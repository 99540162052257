import { createContext } from "react";

const DocumentContext = createContext({
  files: [],
  onInitialize: (files) => {},
  onUpdateDocumentType: (payload) => {},
  onFileRemove: (file) => {},
  onFileAppend: (file) => {},
  onReset: () => {},
});

export default DocumentContext;
