import { Skeleton, Stack } from "@mui/material";
import React from "react";

const HeaderSkeleton = () => (
  <Stack
    alignItems="flex-start"
    direction={{
      xs: "column",
      md: "row",
    }}
    justifyContent="space-between"
    spacing={4}
  >
    <Stack alignItems="center" direction="row" spacing={2}>
      <Skeleton variant="circular" width={74} height={74} />
      <Stack spacing={1}>
        <Skeleton variant="text" width={150} height={30} />
      </Stack>
    </Stack>
  </Stack>
);

export default HeaderSkeleton;
