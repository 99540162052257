import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES } from "src/helpers/url";

import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  AUDITORS: "AUDITORS",
};

export const companyAuditorsApi = createApi({
  reducerPath: "companyAuditorsApi",
  baseQuery,
  tagTypes: [TAGS.AUDITORS],
  endpoints: (builder) => ({
    getCompanyAuditors: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/Auditors`,
      providesTags: [TAGS.AUDITORS],
    }),
    createAuditor: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/Auditors`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [TAGS.AUDITORS],
    }),
    updateAuditor: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/Auditors/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [TAGS.AUDITORS],
    }),
  }),
});

export const {
  useGetCompanyAuditorsQuery,
  useCreateAuditorMutation,
  useUpdateAuditorMutation } = companyAuditorsApi;
