import { useContext } from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from "react-idle-timer";

// context
import AuthContext from "src/contexts/auth/AuthContext";

// hocs
import withAdminGuard from "src/hocs/withAdminGuard";

// hooks
import useSettings from "src/hooks/useSettings";
import useSections from "src/hooks/useSections";

// components
import HorizontalLayout from "../dashboard/horizontal-layout";
import VerticalLayout from "../dashboard/vertical-layout";

const Layout = withAdminGuard((props) => {
  const { onSignOut } = useContext(AuthContext);

  const settings = useSettings();
  const sections = useSections();

  // sign out when user idle
  useIdleTimer({ onIdle: onSignOut, timeout: 900000, throttle: 500 });

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
