import { useEffect } from "react";

// hooks
import useLocalStorage from "./useLocalStorage";
import { useGetLayoutQuery } from "src/redux/api";
// import { useLazyGetCompanyLibraryQuery } from "src/redux/documents/api";

const useInitializer = () => {
  // const cachedTenant = useRef(0);

  const [docketMapping, setDocketMapping] = useLocalStorage("docketMapping");
  // const [tenant] = useLocalStorage("tenant");
  // const [, setLibraries] = useLocalStorage("libraries");

  const { data } = useGetLayoutQuery();

  // const [fetchCompanyLibraries, { data: libraries = [] }] =
  //   useLazyGetCompanyLibraryQuery();

  // store mapping
  useEffect(() => {
    if (!docketMapping && data) setDocketMapping(data);
  }, [data, docketMapping, setDocketMapping]);

  // fetch tenant libraries
  // useEffect(() => {
  //   if (tenant && cachedTenant.current !== tenant.id) {
  //     cachedTenant.current = tenant.id;
  //     fetchCompanyLibraries();
  //   }
  // }, [fetchCompanyLibraries, tenant]);

  // store libraries
  // useEffect(() => {
  //   if (libraries.length > 0) {
  //     const _libraries = libraries.map((library) => {
  //       let obj = { ...library };

  //       // remove documents and subDockets from the docket object
  //       obj.dockets = obj.dockets.map((docket) => {
  //         let newDocket = { ...docket };
  //         delete newDocket.documents;
  //         delete newDocket.subDockets;
  //         return newDocket;
  //       });
  //       return obj;
  //     });
  //     setLibraries(_libraries);
  //   }
  // }, [libraries, setLibraries]);
};

export default useInitializer;
