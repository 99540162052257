import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";

// hooks
import usePopover from "src/hooks/usePopover";

const NotificationsButton = () => {
  const popover = usePopover();

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <Badge color="error">
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default NotificationsButton;
