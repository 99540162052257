import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

// other
import generateUniqueKey from "src/utils/generateUniqueKeys";
import CorporateAuthorization from "src/models/organization/corporate-authorities/CorporateAuthorization";

const rows = [
  {
    key: generateUniqueKey("authority_row_1"),
    cells: [
      {
        label: "Title",
        name: "title",
      },
      {
        label: "Description",
        name: "description",
      },
    ],
  },
  {
    key: generateUniqueKey("authority_row_2"),
    cells: [
      {
        label: "Authority Level",
        name: "authorityLevel",
      },
      {
        label: "Level Description",
        name: "levelDescription",
      },
    ],
  },
];

const AuthorityDetails = ({ authorization }) => {
  return (
    <Card>
      <CardHeader title="Authority Details" />
      <Divider />
      <TableContainer sx={{ pl: 1, pr: 1 }}>
        <Table>
          <TableBody>
            {rows.map(({ key, cells }) => (
              <TableRow key={key}>
                {cells.map(({ label, name }) => (
                  <TableCell key={label}>
                    <Typography variant="caption">{label}</Typography>
                    <Typography variant="subtitle2">
                      {authorization && authorization[name]}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

AuthorityDetails.propTypes = {
  authorization: PropTypes.instanceOf(CorporateAuthorization),
};

export default AuthorityDetails;
