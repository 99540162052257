import { Grid, Skeleton, TableCell, TableRow } from "@mui/material";
import SimpleTable from "src/components/tables/SimpleTable";

const columns = [
  {
    title: "Role",
  },
  {
    title: "Company",
  },
];

const CorporateRolesSkeleton = () => (
  <div>
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <SimpleTable title="Corporate Roles" columns={columns}>
          {[1, 2, 3].map((row) => (
            <TableRow key={row}>
              <TableCell>
                <Skeleton variant="rectangular" height={30} />
              </TableCell>
              <TableCell>
                <Skeleton variant="rectangular" height={30} />
              </TableCell>
            </TableRow>
          ))}
        </SimpleTable>
      </Grid>
    </Grid>
  </div>
);

export default CorporateRolesSkeleton;
