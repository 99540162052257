import { useState, useEffect, useCallback } from "react";

const useSessionStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(error);
    }
  }, [key, storedValue]);

  const storeValueHandler = useCallback(
    (value) => {
      setStoredValue(value);
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    [key]
  );

  const removeStoredValueHandler = useCallback(() => {
    if (typeof window !== "undefined") {
      window.sessionStorage.removeItem(key);
    }
  }, [key]);

  return [storedValue, storeValueHandler, removeStoredValueHandler];
};

export default useSessionStorage;
