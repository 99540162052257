import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "src/constants";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import {
  COMPANIES,
  CORPORATE_PROFILE,
  EMPLOYEES,
  USERS,
} from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  EMPLOYEE: "EMPLOYEE",
  AUTHORIZATIONS: "AUTHORIZATIONS",
};

export const employeeApi = createApi({
  reducerPath: "employeeApi",
  baseQuery,
  tagTypes: [TAGS.EMPLOYEE, TAGS.AUTHORIZATIONS],
  endpoints: (builder) => ({
    createEmployee: builder.mutation({
      query: (payload) => ({
        url: `/${EMPLOYEES}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.EMPLOYEE],
    }),
    getEmployeeList: builder.query({
      query: () => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}`,
        method: HTTP_METHODS.GET,
      }),
      providesTags: [TAGS.EMPLOYEE],
    }),
    getEmployee: builder.query({
      query: (id) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${id}`,
        method: HTTP_METHODS.GET,
      }),
      providesTags: [TAGS.EMPLOYEE],
    }),
    getApplicationUsers: builder.query({
      query: () => `${USERS}`,
    }),
    getEmployeeCorporateAuthorizations: builder.query({
      query: (id) =>
        `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${id}/corporateAuthorizations`,
      providesTags: [TAGS.AUTHORIZATIONS],
    }),
    createAuthorizations: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${payload.employeeId
          }/corporateAuthorizations`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.AUTHORIZATIONS],
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.EMPLOYEE],
    }),
    deleteAuthorization: builder.mutation({
      query: ({ id, employeeId }) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${employeeId}/corporateAuthorizations/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.AUTHORIZATIONS],
    }),
    updateEmployee: builder.mutation({
      query: (payload) => {
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${payload.employeeId
            }`,
          method: HTTP_METHODS.PATCH,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        };
      },
      invalidatesTags: [TAGS.EMPLOYEE],
    }),

    getCorporateProfile: builder.query({
      query: (id) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${id}/${CORPORATE_PROFILE}`,
        method: HTTP_METHODS.GET,
      }),
      providesTags: [TAGS.EMPLOYEE],
    }),

    updateCorporateProfile: builder.mutation({

      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${payload.updateCorporateRoleDto.employeeId}/CorporateProfile/${payload.updateCorporateRoleDto.corporateRoleId}`,
        method: HTTP_METHODS.PUT,
        body: payload
      }),
      invalidatesTags: [TAGS.EMPLOYEE],
    })
  }),
});

export const {
  useCreateEmployeeMutation,
  useGetEmployeeListQuery,
  useGetEmployeeQuery,
  useGetApplicationUsersQuery,
  useGetEmployeeCorporateAuthorizationsQuery,
  useCreateAuthorizationsMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useGetCorporateProfileQuery,
  useDeleteAuthorizationMutation,
  useUpdateCorporateProfileMutation
} = employeeApi;
