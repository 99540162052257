import { useEffect } from "react";
import { useDeleteDocumentTypeMutation } from "src/redux/documents/api";
import useToast from "../../../../hooks/useToast";
import { toast } from "react-hot-toast";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useDeleteType = (id) => {
  const { toastIdHandler, cleanup, toastId } = useToast();

  const [deleteDocumentType, { isLoading, isSuccess, isError, error }] =
    useDeleteDocumentTypeMutation(id);

  const deleteType = (id) => {
    deleteDocumentType(id);
  };

  useEffect(() => {
    if (isLoading && !toastId) {
      const _toastId = toast.loading("Deleting", {
        duration: DEFAULT_TOAST_DURATION,
      });
      toastIdHandler(_toastId);
    }
    if (isSuccess && toastId) {
      toast.success("Document Type Deleted Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
        id: toastId,
      });
    }
    if (isError && toastId) {
      toast.error(`Error on Deleting: ${error?.data?.detail},`, {
        duration: ERROR_TOAST_DURATION,
        id: toastId,
      });
    }
    return () => {
      cleanup();
    };
  }, [isLoading, isSuccess, isError, error, toastId, toastIdHandler]);

  return {
    deleteType,
  };
};

export default useDeleteType;
