import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App";

// msal
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// config
import { msalConfig } from "./authConfig";
import { store } from "./store";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <MsalProvider instance={msalInstance}>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </MsalProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
