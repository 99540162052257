import { useContext } from "react";
import PropTypes from "prop-types";

// untitled-ui
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import RefreshIcon from "@untitled-ui/icons-react/build/esm/RefreshCcw03";
// import { FaExternalLinkAlt } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";

// mui
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";

// contexts
import AuthContext from "src/contexts/auth/AuthContext";

// hooks
import useSessionStorage from "src/hooks/useSessionStorage";
import useRouter from "src/hooks/useRouter";
import { paths } from "src/paths";
import { useDispatch } from "src/store";
import { setLoading } from "src/redux/slice";

const panels = [
  { title: "Organization Settings", id: 1 },
  { title: "Instance Settings", id: 2 },
  // { title: "User Guide", id: 3 },
];

export const AccountPopover = ({ anchorEl, open, onClose }) => {
  const dispatch = useDispatch();
  const { replace } = useRouter();
  const { user, onSignOut } = useContext(AuthContext);
  const [isInstanceAdmin, setIsInstanceAdmin] =
    useSessionStorage("is_instance_admin");
  const [isAdmin, setIsAdmin] = useSessionStorage("is_admin");

  const switchDashboardHandler = () => {
    dispatch(setLoading());
    setIsAdmin(false);
    setIsInstanceAdmin(false);
    replace(paths.home.index);
    window.location.reload(false);
  };

  const adminSwitchHandler = (id) => {
    dispatch(setLoading());
    setIsAdmin(true);

    if (id === 1) setIsInstanceAdmin(false);
    if (id === 2) setIsInstanceAdmin(true);

    replace(paths.admin.index);
    window.location.reload(false);
  };

  /**
   * remove organization settings if isAdmin true
   * remove system settings if isInstanceAdmin true
   * otherwise return panels without filtering
   */
  const filteredPanels =
    isAdmin && !isInstanceAdmin
      ? panels.filter((panel) => panel.id !== 1)
      : isAdmin && isInstanceAdmin
      ? panels.filter((panel) => panel.id !== 2)
      : panels;

  const fullName = user && `${user.firstName} ${user.lastName}`;

  const openLinkInNewTab = () => {
    const url = "https://xtrackuserguidev1.z23.web.core.windows.net/";
    window.open(url, "_blank");
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 260 } }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{fullName}</Typography>
        <Typography color="text.secondary" variant="body2" noWrap>
          {user?.userName}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        {/* <ListItemButton
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Profile</Typography>}
          />
        </ListItemButton>
        <ListItemButton
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Settings</Typography>}
          />
        </ListItemButton> */}
        {filteredPanels.map((panel) => (
          <ListItemButton
            key={panel.id}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            onClick={adminSwitchHandler.bind(null, panel.id)}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <Settings04Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">{panel.title}</Typography>}
            />
          </ListItemButton>
        ))}

        {(isAdmin || isInstanceAdmin) && (
          <ListItemButton
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
            onClick={switchDashboardHandler}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <RefreshIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">User Area</Typography>}
            />
          </ListItemButton>
        )}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box sx={{ py: 0.5, px: 1 }}>
        <Button
          onClick={openLinkInNewTab}
          size={"small"}
          sx={{
            backgroundColor: "primary.light",
            borderRadius: 1,
            my: 0.5,
            width: "100%",
          }}
        >
          <Typography variant={"inherit"}>Visit User Guide</Typography>
          <SvgIcon fontSize="small" sx={{ ml: 1, mt: 0.2 }}>
            <HiExternalLink />
          </SvgIcon>
        </Button>
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button
            color="inherit"
            size="small"
            onClick={onSignOut}
            sx={{
              width: "100%",
              backgroundColor: "error.light",
              borderRadius: 1,
            }}
        >
          Logout
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default AccountPopover;
