import React from "react";
import PropTypes from "prop-types";

// mui
import { Grid } from "@mui/material";

// components
import Authority from "./Authority";
import FloatingButton from "src/components/FloatingButton";

// other
import CorporateAuthorization from "src/models/organization/corporate-authorities/CorporateAuthorization";

const Authorities = ({ authorizations, onClick, onAdd, onDelete }) => (
  <>
    <Grid container spacing={2}>
      {authorizations.map(
        ({
          title,
          authorityLevel,
          bankName,
          branchName,
          financeLimit,
          accountNumber,
          ...authority
        }) => (
          <Grid key={authority.id} item xs={12} md={4} lg={4}>
            <Authority
              title={title}
              bankName={bankName}
              branchName={branchName}
              financeLimit={financeLimit}
              accountNumber={accountNumber}
              authorityLevel={authorityLevel}
              {...authority}
              onClick={onClick}
              onDelete={onDelete}
            />
          </Grid>
        )
      )}
    </Grid>
    <FloatingButton title="Add Authority" onClick={onAdd} />
  </>
);

Authorities.propTypes = {
  authorizations: PropTypes.arrayOf(
    PropTypes.instanceOf(CorporateAuthorization)
  ),
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
};

Authorities.defaultProps = {
  authorizations: [],
  onClick: () => { },
};

export default Authorities;
