import { useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import { useDispatch } from "src/store";
import { useGetEmployeeQuery } from "../redux/api";
import { ERROR_TOAST_DURATION } from "src/constants";

const useGetEmployee = (id) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, error } = useGetEmployeeQuery(id);

  useEffect(() => {}, [data]);

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Employee Updating: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [dispatch, isError, error]);

  const employee = data;

  return {
    employee,
    isLoading,
  };
};

export default useGetEmployee;
