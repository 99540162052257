import { format } from "date-fns";
import {closeFormDialog, openFormDialog} from "src/redux/slice";

// hooks
import { useDispatch } from "src/store";
import useDeleteType from "./useDeleteType";
import useGetDocumentType from "./useGetDocumentType";

// mui
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import TrashIcon from "@untitled-ui/icons-react/build/esm/Trash04";
import useNewDocumentType from "./useNewDocumentType";
import useUpdateDocumentType from "./useUpdateDocumentType";

const useType = (selectedType, setType) => {
  const dispatch = useDispatch();

  const { formik: newFormik, isLoading: newIsLoading } = useNewDocumentType();
  const { formik: updateFormik, isLoading: updateIsLoading } = useUpdateDocumentType(selectedType);
  const { data, isLoading, title} = useGetDocumentType();

  const newTypeHandler = () => {
    dispatch(openFormDialog({
      type: "NEW_TYPE"
    }));
  };
  const DeleteTypeHandler = (event, row) => {
    event.stopPropagation();
    setType(row);
    dispatch(
      openFormDialog({
        type: "DELETE_TYPE",
        message: `Are you sure you want to remove ${row.name} Type?`,
        id: row.id,
      })
    );
  };

  const updateTypeHandler = (row) => {
    if (!row.isSystem) {
      setType(row);
      dispatch(openFormDialog({
        type: "UPDATE_TYPE",
        data: row,
      }));
    }
  }

  const onActionsCloseHandler = () => {
    setType(null);
  };

  const onCloseHandler = () => {
    dispatch(closeFormDialog());
  };

  const { deleteType } = useDeleteType();

  const handleDelete = () => {
    deleteType(selectedType?.id);
    onCloseHandler();
  };

  const columns = [
    {
      field: "name",
      headerName: "Document Type ",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) =>
      format(new Date(params.row.createdAt), "dd/MM/yyyy"),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "action",
      headerName: "action",
      width: 80,
      align: "left",
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.isSystem
              ? "Cannot Remove System Document Type"
              : "Remove"
          }
          placement="left"
        >
          <span>
            <IconButton
              onClick={(event) => DeleteTypeHandler(event, params.row)}
              disabled={params.row.isSystem}
              style={{ color: params.row.isSystem ? "lightcoral" : "red" }}
            >
              <SvgIcon
                fontSize="small"
                style={{
                  color: "red",
                }}
              >
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  return {
    columns,
    selectedType,
    updateTypeHandler,
    onCloseHandler,
    handleDelete,
    onClose: onActionsCloseHandler,
    data,
    isLoading,
    newTypeHandler,
    title,
    newFormik,
    newIsLoading,
    updateFormik,
    updateIsLoading,
  };
};

export default useType;
