import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { COMPANY_PROFILE, STAKEHOLDERS } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

const TAGS = {
  STAKEHOLDERS: "STAKEHOLDERS",
  CORPORATE_ROLES: "CORPORATE_ROLES",
};

export const stakeholdersApi = createApi({
  reducerPath: "stakeholdersApi",
  baseQuery,
  tagTypes: [TAGS.STAKEHOLDERS, TAGS.CORPORATE_ROLES],
  endpoints: (builder) => ({
    getStakeholders: builder.query({
      query: () => `${STAKEHOLDERS}/1/stakeholders`,
      providesTags: [TAGS.STAKEHOLDERS],
    }),
    getStakeholder: builder.query({
      query: (id) => `${STAKEHOLDERS}/${id}/stakeholder`,
      providesTags: [TAGS.CORPORATE_ROLES],
    }),
    getCompanyProfiles: builder.query({
      query: () => COMPANY_PROFILE,
    }),
    getRoles: builder.query({
      query: () => `${STAKEHOLDERS}/roles`,
    }),
    createStakeholder: builder.mutation({
      query: (payload) => ({
        url: `${STAKEHOLDERS}/${payload.companyProfileId}/stakeholder`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    createCorporateRole: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_PROFILE}/${payload.companyProfileId}/corporate-role`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_ROLES],
    }),
    updateStakeholder: builder.mutation({
      query: (payload) => ({
        url: `${STAKEHOLDERS}${payload.sId}/update-stakeholder`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
    }),
    updateCorporateRole: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_PROFILE}/${payload.id}/update-corporate-role`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_ROLES],
    }),
    deleteStakeholder: builder.mutation({
      query: (id) => ({
        url: `${STAKEHOLDERS}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.STAKEHOLDERS],
    }),
  }),
});

export const {
  useGetStakeholdersQuery,
  useGetStakeholderQuery,
  useGetCompanyProfilesQuery,
  useGetRolesQuery,
  useCreateStakeholderMutation,
  useCreateCorporateRoleMutation,
  useUpdateStakeholderMutation,
  useUpdateCorporateRoleMutation,
  useDeleteStakeholderMutation,
} = stakeholdersApi;
