// layout
import PageContainer from "src/layouts/page";
import DataTableSkeleton from "src/components/skeletons/table/DataTableSkeleton";
import withLoading from "src/hocs/withLoading";
// components
import FloatingButton from "src/components/FloatingButton";
import FormModal from "src/components/modals/FormModal";
import DataTable from "src/components/tables/DataTable";
import { formDialogSelector } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "src/store";
import { closeFormDialog } from "src/redux/slice";
import Confirmation from "src/components/modals/Confirmation";
import UpdateDepartmentForm from "./components/UpdateDepartmentForm";
import useGetDepartments from "./hooks/useGetDepartments";
import useDeleteDepartment from "./hooks/useDeleteDepartment";
import useDepartments from "./hooks/useDepartments";
import NewDepartmentForm from "./components/NewDepartmentForm";

const Departments = () => {
  const { columns, selectedDepartment, UpdateDepartmentHandler } =
    useDepartments();
  const { data, newTypeHandler, title } = useGetDepartments();
  const { data: dialogData } = useSelector(formDialogSelector);

  const dispatch = useDispatch();
  const onCloseHandler = () => {
    dispatch(closeFormDialog());
  };

  const { deleteDepartment } = useDeleteDepartment();

  const handleDelete = () => {
    deleteDepartment(selectedDepartment?.id);
    onCloseHandler();
  };

  const TableWithLoading = withLoading(DataTable, DataTableSkeleton);
  return (
    <>
      <PageContainer title="Departments">
        <TableWithLoading
          isEmpty={data?.length === 0}
          isLoading={false}
          columns={columns}
          rows={data}
          onRowClick={UpdateDepartmentHandler}
          headerSx={{ color: "#696868" }}
          title={title}
        />
      </PageContainer>
      {dialogData?.type === "NEW_DEPARTMENT" && (
        <FormModal title="New Department">
          <NewDepartmentForm />
        </FormModal>
      )}

      {dialogData?.type === "DELETE_DEPARTMENT" && (
        <FormModal title="Remove Department">
          <Confirmation onConfirm={handleDelete} onClose={onCloseHandler} />
        </FormModal>
      )}
      {dialogData?.type === "UPDATE_DEPARTMENT" && (
        <FormModal title="Update Department">
          <UpdateDepartmentForm selectedDepartment={selectedDepartment} />
        </FormModal>
      )}

      <FloatingButton title="New Department" onClick={newTypeHandler} />
    </>
  );
};

export default Departments;
