import React from "react";

const Logo = ({ viewBox = "0 0 128 128" }) => (
  
<svg width="100%" height="100%" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1483_26293" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="7" width="85" height="117">
<path d="M54.2403 61.5368L84.065 31.7111L61.722 9.36703C59.5367 7.18173 55.9941 7.18173 53.8077 9.36703L1.63898 61.5368C-0.546325 63.7221 -0.546325 67.2648 1.63898 69.4501L53.8077 121.62C55.9941 123.805 59.5367 123.805 61.722 121.62L84.065 99.2758L54.2403 69.4501C52.0539 67.2648 52.0539 63.7221 54.2403 61.5368Z" fill="white"/>
</mask>
<g mask="url(#mask0_1483_26293)">
<path d="M54.2403 61.5368L84.065 31.7111L61.722 9.36703C59.5367 7.18173 55.9941 7.18173 53.8077 9.36703L1.63898 61.5368C-0.546325 63.7221 -0.546325 67.2648 1.63898 69.4501L53.8077 121.62C55.9941 123.805 59.5367 123.805 61.722 121.62L84.065 99.2758L54.2403 69.4501C52.0539 67.2648 52.0539 63.7221 54.2403 61.5368Z" fill="url(#paint0_linear_1483_26293)"/>
</g>
<mask id="mask1_1483_26293" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="84" y="7" width="85" height="117">
<path d="M166.495 61.5363L114.326 9.36654C112.14 7.18124 108.598 7.18124 106.412 9.36654L84.0684 31.7106L113.894 61.5363C116.08 63.7216 116.08 67.2643 113.894 69.4496L84.0684 99.2753L106.412 121.619C108.598 123.805 112.14 123.805 114.326 121.619L166.495 69.4496C168.682 67.2643 168.682 63.7216 166.495 61.5363Z" fill="white"/>
</mask>
<g mask="url(#mask1_1483_26293)">
<path d="M166.495 61.5363L114.326 9.36654C112.14 7.18124 108.598 7.18124 106.412 9.36654L84.0684 31.7106L113.894 61.5363C116.08 63.7216 116.08 67.2643 113.894 69.4496L84.0684 99.2753L106.412 121.619C108.598 123.805 112.14 123.805 114.326 121.619L166.495 69.4496C168.682 67.2643 168.682 63.7216 166.495 61.5363Z" fill="url(#paint1_linear_1483_26293)"/>
</g>
<mask id="mask2_1483_26293" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="52" y="31" width="64" height="69">
<path d="M113.894 61.5364L84.068 31.7107L54.2433 61.5364C52.0569 63.7217 52.0569 67.2644 54.2433 69.4497L84.068 99.2754L113.894 69.4497C116.08 67.2644 116.08 63.7217 113.894 61.5364Z" fill="white"/>
</mask>
<g mask="url(#mask2_1483_26293)">
<path d="M113.894 61.5364L84.068 31.7107L54.2433 61.5364C52.0569 63.7217 52.0569 67.2644 54.2433 69.4497L84.068 99.2754L113.894 69.4497C116.08 67.2644 116.08 63.7217 113.894 61.5364Z" fill="url(#paint2_linear_1483_26293)"/>
<path d="M113.894 61.5364L84.068 31.7107L54.2433 61.5364C52.0569 63.7217 52.0569 67.2644 54.2433 69.4497L84.068 99.2754L113.894 69.4497C116.08 67.2644 116.08 63.7217 113.894 61.5364Z" fill="url(#paint3_linear_1483_26293)"/>
</g>
<path d="M90.2332 18.5178C86.8404 21.9127 81.2889 21.9127 77.8962 18.5178C74.5045 15.1229 68.953 9.56723 65.5614 6.17236C62.1676 2.77856 64.4283 0.000184561 70.5835 0.000184561H72.873C79.0282 0.000184561 89.1012 0.000184561 95.2564 0.000184561H97.5448C103.7 0.000184561 105.961 2.77856 102.569 6.17236C99.1763 9.56723 93.6259 15.1229 90.2332 18.5178Z" fill="#4338CA"/>
<mask id="mask3_1483_26293" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="63" y="110" width="42" height="22">
<path d="M77.8935 113.482C81.2873 110.088 86.8388 110.088 90.2316 113.482C93.6243 116.877 99.1747 122.433 102.567 125.827C105.959 129.222 103.698 132 97.5432 132H95.2537C89.0985 132 79.0255 132 72.8703 132H70.5819C64.4267 132 62.1659 129.222 65.5587 125.827C68.9514 122.433 74.5029 116.877 77.8935 113.482Z" fill="white"/>
</mask>
<g mask="url(#mask3_1483_26293)">
<path d="M77.8935 113.482C81.2873 110.088 86.8388 110.088 90.2316 113.482C93.6243 116.877 99.1747 122.433 102.567 125.827C105.959 129.222 103.698 132 97.5432 132H95.2537C89.0985 132 79.0255 132 72.8703 132H70.5819C64.4267 132 62.1659 129.222 65.5587 125.827C68.9514 122.433 74.5029 116.877 77.8935 113.482Z" fill="url(#paint4_linear_1483_26293)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1483_26293" x1="42.033" y1="-19.5808" x2="42.033" y2="153.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#4338CA"/>
<stop offset="1" stop-color="#3A2666"/>
</linearGradient>
<linearGradient id="paint1_linear_1483_26293" x1="126.101" y1="-8.03296" x2="126.101" y2="144.225" gradientUnits="userSpaceOnUse">
<stop stop-color="#72D1E9"/>
<stop offset="0.00537634" stop-color="#72D1E9"/>
<stop offset="1" stop-color="#515AB2"/>
</linearGradient>
<linearGradient id="paint2_linear_1483_26293" x1="84.068" y1="-8.9852" x2="84.068" y2="200.31" gradientUnits="userSpaceOnUse">
<stop stop-color="#EBED13"/>
<stop offset="1" stop-color="#8B331C"/>
</linearGradient>
<linearGradient id="paint3_linear_1483_26293" x1="84.068" y1="-19.5811" x2="84.068" y2="73.8599" gradientUnits="userSpaceOnUse">
<stop stop-color="#4338CA"/>
<stop offset="1" stop-color="#515AB2"/>
</linearGradient>
<linearGradient id="paint4_linear_1483_26293" x1="84.0625" y1="80.2586" x2="84.0625" y2="226.356" gradientUnits="userSpaceOnUse">
<stop stop-color="#72D1E9"/>
<stop offset="0.00537634" stop-color="#72D1E9"/>
<stop offset="1" stop-color="#515AB2"/>
</linearGradient>
</defs>
</svg>

);

export default Logo;
