import PropTypes from "prop-types";

// material
import { Box, SvgIcon, Tooltip } from "@mui/material";

// icons
import { BsPinFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";

// components
import FileIcon from "src/components/FileIcon";

// other
import DocumentModel from "src/models/documents/Document";
import DocketModel from "src/models/documents/Docket";
import { EXCEL_TYPES, WORD_TYPES } from "./constants";

const lockToolTipSuffix = "is Locked";

const DocumentIcons = ({ isVersion, item, styles = {}, extension }) => {
  const getExtension = (fileTypes) => {
    if (fileTypes) {
      const type = fileTypes.split("/")[1];

      if (WORD_TYPES.find((x) => x === type)) return "docx";
      if (EXCEL_TYPES.find((x) => x === type)) return "xls";

      return type;
    }

    return extension;
  };

  if (!item) {
    return null;
  }

  const lockToolTipTitle =
    item instanceof DocumentModel
      ? `Document ${lockToolTipSuffix}`
      : `Docket ${lockToolTipSuffix}`;

  return (
    <Box>
      {!isVersion && item.isLocked && (
        <Box position={'relative'}>
          <Box position={'absolute'} top={33} left={30}>
            <Tooltip title={lockToolTipTitle}>
              <SvgIcon fontSize="small" >
                <AiFillLock color={'#757575'}/>
              </SvgIcon>
            </Tooltip>
          </Box>
        </Box>
      )}
      {isVersion && item.isCurrentVersion && (
        <Box position={'relative'}>
          <Box position={"absolute"} top={32} left={-2}>
            <Tooltip title="Current Version">
              <SvgIcon fontSize="small">
                <BsPinFill size={24} color={'#757575'}  />
              </SvgIcon>
            </Tooltip>
          </Box>
        </Box>
      )}
      {!isVersion && (
        <FileIcon
          styles={styles}
          extension={getExtension(item.currentDocumentVersion?.fileType)}
        />
      )}
      {isVersion && (
        <FileIcon styles={styles} extension={getExtension(item.fileType)} />
      )}
    </Box>
  );
};

DocumentIcons.propTypes = {
  isVersion: PropTypes.bool,
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(DocumentModel),
    PropTypes.instanceOf(DocketModel),
  ]),
  styles: PropTypes.object,
};

export default DocumentIcons;
