import { useEffect, useState } from "react";
import toast from "react-hot-toast";

// hooks
import useRouter from "src/hooks/useRouter";
import { useCreateEmployeeMutation } from "../../redux/api";
import { useDispatch } from "src/store";

// other
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { closeFormDialog, openFormDialog } from "src/redux/slice";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import { paths } from "src/paths";

const useNewEmployeeLogic = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();

  const [selectedTab, setTab] = useState(1);
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateEmployeeMutation();

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Employee Creating: ${error?.data?.detail}.`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [dispatch, isError, error]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Employee Created Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch(
        openFormDialog({
          type: "NEW_CONFIRMATION",
          message: `Do you need to add another Employee?`,
        })
      );
    }
  }, [dispatch, isSuccess]);

  const tabs = [
    {
      label: "Employee Details",
      value: 1,
    },
    {
      label: "Corporate Profile Details",
      value: 2,
    },
  ];

  const tabChangeHandler = (event, newValue) => setTab(newValue);

  const backHandler = () => setTab(1);

  const submitHandler = (values) => {
    const {
      name,
      corporateRoleId,
      companyId,
      addressNumber,
      addressLine1,
      addressLine2,
      street,
      city,
      province,
      zipCode,
      country,
      ...employee
    } = values;
    let corporateProfiles = null;
    if
      (name && corporateRoleId) {
      corporateProfiles = [{
        name, corporateRoleId,
        companyId
          :
          getCurrentTenant
            ()
      }];
    }
    create({
      createEmployeeDto: {
        residentialAddress: {
          number: addressNumber,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          street: street,
          city: city,
          province: province,
          country: country,
          zipCode: zipCode,
        },
        companyId: getCurrentTenant(),
        corporateProfiles: corporateProfiles,
        ...employee,
      },
    });
  };

  const closeHandler = () => {
    dispatch(closeFormDialog());
    push(paths.admin.employee.index);
  };

  const confirmHandler = () => {
    dispatch(closeFormDialog());
    window.location.reload();
  };

  return {
    isSuccess,
    tabs,
    selectedTab,
    isLoading,
    onTabChange: tabChangeHandler,
    submitHandler,
    backHandler,
    confirmHandler,
    closeHandler,
  };
};

export default useNewEmployeeLogic;
