import { useContext } from "react";
import PropTypes from "prop-types";

// context
import AuthContext from "src/contexts/auth/AuthContext";

// components
import Error405Page from "src/pages/405";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isAdmin } = useContext(AuthContext);

  if (!isAuthenticated) {
    return null;
  }

  if (isAuthenticated && isAdmin) return <Error405Page />;

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
