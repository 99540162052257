// mui
import {
  Avatar,
  Box,
  Divider,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";

// hooks
import useTenant from "./hooks/useTenant";

// components
import Logo from "../../../components/Logo";

const TenantControl = () => {
  const {
    filteredCompanies,
    anchorRef,
    open,
    selectedTenant,
    keyword,
    changeHandler,
    searchChangeHandler,
    handleToggle,
  } = useTenant();

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ p: 2 }}
      >
        <ListItemButton onClick={handleToggle} ref={anchorRef}>
          <Box
            sx={{
              borderColor: "var(--nav-logo-border)",
              borderRadius: 1,
              borderStyle: "solid",
              borderWidth: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 50,
              p: "4px",
              width: 50,
            }}
          >
            <Logo viewBox="-6 -28 180 180" />
          </Box>
          <ListItemText
            primary="XTrack"
            primaryTypographyProps={{
              fontSize: 18,
              fontWeight: "medium",
              lineHeight: "20px",
              mb: "2px",
            }}
            secondary={selectedTenant?.commonName || "Select Tenant"}
            secondaryTypographyProps={{
              noWrap: true,
              fontSize: 12,
              lineHeight: "16px",
              color: open ? "rgba(0,0,0,0)" : "rgba(255,255,255,0.5)",
            }}
            sx={{ my: 0, mx: 2 }}
          />
          <KeyboardArrowDown
            sx={{
              mr: -1, // opacity: 0,
              transform: open ? "rotate(-180deg)" : "rotate(0)",
              transition: "0.2s",
            }}
          />
        </ListItemButton>
      </Stack>
      {/* <TenantPopover
        tenants={accessibleCompanies}
        anchorEl={anchorRef.current}
        open={open}
        selected={selectedTenant}
        onChange={changeHandler}
        onClose={handleClose}
      /> */}
      {open && (
        <>
          <Divider sx={{ borderColor: "rgba(255,255,255,.1)" }} />
          <Box
            sx={{
              bgcolor: "#1d2c45",
              // p: 1,
              overflow: "hidden",
              minHeight: 225,
              boxShadow: "inset 1px -20px 10px -14px #131827, inset 0px 20px 10px -14px #131827",
            }}
          >
            {/* <Divider sx={{ borderWidth: 3, borderColor: "#1f2c48" }} /> */}
          <Stack
            alignItems={"left"}
            justifyContent={"left"}
            sx={{
              bgcolor: "#1d2c45",
              pt: 1,
              my: 2,
              // boxShadow: "-1px -1px 2px #19263d, 1px 1px 2px #19263d, inset 1px 2px 8px #020304, inset -1px -1px 5px #19263d"
          }}
          >
            <Box
              sx={{
                mt: 1,
                mx: 1.5,
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                InputProps={{
                  inputProps: {
                    style: {
                      color: "#C0C0C0",
                      height: "0.8rem"
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon sx={{fontSize: "16px", color:"#C0C0C0"}}>
                        <SearchMdIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                size="small"
                placeholder="Search"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray"
                  }
                }}
                value={keyword}
                onChange={searchChangeHandler}
              />
            </Box>
            <Box
              mt={1}
              mx={2}
              maxHeight={140}
              overflow={"scroll"}
                sx={{
                "&::-webkit-scrollbar": {
                  width: "0.4em"
                },
                "&::-webkit-scrollbar-track": {
                  "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,.1)"
                },
                "&::-webkit-scrollbar-button": {
                  width: "0px",
                  height: "0px"
                },
                "&::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent"
                }
              }}
            >
            <List sx={{ width: "98%" }}>
              {filteredCompanies.map((tenant) => (
                <ListItemButton
                  key={tenant.id}
                  sx={{
                    minHeight: 32,
                    color: "#C0C0C0",
                    "&:hover": {
                      bgcolor: "#324367",
                      borderRadius: "8px",
                    },

                  }}
                  onClick={changeHandler.bind(null, tenant)}
                >
                  <ListItemAvatar>
                    <Avatar
                      size={"small"}
                      alt={`Avatar n°${tenant.logoUrl}`}
                      src={tenant.logoUrl}
                    >
                      {tenant.companyCode}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={tenant.commonName}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
            </Box>
          </Stack>
            {/*<Divider sx={{ borderColor: "rgba(255,255,255,.1)" }} />*/}
          </Box>
        </>
      )}
    </>
  );
};

export default TenantControl;
