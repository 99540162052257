import { useCallback, useState } from "react";
import PropTypes from "prop-types";

// hooks
import { useDispatch } from "src/store";

// contexts
import DocumentContext from "./DocumentContext";

// redux
import { openDrawer } from "src/redux/slice";

const DocumentProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const transformFiles = (list, defaultType) =>
    list.map((file) => {
      const {
        name,
        path,
        lastModified,
        size,
        type,
        webkitRelativePath,
        lastModifiedDate,
      } = file;

      return {
        document: file,
        name: name,
        documentTypeId: defaultType,
        path,
        lastModified,
        lastModifiedDate,
        size,
        type,
        webkitRelativePath,
      };
    });

  const initializeHandler = useCallback(
    (files, defaultType, allowTypeSelect = true) => {
      const _files = transformFiles(files, defaultType);

      setFiles(_files);
      if (allowTypeSelect) dispatch(openDrawer());
    },
    [dispatch]
  );

  const updateDocumentTypeHandler = useCallback(
    (payload) => {
      const { name, documentTypeId } = payload;

      let temp = [...files];
      let fileIndex = temp.findIndex((x) => x.name === name);

      if (fileIndex > -1) {
        temp[fileIndex] = { ...temp[fileIndex], documentTypeId };
      }

      setFiles(temp);
    },
    [files]
  );

  const removeFileHandler = (file) =>
    setFiles((prev) => [
      ...prev.filter((_file) => _file.document.path !== file.path),
    ]);

  const appendFileHandler = (appendedFiles, defaultType) => {
    const _files = transformFiles(appendedFiles, defaultType);
    setFiles((prev) => [...prev, ..._files]);
  };

  const resetHandler = useCallback(() => setFiles([]), []);

  const valueObject = {
    files,
    onInitialize: initializeHandler,
    onUpdateDocumentType: updateDocumentTypeHandler,
    onFileRemove: removeFileHandler,
    onFileAppend: appendFileHandler,
    onReset: resetHandler,
  };

  return (
    <DocumentContext.Provider value={valueObject}>
      {children}
    </DocumentContext.Provider>
  );
};

DocumentProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DocumentProvider;
