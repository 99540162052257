import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, ButtonBase, SvgIcon, Tooltip } from "@mui/material";

// icons
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";

const FloatingButton = ({ title, onClick }) => {
  return (
    <Tooltip title={title}>
      <Box
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "50%",
          bottom: 0,
          boxShadow: 16,
          margin: (theme) => theme.spacing(4),
          position: "fixed",
          right: 0,
          zIndex: (theme) => theme.zIndex.speedDial,
        }}
      >
        <ButtonBase
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "50%",
            color: "primary.contrastText",
            p: "10px",
          }}
          onClick={onClick}
        >
          <SvgIcon>
            <PlusIcon />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  );
};

FloatingButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default FloatingButton;
