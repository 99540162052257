import PropTypes from "prop-types";
import { Toaster as HotToaster } from "react-hot-toast";
import { alpha, useTheme } from "@mui/material/styles";

const Toaster = ({ position }) => {
  const theme = useTheme();

  return (
    <HotToaster
      position={position}
      toastOptions={{
        duration: 4000,
        style: {
          backdropFilter: "blur(6px)",
          background: alpha(theme.palette.neutral[900], 0.8),
          color: theme.palette.common.white,
          boxShadow: theme.shadows[16],
        },
      }}
    />
  );
};

Toaster.propTypes = {
  position: PropTypes.string,
};

Toaster.defaultProps = {
  position: "top-center",
};

export default Toaster;
