import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { COMPANY_DIVESTMENT } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  DIVESTMENT_DETAIL: "DIVESTMENT_DETAIL",
};

export const companyDivestmentApi = createApi({
  reducerPath: "companyDivestmentApi",
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query({
      query: (id) => `${COMPANY_DIVESTMENT}/${id}`,
      providesTags: [TAGS.DIVESTMENT_DETAIL],
    }),
    createRequest: builder.mutation({
      query: (payload) => ({
        url: COMPANY_DIVESTMENT,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateRequest: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_DIVESTMENT}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.DIVESTMENT_DETAIL],
    }),
  }),
});

export const {
  useGetByIdQuery,
  useCreateRequestMutation,
  useUpdateRequestMutation,
} = companyDivestmentApi;
