export const ACCOUNT_TYPES = [
  { value: 1, label: "Checking Accounts" },
  { value: 2, label: "Savings Accounts" },
  { value: 3, label: "Money Market Accounts (MMAs)" },
  { value: 4, label: "Certificate of Deposit Dccounts (CDs)" },
  { value: 5, label: "Not Set Yet" },
];

export const ACCOUNT_DOCUMENTS = "ACCOUNT_DOCUMENTS";
export const LINK_DOCUMENTS = "LINK_DOCUMENTS";
export const ACCOUNT_DOCUMENT_FORM = "ACCOUNT_DOCUMENT_FORM";
