import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, Divider, Typography, Stack, Button } from "@mui/material";

// hooks
import { useSelector } from "src/store";

// redux
import { formDialogSelector } from "src/redux/selectors";

const Confirmation = ({ onClose, onConfirm }) => {
  const { data } = useSelector(formDialogSelector);

  return (
    <>
      <Box sx={{ mt: 3, mb: 2, mr: 2, ml: 2 }}>
        <Typography>{data?.message}</Typography>
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />
        <div>
          <Button
            variant="text"
            sx={{ marginRight: 2, color: "black" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </Stack>
    </>
  );
};

Confirmation.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Confirmation;
