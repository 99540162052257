import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../constants";
import { SECTOR } from "../../helpers/url";
import { baseQuery } from "../apiService";

export const TAGS = {
  SECTORS: "SECTORS",
};
export const sectorsApi = createApi({
  reducerPath: "sectorsApi",
  baseQuery,
  //tagtypes
  endpoints: (builder) => ({
    getSectors: builder.query({
      query: () => `${SECTOR}`,
      providesTags: [TAGS.SECTORS],
    }),
    createSector: builder.mutation({
      query: (payload) => ({
        url: `${SECTOR}/`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.SECTORS],
    }),
    updateSector: builder.mutation({
      query: (payload) => ({
        url: `${SECTOR}/${payload.sectorId}`,

        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.SECTORS],
    }),
    deleteSector: builder.mutation({
      query: (id) => ({
        url: `${SECTOR}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.SECTORS],
    }),
  }),
});

export const {
  useGetSectorsQuery,
  useCreateSectorMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
} = sectorsApi;
