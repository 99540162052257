import { forwardRef } from "react";
import { Link } from "react-router-dom";

/**
 * This is an adapted for `react-router-dom/link` component.
 * We use this to help us maintain consistency between CRA and Next.js
 */
const RouterLink = forwardRef(({ href }, ref) => <Link ref={ref} to={href} />);

export default RouterLink;
