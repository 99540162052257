import { createApi } from "@reduxjs/toolkit/query/react";
import {
  COMPANIES,
  DOCKETS,
  DOCUMENTS,
  DOCUMENT_LIBRARIES,
  DOCUMENT_TYPE,
  DOCUMENT_LIBRARY,
  DOCKET_TYPES,
  DOCUMENT_CATEGORY,
  DOCUMENT_LIBRARY_TEMPLATES,
} from "src/helpers/url";
import { HTTP_METHODS } from "src/constants";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "../apiService";

export const TAGS = {
  DOCKET: "DOCKET",
  DOCUMENT: "DOCUMENT",
  LIBRARIES: "LIBRARIES",
  DOCUMENT_CATEGORY: "DOCUMENTCATEGORY",
  DOCUMENT_TYPE: "DOCUMENTTYPE",
};

export const documentsApi = createApi({
  reducerPath: "documentsApi",
  baseQuery,
  tagTypes: [TAGS.DOCKET, TAGS.DOCUMENT, TAGS.LIBRARIES],
  endpoints: (builder) => ({
    getCompanyLibrary: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/${DOCUMENT_LIBRARIES}`,
      providesTags: [TAGS.LIBRARIES],
    }),
    getDocket: builder.query({
      query: (args) => {
        const { id, fetchDocuments } = args;
        return {
          url: `${DOCKETS}/${id}`,
          params: { fetchDocuments },
        };
      },
      providesTags: [TAGS.DOCKET],
    }),
    getDocument: builder.query({
      query: (data) => `${DOCKETS}/${data.docket}/${DOCUMENTS}/${data.id}`,
      providesTags: [TAGS.DOCUMENT],
    }),
    getDocumentTypes: builder.query({
      query: () => DOCUMENT_TYPE,
      providesTags: [TAGS.DOCUMENT_TYPE],
    }),
    getDocketTypes: builder.query({
      query: () => DOCKET_TYPES,
    }),
    getDocumentLibraryTemplates: builder.query({
      query: (groupBy) => ({
        url: DOCUMENT_LIBRARY_TEMPLATES,
        params: { groupBy },
      }),
      transformResponse: (response) => {
        if (response.items) {
          return response.items;
        }

        return response;
      },
    }),
    downloadDocument: builder.query({
      query: (args) => {
        const { docketId, documentId, versionId } = args;
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/${documentId}/download`,
          params: { versionId },
        };
      },
    }),
    searchDocument: builder.query({
      query: (search) => {
        console.log(search);
        return {
          url: `search`,
          params: { search },
        };
      },
    }),
    createDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docketId}/${DOCUMENTS}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    createLibrary: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${DOCUMENT_LIBRARY}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.LIBRARIES],
    }),

    createDocket: builder.mutation({
      query: (body) => ({
        url: `${DOCUMENT_LIBRARIES}/${getCurrentTenant()}/docket`,
        method: HTTP_METHODS.POST,
        body,
      }),
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    uploadMultiple: builder.mutation({
      query: (payload) => {
        const { docketId, formData } = payload;
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/upload`,
          method: HTTP_METHODS.POST,
          body: formData,
        };
      },
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),

    uploadDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docketId}/${DOCUMENTS}/${payload.documentId}/upload`,
        method: HTTP_METHODS.PUT,
        body: payload.formData,
      }),
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    updateDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docketId}/${DOCUMENTS}/${payload.documentId}/update`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      // invalidatesTags: [TAGS.DOCUMENT],
    }),
    updateVersion: builder.mutation({
      query: (payload) => {
        const { docket, document, versionId } = payload;
        return {
          url: `${DOCKETS}/${docket}/${DOCUMENTS}/${document}/revert`,
          method: HTTP_METHODS.PUT,
          params: { versionId },
        };
      },
    }),
    updateLockUnlockDocket: builder.mutation({
      query: (payload) => {
        const { id, isLocked } = payload;
        return {
          url: `${DOCKETS}/${id}/lockdocket`,
          method: HTTP_METHODS.PUT,
          params: { isLocked },
        };
      },
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    updateLockUnlockDocument: builder.mutation({
      query: (payload) => {
        const { docket, document, isLocked } = payload;
        return {
          url: `${DOCKETS}/${docket}/${DOCUMENTS}/${document}/lockdocument`,
          method: HTTP_METHODS.PUT,
          params: { isLocked },
        };
      },
      // invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    updateDocumentLocation: builder.mutation({
      query: (payload) => {
        const { docketId, documentId, moveToDocketId } = payload;
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/${documentId}/move/${moveToDocketId}`,
          method: HTTP_METHODS.PUT,
        };
      },
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    assignDocumentToDocket: builder.mutation({
      query: (payload) => {
        const { docketId, documentId } = payload;
        console.log(docketId);
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/assign`,
          method: HTTP_METHODS.PUT,
          params: { documentId },
        };
      },
    }),
    unassignDocumentFromDocket: builder.mutation({
      query: (payload) => {
        const { docketId, documentId } = payload;
        console.log(documentId);
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/remove`,
          method: HTTP_METHODS.PUT,
          params: { documentId },
        };
      },
    }),
    deleteDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docket}/${DOCUMENTS}/${payload.id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.LIBRARIES],
    }),
    deleteDocket: builder.mutation({
      query: (id) => ({
        url: `${DOCKETS}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.DOCKET, TAGS.LIBRARIES],
    }),
    deleteLibrary: builder.mutation({
      query: (id) => ({
        url: `${DOCUMENT_LIBRARIES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.LIBRARIES],
    }),
    getDocumentCategory: builder.query({
      query: () => DOCUMENT_CATEGORY,
      providesTags: [TAGS.DOCUMENT_CATEGORY],
    }),
    getDocumentPreview: builder.query({
      query: ({ documentId, docketId, versionId }) => ({
        url: `documentAccess/docket/${docketId}/document/${documentId}/version/${versionId}/preview`,
      }),
    }),
    createDocumentCategory: builder.mutation({
      query: (payload) => ({
        url: DOCUMENT_CATEGORY,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.DOCUMENT_CATEGORY],
    }),
    deleteDocumentCategory: builder.mutation({
      query: (id) => ({
        url: `${DOCUMENT_CATEGORY}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.DOCUMENT_CATEGORY],
    }),
    updateDocumentCategory: builder.mutation({
      query: (payload) => ({
        url: `${DOCUMENT_CATEGORY}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.DOCUMENT_CATEGORY],
    }),
    createDocumentType: builder.mutation({
      query: (payload) => ({
        url: DOCUMENT_TYPE,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.DOCUMENT_TYPE],
    }),
    deleteDocumentType: builder.mutation({
      query: (id) => ({
        url: `${DOCUMENT_TYPE}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.DOCUMENT_TYPE],
    }),
    updateDocumentType: builder.mutation({
      query: (payload) => ({
        url: `${DOCUMENT_TYPE}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.DOCUMENT_TYPE],
    }),
  }),
});

export const {
  useGetDocketQuery,
  useLazyGetDocketQuery,
  useGetDocumentQuery,
  useGetDocumentTypesQuery,
  useSearchDocumentQuery,
  useDownloadDocumentQuery,
  useGetCompanyLibraryQuery,
  useLazyGetCompanyLibraryQuery,
  useGetDocketTypesQuery,
  useLazyGetDocketTypesQuery,
  useGetDocumentLibraryTemplatesQuery,
  useGetDocumentPreviewQuery,
  useCreateDocumentMutation,
  useCreateLibraryMutation,
  useCreateDocketMutation,
  useUploadMultipleMutation,
  useUpdateVersionMutation,
  useUploadDocumentMutation,
  useUpdateDocumentMutation,
  useUpdateLockUnlockDocumentMutation,
  useUpdateLockUnlockDocketMutation,
  useUpdateDocumentLocationMutation,
  useAssignDocumentToDocketMutation,
  useUnassignDocumentFromDocketMutation,
  useDeleteDocumentMutation,
  useDeleteDocketMutation,
  useGetDocumentCategoryQuery,
  useCreateDocumentCategoryMutation,
  useCreateDocumentTypeMutation,
  useUpdateDocumentTypeMutation,
  useDeleteDocumentCategoryMutation,
  useUpdateDocumentCategoryMutation,
  useDeleteDocumentTypeMutation,
  useDeleteLibraryMutation,
} = documentsApi;
