import { createApi } from "@reduxjs/toolkit/query/react";

import {
  COMPANIES,
  BOARD_RESOLUTIONS,
  BOARD_RESOLUTION_TYPES,
} from "src/helpers/url";

import getCurrentTenant from "src/helpers/getCurrentTenant";
import { HTTP_METHODS } from "src/constants";
import { baseQuery } from "../apiService";

export const TAGS = {
  BOARD_RESOLUTIONS: "BOARD_RESOLUTIONS",
  BOARD_RESOLUTION: "BOARD_RESOLUTION",
};

export const boardResolutionsApi = createApi({
  reducerPath: "boardResolutionsApi",
  baseQuery,
  tagTypes: [TAGS.BOARD_RESOLUTION, TAGS.BOARD_RESOLUTIONS],
  endpoints: (builder) => ({
    getBoardResolutions: builder.query({
      query: (payload) => {
        const { name, sort, type } = payload;
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}`,
          params: { name, sort, type },
        };
      },
      providesTags: [TAGS.BOARD_RESOLUTIONS],
    }),
    getBoardResolutionById: builder.query({
      query: (id) =>
        `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}/${id}`,
      providesTags: [TAGS.BOARD_RESOLUTION],
    }),
    getBoardResolutionTypes: builder.query({
      query: () => BOARD_RESOLUTION_TYPES,
    }),
    createBoardResolution: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [TAGS.BOARD_RESOLUTIONS],
    }),
    setFinalArtifact: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}/${
          payload.id
        }/final-artifact`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
    }),
    setLinkedDocuments: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}/${
          payload.id
        }/linked-documents`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
    }),
    updateBoardResolution: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}/${
          payload.id
        }`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.BOARD_RESOLUTION],
    }),
    deleteBoardResolution: builder.mutation({
      query: (id) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${BOARD_RESOLUTIONS}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.BOARD_RESOLUTIONS],
    }),
  }),
});

export const {
  useGetBoardResolutionsQuery,
  useLazyGetBoardResolutionsQuery,
  useGetBoardResolutionByIdQuery,
  useGetBoardResolutionTypesQuery,
  useLazyGetBoardResolutionByIdQuery,
  useCreateBoardResolutionMutation,
  useSetFinalArtifactMutation,
  useSetLinkedDocumentsMutation,
  useUpdateBoardResolutionMutation,
  useDeleteBoardResolutionMutation,
} = boardResolutionsApi;
