import React from "react";
import PropTypes from "prop-types";
import NoData from "src/components/NoData";
import FloatingButton from "src/components/FloatingButton";

const NoAuthorities = ({ message, onAdd }) => (
  <>
    <NoData boxSx={{ marginTop: "100px" }} title={message} />

    <FloatingButton title="Add Authority" onClick={onAdd} />
  </>
);

NoAuthorities.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NoAuthorities;
