import { useFormik } from "formik";
//actions
import { closeFormDialog } from "src/redux/slice";
import { useEffect } from "react";

// hooks
import { useDispatch } from "src/store";

// other
import toast from "react-hot-toast";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import validationSchema from "../validations";
import { useCreateDepartmentMutation } from "src/redux/company/api";
import getCurrentTenant from "src/helpers/getCurrentTenant";

const useNewDepartment = () => {
  const dispatch = useDispatch();

  const [create, { isLoading, isError, isSuccess, error, data }] =
    useCreateDepartmentMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      create({ ...values, companyId: getCurrentTenant() });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("New Department Created Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch(closeFormDialog());
    }
  }, [data, dispatch, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error("Department Already Exists.", {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [dispatch, isError, error]);

  return {
    formik,
    isLoading,
  };
};

export default useNewDepartment;
