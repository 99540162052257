// src/redux/chatApi.js
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../apiService";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery,
  endpoints: (builder) => ({
    getChatCompletion: builder.query({
      query: ({
        systemPrompt,
        userPrompt,
        docketId,
        documentId,
        versionId,
      }) => ({
        url: `GPT`,
        params: { systemPrompt, userPrompt, docketId, documentId, versionId },
      }),
    }),
  }),
});

export const { useGetChatCompletionQuery } = chatApi;
