export const pageTitles = {
  home: {
    index: "Home",
  },
  dashboard: {
    index: "Global View",
  },
  people: {
    corporateManagement: {
      index: "Corporate Management",
    },
    boardOfDirectors: {
      index: "Board of Directors",
    },
    companySecretaries: {
      index: "Company Secretaries",
    },
    auditors: {
      index: "Auditors",
    },
  },
  secretarial: {
    companyDocuments: {
      index: "Company Files",
      detail: "Document detail",
    },
    statutoryDocuments: {
      index: "Share Structure",
      newShareholder: "New Shareholder",
      issueShares: "Issue Shares",
      editShare: "Update Share",
    },
    boardResolutions: {
      index: "Minute Book",
      newBoardResolution: "New Minute Book",
    },
  },
  banking: {
    accounts: {
      index: "Accounts",
      create: "New Account",
    },
    facilities: {
      index: "Bank Facilities",
      create: "New Facility",
    },
    creditCards: {
      index: "Credit Cards",
      create: "New Credit Card",
      detail: "Credit Card Details",
    },
  },
  leasing: {
    facilities: {
      index: "Lease Facility",
      create: "New Lease Facility",
      renewal: "Renewal Lease",
      subLease: "Sub Lease",
      assignLease: "Assign Lease",
    },
  },
  workFlows: {
    requests: {
      index: "Requests",
      newRequest: "New Request",
      detail: "Request detail",
      registrarOfCompanies: {
        appointmentOfDirector: {
          index: "New Appointment of Director Request",
          detail: "Appointment of Director Detail",
        },
        changeOfArticles: {
          index: "New of Articles Change Request",
          detail: "Change of Articles Detail",
        },
        changeRegisteredAddress: {
          index: "New Registered Address Change Request",
          detail: "Change Registered Address Detail",
        },
        changeCompanyAuditors: {
          index: "New Company Auditors Change Request",
          detail: "Change Company Auditors Detail",
        },
        changeCompanyName: {
          index: "New Company Name Change Request",
          detail: "Change Company Name Detail",
        },
        changeCompanySecretaries: {
          index: "New Company Secretaries Change Request",
          detail: "Change Company Secretaries Detail",
        },
        newCompanyIncorporation: {
          index: "New Company Incorporation Request",
          detail: "New Company Incorporation Detail",
        },
        removeDirector: {
          index: "New Remove Director Request",
          detail: "Remove Director Detail",
        },
      },
      shares: {
        issueShares: {
          index: "New Issue Shares Request",
          detail: "Issue Shares Detail",
        },
        transferShares: {
          index: "New Transfer Shares Request",
          detail: "Transfer Shares Detail",
        },
        shareAcquisition: {
          index: "New Share Acquisition Request",
          detail: "Share Acquisition Detail",
        },
      },
      banking: {
        removeSignatory: {
          index: "Remove Account Signatory",
          detail: "Remove Account Signatory Detail",
        },
        addSignatory: {
          index: "Add Account Signatory",
          detail: "Add Account Signatory Detail",
        },
        closeAccount: {
          index: "Close Account",
          detail: "Close Account Detail",
        },
        newBankFacility: {
          index: "New Bank Facility",
          detail: "New Bank Facility Detail",
        },
        newAccountOpening: {
          index: "New Account Opening",
          detail: "New Account Opening Detail",
        },
        increaseTransactionLimit: {
          index: "Increase Transaction Limit",
          detail: "Increase Transaction Limit Detail",
        },
        enableOnlineBanking: {
          index: "Enable Online Banking",
          detail: "Enable Online Banking  Detail",
        },
        issueCreditCard: {
          index: "Issue Credit Card",
          detail: "Issue Credit Card Detail",
        },
        enableInternetBanking: {
          index: "Enable Internet Banking",
          detail: "Enable Internet Banking Detail",
        },
        enableMerchantAgreement: {
          index: "Enable Merchant Agreement",
          detail: "Enable Merchant Agreement Detail",
        },
        obtainEWindowFacility: {
          index: "Obtain EWindow Facility",
          detail: "Obtain EWindow Facility Detail",
        },
        activateDormantAccount: {
          index: "Activate Dormant Account",
          detail: "Activate Dormant Account Detail",
        },
        cancelCreditCard: {
          index: "Cancel Credit Card",
          detail: "Cancel Credit Card Detail",
        },
        increaseAuthLevel: {
          index: "Increase Auth Level",
          detail: "Increase Auth Level Detail",
        },
        increaseCreditCardLimit: {
          index: "Increase Credit Card Limit",
          detail: "Increase Credit Card Limit Detail",
        },
      },
      leasing: {
        newLease: {
          index: "New Lease Request",
          detail: "New Lease Detail",
        },
        renewalOfLease: {
          index: "Renewal of Lease Request",
          detail: "Renewal of Lease Detail",
        },
        cancellationOfLease: {
          index: "Cancellation of Lease Request",
          detail: "Cancellation of Lease Detail",
        },
        assignmentOfLease: {
          index: "Assignment of Lease Request",
          detail: "Assignment of Lease Detail",
        },
        subLease: {
          index: "Sub Lease Request",
          detail: "Sub Lease Detail",
        },
      },
      miscellaneous: {
        companyAcquisition: {
          index: "New Company Acquisition Request",
          detail: "Company Acquisition Detail",
        },
        companyDivestment: {
          index: "New Company Divestment Request",
          detail: "Company Divestment Detail",
        },
        badDebtWriteOff: {
          index: "New Bad Debt Write Off Request",
          detail: "Bad Debt Write Off Detail",
        },
        guarantees: {
          index: "New Guarantees Request",
          detail: "Guarantees Detail",
        },
        annualQuarterlyFinancialReport: {
          index: "New Annual Quarterly Financial Report Request",
          detail: "Annual Quarterly Financial Report Detail",
        },
        annualReport: {
          index: "New Annual Report Request",
          detail: "Annual Report Detail",
        },
        authorizedSignatories: {
          index: "New Authorized Signatories Request",
          detail: "Authorized Signatories Detail",
        },
        dividendDeclaration: {
          index: "New Dividend Declaration Request",
          detail: "Dividend Declaration Detail",
        },
        irdLiaisonAuthorization: {
          index: "New IRD Liaison Authorization Request",
          detail: "IRD Liaison Authorization Detail",
        },
        bonds: {
          index: "New Bond Request",
          detail: "Bond Detail",
        },
      },
    },
    tasks: {
      index: "Tasks",
      detail: "Task Detail",
    },
  },
  organization: {
    documents: {
      index: "Repository",
    },
  },
  admin: {
    companies: {
      index: "Companies",
      newCompany: "New Company",
      companyDetail: "Company Detail",
    },
    employees: {
      index: "Employees",
      newEmployee: "New Employee",
      EmployeeDetail: "Employee Detail",
      UpdateEmployee: "Update Employee",
      manageAuthorities: "Manage Authorities",
    },
    stakeholders: {
      index: "Stakeholders",
      detail: "Stakeholder Details",
    },
    documentcategory: {
      index: "Document Categories",
      detail: "Document Categories",
    },
    documenttype: {
      index: "Document Types",
      detail: "Document Types",
    },
    companyAccessControl: {
      index: "Company Access Control",
      detail: "Company Access Control",
    },
    applicationAccessControl: {
      index: "Application Access Control",
      detail: "Application Access Control",
    },
    departments: {
      index: "Departments",
      detail: "Departments",
    },
    users: {
      index: "Users",
      detail: "User Details",
      newUser: "New User",
      manageAccess: {
        index: "Manage Access",
      },
    },
    sectors: {
      index: "Sectors",
      detail: "Sector Details",
      new: "New Sector",
    },
    geography: {
      index: "Geography",
      detail: "Geography Details",
      new: "New Country",
    },
    currency: {
      index: "Currency",
      detail: "Currency Details",
      new: "New Currency",
    },
    workflowRequestTypes: {
      index: "Workflow Request Types",
    },
    authorities: {
      index: "Authorities",
      newAuthority: "New Authority",
      authorityDetails: "Authority Details",
    },
    banks: {
      index: "Banks",
      create: "New Bank",
      detail: "Bank Details",
    },
    Lessors: {
      index: "Lessors",
      create: "New Lessor",
      detail: "Lessor Details",
    },
    corporateRoles: {
      index: "Corporate Roles",
    },
  },
};
