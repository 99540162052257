import { useState, useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import { useDispatch, useSelector } from "src/store";
import {
  documentsApi,
  useUploadDocumentMutation,
  TAGS,
} from "src/redux/documents/api";

// redux
import { closeFormDialog, openFormDialog } from "src/redux/slice";
import { formDialogSelector } from "src/redux/selectors";

// other
import { DIALOG_TYPE } from "src/components/docket/constants";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const DEFAULT_MESSAGE = "Document uploaded successfully.";

const useUploadLogic = ({ docketId = 0, message = DEFAULT_MESSAGE }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const { data: uploadDetails } = useSelector(formDialogSelector);

  const [uploadDocument, { isLoading, isSuccess, isError, error, reset }] =
    useUploadDocumentMutation();

  // handle document upload success
  useEffect(() => {
    if (isSuccess) {
      toast.success(message, {
        duration: DEFAULT_TOAST_DURATION,
      });

      dispatch(closeFormDialog());
      dispatch({
        type: `${documentsApi.reducerPath}/invalidateTags`,
        payload: [TAGS.DOCUMENT],
      }); // invalidate manually
      reset();
    }
  }, [dispatch, isSuccess, message, reset]);

  // handle document upload error
  useEffect(() => {
    if (isError)
      toast.error(`Error on Document Uploading: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [error, isError]);

  const dropHandler = (files, event) => {
    setFiles([...files]);
  };

  const removeHandler = (file) =>
    setFiles((prev) => [...prev.filter((_file) => _file.path !== file.path)]);

  // open file upload dialog
  const uploadClickHandler = (document) =>
    dispatch(
      openFormDialog({
        documentId: document.id,
        docketId: docketId,
        type: DIALOG_TYPE.UPLOAD,
      })
    );

  const uploadHandler = () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    uploadDocument({ ...uploadDetails, formData });
  };

  const isUploadDisabled = files.length === 0;

  return {
    files,
    isLoading,
    isUploadDisabled,
    uploadDocument,
    dropHandler,
    removeHandler,
    uploadHandler,
    uploadClickHandler,
  };
};

export default useUploadLogic;
