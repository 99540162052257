import { Box } from "@mui/material";
import React from "react";
import { neutral } from "src/theme/colors";

const Container = ({ children }) => (
  <Box
    sx={{
      padding: 2,
      bgcolor: neutral[100],
      display: "flex",
      flexDirection: "column",
      gap: 4,
    }}
  >
    {children}
  </Box>
);

export default Container;
