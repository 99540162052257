const { createContext } = require("react");

const AuthContext = createContext({
  user: null,
  token: null,
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  userProfilePicURL: null,
  ability: null,
  onSignIn: () => {},
  onSignOut: () => {},
});

export default AuthContext;
