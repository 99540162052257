import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../apiService";
import { LEASE_FACILITIES } from "../../helpers/url";
import { HTTP_METHODS } from "../../constants";

export const TAGS = {
  LEASING: "LEASING",
  FACILITIES: "FACILITIES",
  FACILITY: "FACILITY",
};

export const leasingApi = createApi({
  reducerPath: "leasingApi",
  baseQuery,
  tagTypes: [TAGS.FACILITY, TAGS.LEASING],
  endpoints: (builder) => ({
    getLeases: builder.query({
      query: () => LEASE_FACILITIES,
      providesTags: [TAGS.FACILITY, TAGS.FACILITIES],
    }),
    getLease: builder.query({
      query: (id) => `${LEASE_FACILITIES}/${id}`,
      providesTags: [TAGS.LEASING], //..
    }),
    createLease: builder.mutation({
      query: (payload) => ({
        url: LEASE_FACILITIES,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.FACILITIES],
    }),
    updateDeactivateLease: builder.mutation({
      query: (payload) => (
        {
          url: `${LEASE_FACILITIES}/cancel/${payload.id}`,
          method: HTTP_METHODS.PUT,
          body: payload,
        }),
    }),
  }),
});
export const {
  useGetLeasesQuery,
  useGetLeaseQuery,
  useLazyGetLeaseQuery,
  useCreateLeaseMutation,
  useUpdateDeactivateLeaseMutation,
} = leasingApi;
