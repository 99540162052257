import Base from "../Base";

class Currency extends Base {
  constructor({ id, name, code, createdAt, createdBy, updatedAt, updatedBy }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, { name, code });
  }
}

export default Currency;
