import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES } from "src/helpers/url";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  CORPORATE_ROLES: "CORPORATE_ROLES",
};
export const corporateRoleApi = createApi({
  reducerPath: "corporateRoleApi",
  baseQuery,
  tagTypes: [TAGS.CORPORATE_ROLES],
  endpoints: (builder) => ({
    getCorporateRoles: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/CorporateRoles`,
      providesTags: [TAGS.CORPORATE_ROLES],
    }),
    createCorporateRole: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/CorporateRole`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_ROLES],
    }),
  }),
});

export const { useGetCorporateRolesQuery, useCreateCorporateRoleMutation } =
  corporateRoleApi;
