import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, Divider, Typography, Stack, Button } from "@mui/material";

// hooks
import { useSelector } from "src/store";

// redux
import { formDialogSelector } from "src/redux/selectors";
import { rowSelectionStateInitializer } from "@mui/x-data-grid/internals";

const NewConfirmation = ({ onClose, onConfirm }) => {
  const { data } = useSelector(formDialogSelector);

  return (
    <>
      {/*<Divider />*/}
      <Box sx={{ mt: 1, mb: 2, mr: 2, ml: 2 }}>
        <Typography variant={"h6"} align="center">
          {data?.message}
        </Typography>
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />

        <Stack direction="row" spacing={2}>
          <Button sx={{ marginRight: 2, color: "black" }} onClick={onClose}>
            No
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Yes
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

NewConfirmation.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default NewConfirmation;
