import Departments from "src/features/admin/departments";
import usePageTitle from "src/hooks/usePageTitle";
import { pageTitles } from "src/pageTitles";

const DepartmentsPage = () => {
  usePageTitle({ title: pageTitles.admin.departments.index });

  return <Departments />;
};
export default DepartmentsPage;
