import PropTypes from "prop-types";

// mui
import { Box, Stack } from "@mui/material";

// components
import SideNavItem from "./SideNavItem";

const renderItems = ({ depth = 0, items, pathname }) =>
  items.reduce(
    (acc, item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
      }),
    []
  );

const reduceChildRoutes = ({ acc, depth, item, pathname }) => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path) : false;
  const exactMatch = checkPath ? pathname === item.path : false;

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </Stack>
      </SideNavItem>
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const SideNavSection = ({ items = [], pathname, subheader = "" }) => (
  <Stack
    component="ul"
    spacing={0.5}
    sx={{
      listStyle: "none",
      m: 0,
      p: 0,
    }}
  >
    {subheader && (
      <Box
        component="li"
        sx={{
          color: "var(--nav-section-title-color)",
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.66,
          mb: 1,
          ml: 1,
          textTransform: "uppercase",
        }}
      >
        {subheader}
      </Box>
    )}
    {renderItems({ items, pathname })}
  </Stack>
);

SideNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};

SideNavSection.defaultProps = {
  items: [],
  subheader: "",
};

export default SideNavSection;
