import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../constants";
import { CURRENCY } from "../../helpers/url";
import { baseQuery } from "../apiService";

export const TAGS = {
  CURRENCY: "CURRENCY",
};
export const currenciesApi = createApi({
  reducerPath: "currenciesApi",
  baseQuery,
  tagTypes: [TAGS.CURRENCY],
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: () => `${CURRENCY}`,
      providesTags: [TAGS.CURRENCY],
    }),
    getCurrency: builder.query({
      query: (id) => `${CURRENCY}/${id}`,
      providesTags: [TAGS.CURRENCY],
    }),
    createCurrency: builder.mutation({
      query: (payload) => ({
        url: `${CURRENCY}/`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.CURRENCY],
    }),
    updateCurrency: builder.mutation({
      query: (payload) => ({
        url: `${CURRENCY}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.CURRENCY],
    }),
    deleteCurrency: builder.mutation({
      query: (id) => ({
        url: `${CURRENCY}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.CURRENCY],
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useGetCurrencyQuery,
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation,
} = currenciesApi;
