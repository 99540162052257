import { useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import { useGetDocumentCategoryQuery } from "src/redux/documents/api";

// redux
import { ERROR_TOAST_DURATION } from "src/constants";

const useGetDocumentCategory = () => {
  const { data= [], isLoading, isError, error } = useGetDocumentCategoryQuery();

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Fetching Categories: ${error?.data?.detail}.`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  const List = data || [];
  const selectOptions = List.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const title = `Total (${data?.length ?? 0})`;
  return {
    data,
    selectOptions,
    isLoading,
    title,
  };
};

export default useGetDocumentCategory;
