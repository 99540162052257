import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

// other
import generateUniqueKey from "src/utils/generateUniqueKeys";
import Account from "src/models/organization/bank/Account";

const rows = [
  {
    key: generateUniqueKey("account_row_1"),
    cells: [
      {
        label: "Account",
        name: "accountNumber",
      },
      {
        label: "Account Type",
        name: "accountType",
      },
    ],
  },
  {
    key: generateUniqueKey("account_row_2"),
    cells: [
      {
        label: "Currency",
        name: "currencyCode",
      },
      {
        label: "Finance Limit",
        name: "amount",
      },
    ],
  },
];

const AccountDetails = ({ authorization }) => {
  if (!authorization.account) return;
  return (
    <Card>
      <CardHeader title="Account Details" />
      <Divider />
      <TableContainer sx={{ pl: 1, pr: 1 }}>
        <Table>
          <TableBody>
            {rows.map(({ key, cells }) => (
              <TableRow key={key}>
                {cells.map(({ label, name }) => (
                  <TableCell key={label}>
                    <Typography variant="caption">{label}</Typography>
                    <Typography variant="subtitle2">{label !== "Finance Limit" ? authorization.account[name] : authorization.financeLimit}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

AccountDetails.propTypes = {
  account: PropTypes.instanceOf(Account),
};

export default AccountDetails;
