import { useEffect, useState } from "react";
import { useContext } from "react";

// context
import AuthContext from "src/contexts/auth/AuthContext";

// context
import useSessionStorage from "src/hooks/useSessionStorage";
import usePopover from "src/hooks/usePopover";
import useRouter from "src/hooks/useRouter";
import { paths } from "src/paths";

const useTenant = () => {
  const { user, isAdmin } = useContext(AuthContext);
  const { anchorRef, open, handleToggle, handleClose } = usePopover();
  const { replace } = useRouter();

  const [selectedTenant, setSelectedTenant] = useSessionStorage("tenant");
  const [keyword, setKeyword] = useState("");

  const { accessibleCompanies = [] } = user ?? {};

  // set default tenant
  useEffect(() => {
    if (accessibleCompanies.length > 0 && !selectedTenant) {
      setSelectedTenant(accessibleCompanies[0]);
      window.location.reload(); // Libraries not sync for required documents
    }
  }, [accessibleCompanies, selectedTenant, setSelectedTenant]);

  const changeHandler = (tenant) => {
    setSelectedTenant(tenant);

    if (!isAdmin) replace(paths.home.index);

    if (isAdmin) replace(paths.admin.index);

    window.location.reload(false);
  };

  const searchChangeHandler = (event) => setKeyword(event.target.value);

  const filteredCompanies = keyword
    ? accessibleCompanies.filter((company) =>
        company.commonName.toLowerCase().includes(keyword.toLowerCase())
      )
    : accessibleCompanies;

  return {
    accessibleCompanies,
    filteredCompanies,
    anchorRef,
    open,
    selectedTenant,
    keyword,
    handleToggle,
    handleClose,
    changeHandler,
    searchChangeHandler,
  };
};

export default useTenant;
