const getCurrentTenant = () => {
  const tenantString = window.sessionStorage.getItem("tenant");

  if (tenantString) {
    const { id: companyId } = JSON.parse(tenantString);
    return companyId;
  }
  return 0;
};

export default getCurrentTenant;
