import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getCurrentTenant from "src/helpers/getCurrentTenant";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/`,
  prepareHeaders: (headers) => {
    // add the company id to the headers
    const company = getCurrentTenant();
    headers.set("companyId", company);

    // add the JWT token to the headers
    const token = JSON.parse(window.sessionStorage.getItem("ad_token"));
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export { baseQuery };
