// hooks
import EmployeesFeature from "src/features/admin/employees";
import usePageTitle from "src/hooks/usePageTitle";

// features

// other
import { pageTitles } from "src/pageTitles";

const Employees = () => {
  usePageTitle({ title: pageTitles.admin.employees.index });

  return <EmployeesFeature />;
};

export default Employees;
