import { graphConfig } from "src/authConfig";

export const getProfilePic = async () => {
  // set headers required
  const headers = new Headers();
  const bearer = `Bearer ${JSON.parse(sessionStorage.getItem("graph_token"))}`;
  headers.append("Authorization", bearer || "");
  try {
    const response = await fetch(graphConfig.graphMeEndpoint, {
      method: "GET",
      headers,
    });

    if (!response.ok) return null;

    return response.blob();
  } catch (error) {
    console.error("Error fetching profile picture:", error);
  }
};
