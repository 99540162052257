import generateUniqueKey from "src/utils/generateUniqueKeys";
import { INPUT_TYPES } from "src/constants";
export const employeeBasicDetails = [
  {
    key: generateUniqueKey("firstName"),
    label: "First Name ",
    name: "firstName",
    required: true,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("lastName"),
    label: "Last Name",
    name: "lastName",
    required: true,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("birthDay"),
    label: "Birth Day",
    name: "dateOfBirth",
    required: false,
    type: INPUT_TYPES.DATE,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("gender"),
    label: "Gender",
    name: "gender",
    type: INPUT_TYPES.RADIO,
    required: false,
    options: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("nicNumber"),
    label: "NIC Number",
    name: "nicNumber",
    required: true,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },

  {
    key: generateUniqueKey("passportNumber"),
    label: "Passport Number",
    name: "passportNumber",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("personalEmail"),
    label: "Personal Email",
    name: "personalEmail",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("mobileNumber"),
    label: "Mobile Number",
    name: "mobileNumber",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
];

export const employeeoOfficialDetails = [
  {
    key: generateUniqueKey("employeeNumber"),
    label: "Employee Number",
    name: "employeeNumber",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("officeNumber"),
    label: "Office Number",
    name: "officeNumber",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("companyEmail"),
    label: "Company Email",
    name: "companyEmail",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: "departmentId",
    label: "Department",
    name: "departmentId",
    required: false,
    type: INPUT_TYPES.SELECT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("dateOfAppoinment"),
    label: "Appointment Date",
    name: "dateOfAppoinment",
    required: false,
    type: INPUT_TYPES.DATE,
    xs: 12,
    md: 6,
  },
];
export const employeeResidentialDetails = [
  {
    key: generateUniqueKey("addressNmber"),
    label: "Address Number",
    name: "addressNumber",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("addressLine1"),
    label: "Addresss Line 1",
    name: "addressLine1",
    required: false,
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("addressLine2"),
    label: "Addresss Line 2",
    name: "addressLine2",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("street"),
    label: "Street",
    name: "street",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("city"),
    label: "City",
    name: "city",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("province"),
    label: "Province",
    name: "province",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("country"),
    label: "Country",
    name: "country",
    required: false,
    type: INPUT_TYPES.SELECT,

    xs: 12,
    md: 6,
  },

  {
    key: generateUniqueKey("zip_code"),
    label: "Zip Code",
    name: "zipCode",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
];

export const corporateProfileDetails = [
  {
    key: generateUniqueKey("name"),
    label: "Name",
    name: "name",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("corporateRoleId"),
    label: "Corporate Role",
    name: "corporateRoleId",
    required: false,
    type: INPUT_TYPES.SELECT,
    xs: 12,
    md: 6,
  },
  {
    key: generateUniqueKey("user"),
    label: "User",
    name: "userId",
    type: INPUT_TYPES.SELECT,
    required: false,
    xs: 12,
    md: 6,
  },
];
