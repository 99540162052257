import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  library: null,
  view: "grid",
  path: {},
  searchKeyWord: "",
  currentDocket: "",
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setLibrary: (state, action) => {
      state.library = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setPath: (state, action) => {
      state.path = { ...state.path, ...action.payload };
    },
    removePath: (state, action) => {
      const temp = { ...state.path };
      const keys = Object.keys(temp);
      const indexToRemove = keys.indexOf(action.payload);

      if (indexToRemove > -1) {
        keys.slice(indexToRemove + 1).forEach((key) => {
          delete temp[key];
        });
      }

      state.path = temp;
    },
    resetPath: (state) => {
      state.path = initialState.path;
    },
    removeLast: (state) => {
      const temp = { ...state.path };
      const keys = Object.keys(state.path);
      const lastKey = keys[keys.length - 1];
      delete temp[lastKey];

      state.path = temp;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    resetSearchKeyWord: (state) => {
      state.searchKeyWord = initialState.searchKeyWord;
    },
    setCurrentDocket: (state, action) => {
      state.currentDocket = action.payload;
    },
  },
});

const { actions, reducer } = documentsSlice;

export const {
  setLibrary,
  setView,
  setPath,
  removePath,
  resetPath,
  removeLast,
  setSearchKeyWord,
  resetSearchKeyWord,
  setCurrentDocket,
} = actions;

export default reducer;
