import { useCallback, useEffect, useMemo } from "react";
import numeral from "numeral";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useFormik } from "formik";

// hooks
import { useCreateAuthorizationsMutation } from "../../redux/api";
import { useGetCorporateAuthoritiesByInstitutionQuery } from "src/redux/corporateAuthorities/api";
import { useDispatch } from "src/store";
import { useGetInstitutionsQuery } from "src/redux/company/api";
import { useLazyGetBankQuery } from "src/redux/banking/api";

// redux
import { closeFormDialog } from "src/redux/slice";

// other
import { assignAuthorityValidationSchema } from "../../validations";
import generateInputField from "src/helpers/generateInputField";
import generateOptions from "src/utils/generateOptions";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import { Grid } from "@mui/material";

const useAssignNewAuthorityFormik = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { data: institutions = [] } = useGetInstitutionsQuery();

  const [createAuthorization, { isLoading, isSuccess, isError, error }] =
    useCreateAuthorizationsMutation();

  const [fetchBank, { data: bank }] = useLazyGetBankQuery();

  const formik = useFormik({
    initialValues: {
      institutionId: 0,
      accountId: 0,
      corporateAuthorityId: 0,
      corporateAuthorityLevelId: 0,
    },
    validationSchema: assignAuthorityValidationSchema(institutions),
    onSubmit: (values) =>
      createAuthorization({
        ...values,
        employeeId: Number(params.id),
        companyId: Number(getCurrentTenant()),
      }),
  });

  const { handleSubmit } = formik;
  const { corporateAuthorityId, institutionId } = formik.values;

  const institutionType = useMemo(
    () =>
      institutions.find((institution) => institution.id === institutionId)
        ?.institutionType,
    [institutionId, institutions]
  );

  const { data: corporateAuthorities = [] } =
    useGetCorporateAuthoritiesByInstitutionQuery(institutionId, {
      skip: institutionId === 0, // skip fetching when there is no institution selected
    });

  // fetch bank if institution if bank
  useEffect(() => {
    if (institutionType === "Bank") fetchBank(institutionId);
  }, [fetchBank, institutionId, institutionType]);

  // success feedback
  useEffect(() => {
    if (isSuccess) {
      toast.success("Authority Assigned Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch(closeFormDialog());
    }
  }, [dispatch, isSuccess]);

  // failure feedback
  useEffect(() => {
    if (isError)
      toast.error(`Error on Assigning Authority: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [isError, error]);

  // authorities without levels
  const authorities = useMemo(
    () =>
      corporateAuthorities.map(
        ({ corporateAuthorityLevels, ...corporateAuthority }) =>
          corporateAuthority
      ),
    [corporateAuthorities]
  );

  // get authority levels of selected authority
  const getLevels = useCallback(() => {
    if (corporateAuthorityId > 0) {
      const authority = corporateAuthorities.find(
        (authority) => authority.id === corporateAuthorityId
      );

      if (authority) {
        return authority.corporateAuthorityLevels;
      }

      return [];
    }

    return [];
  }, [corporateAuthorities, corporateAuthorityId]);

  const institutionOptions = useMemo(
    () => generateOptions(institutions, "id", "name"),
    [institutions]
  );

  const levelOptions = generateOptions(
    getLevels(),
    "id",
    null,
    ({ name, financeLimit }) =>
      `${name} (${numeral(financeLimit).format("0,0.00")})`
  );

  const accountOptions = useMemo(
    () => generateOptions(bank?.accounts, "id", "accountNumber"),
    [bank]
  );

  const renderFields = (field) => {
    let options = [];

    if (field.show && !field.show(institutionType)) return;

    if (field.name === "institutionId") options = institutionOptions;

    if (field.name === "corporateAuthorityId")
      options = generateOptions(authorities, "id", "title");

    if (field.name === "corporateAuthorityLevelId") options = levelOptions;

    if (field.name === "accountId") options = accountOptions;

    return (
      <Grid item key={field.key} xs={field.xs}>
        {generateInputField(field, formik, options)}
      </Grid>
    );
  };

  console.log(formik.errors);

  return {
    isLoading,
    handleSubmit,
    renderFields,
  };
};

export default useAssignNewAuthorityFormik;
