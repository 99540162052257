import PropTypes from "prop-types";

// mui
import { Avatar, Stack, Typography } from "@mui/material";

// other
import getFullName from "src/helpers/getFullName";
import extractCapitals from "src/utils/extractCapitals";
import { blue } from "@mui/material/colors";

const Header = ({ firstName = "", lastName = "" }) => {
  const name = getFullName({ firstName, lastName });

  return (
    <Stack
      alignItems="flex-start"
      direction={{
        xs: "column",
        md: "row",
      }}
      justifyContent="space-between"
      spacing={4}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Avatar
          alt={name}
          sx={{
            height: 64,
            width: 64,
            bgcolor: blue[300],
          }}
        >
          {extractCapitals(name)}
        </Avatar>
        <Stack spacing={1}>
          <Typography variant="h4">{name}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

Header.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default Header;
