import { useSelector } from "react-redux";

// hoc
import withLoading from "src/hocs/withLoading";
import withNoData from "src/hocs/withNoData";

// hooks
import useType from "./hooks/useType";

// components
import DataTable from "src/components/tables/DataTable";
import DataTableSkeleton from "src/components/skeletons/table/DataTableSkeleton";
import PageContainer from "src/layouts/page";
import FloatingButton from "src/components/FloatingButton";
import FormModal from "src/components/modals/FormModal";
import DocumentTypeForm from "./components/DocumentTypeForm";
import { formDialogSelector } from "src/redux/selectors";
import Confirmation from "src/components/modals/Confirmation";
import DataTableWithoutData from "src/components/tables/DataTableWithoutData";
import {useState} from "react";

const TableWithNoData = withNoData(DataTable, DataTableWithoutData);
const TableWithLoading = withLoading(TableWithNoData, DataTableSkeleton);

const message =
  "Sorry Couldn't Find Any Document Types. Please Try by Submitting New Document Type.";

const DocumentTypes = () => {
  const { data: dialogData } = useSelector(formDialogSelector);
  const [selectedType, setType] = useState(null);

  const {
    columns,
    updateTypeHandler,
    onCloseHandler,
    handleDelete,
    newTypeHandler,
    title,
    data,
    isLoading,
    newFormik,
    newIsLoading,
    updateFormik,
    updateIsLoading,
  } = useType(selectedType, setType);

  return (
    <>
      <PageContainer title="Document Type">
        <TableWithLoading
          isEmpty={data.length === 0}
          message={message}
          isLoading={isLoading}
          isExport={false}
          columns={columns}
          rows={data}
          title={title}
          headerSx={{ color: "#696868" }}
          onRowClick={(rowData) => updateTypeHandler(rowData.row)}
        />
      </PageContainer>

      {dialogData?.type === "NEW_TYPE" && (
        <FormModal title="New Document Type">
          <DocumentTypeForm
            onClose={onCloseHandler}
            type={null}
            formik={newFormik}
            isLoading={newIsLoading}
          />
        </FormModal>
      )}

      {dialogData?.type === "UPDATE_TYPE" && (
          <FormModal title="Update Document Type">
            <DocumentTypeForm
                onClose={onCloseHandler}
                type={selectedType}
                formik={updateFormik}
                isLoading={updateIsLoading}
            />
          </FormModal>
      )}

      {dialogData?.type === "DELETE_TYPE" && (
        <FormModal title="Remove Document Type">
          <Confirmation onConfirm={handleDelete} onClose={onCloseHandler} />
        </FormModal>
      )}

      <FloatingButton title="New Document Type" onClick={newTypeHandler} />
    </>
  );
};

export default DocumentTypes;
