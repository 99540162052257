import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

// untitled-ui
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import XIcon from "@untitled-ui/icons-react/build/esm/X";

// mui
import {
  Badge,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Button,
} from "@mui/material";

//hoks
import useRouter from "src/hooks/useRouter";

// components
import Tip from "src/components/Tip";

const SearchDialog = ({ onClose, open }) => {
  const { push } = useRouter();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!open) {
      setSearchQuery("");
    }
  }, [open]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      push(`/dashboard/search?query=${searchQuery}`);
      onClose();
    }
  };
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">Search</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent>
        <Tip message="Search by entering a keyword and pressing Enter" />
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            fullWidth
            variant={"outlined"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon>
                    <SearchMdIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            // label="Search"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type here..."
            value={searchQuery}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SearchDialog.defaultProps = {
  open: false,
};

export default SearchDialog;
