import { combineReducers } from "@reduxjs/toolkit";

// reducers
import ui from "src/redux/slice";
import workFlowRequests from "src/features/work-flows/requests/redux/slice";
import documentsSlice from "src/features/organization/documents/redux/slice";
import { stakeholdersApi } from "src/features/admin/stakeholders/redux/api";
import { boardOfDirectorsApi } from "src/features/people/board-of-directors/redux/api";
import { companySecretariesApi } from "src/features/people/company-secretaries/redux/api";
import { corporateManagementApi } from "src/features/people/corporate-management/redux/api";
import { statutoryDocumentsApi } from "src/features/secretarial/statutory-documents/redux/api";
import { companyAddressChangeApi } from "src/features/work-flows/requests/registrar-of-companies/change-registered-address/redux/api";
import { companyDivestmentApi } from "src/features/work-flows/requests/miscellaneous/divestment-of-a-company/redux/api";
import { companyAuditorsChangeApi } from "src/features/work-flows/requests/registrar-of-companies/company-auditors-change/redux/api";
import { removeDirectorsRequestApi } from "src/features/work-flows/requests/registrar-of-companies/remove-director/redux/api";
import { dividendDeclarationApi } from "src/features/work-flows/requests/miscellaneous/dividend-declaration/redux/api";
import { workflowApi } from "src/redux/work-flows/api";
import { companyAcquisitionApi } from "src/features/work-flows/requests/miscellaneous/company-acquisition/redux/api";
import { workFlowTasksApi } from "src/features/work-flows/tasks/redux/api";
import { companyAuditorsApi } from "src/features/people/auditors/redux/api";
import { documentsApi } from "src/redux/documents/api";
import { commonApi } from "src/redux/api";
import { sectorsApi } from "../redux/sector/api";
import { geographiesApi } from "../redux/geography/api";
import { currenciesApi } from "../redux/currency/api";
import { accessControlsApi } from "src/redux/accessControls/api";
import { authenticationApi } from "src/redux/authentication/api";
import { companyApi } from "src/redux/company/api";
import { boardResolutionsApi } from "src/redux/boardResolution/api";
import { employeeApi } from "src/features/admin/employees/redux/api";
import { corporateAuthoritiesApi } from "src/redux/corporateAuthorities/api";
import { dashboardApi } from "src/redux/dashboard/api";
import { chatApi } from "src/redux/chat/chatApi";
import { bankingApi } from "src/redux/banking/api";
import { leasingApi } from "../redux/leasing/api";
import { lessorsApi } from "src/redux/lessors/api";
import { corporateRoleApi } from "src/redux/corporateRoles/api";

export const rootReducer = combineReducers({
  ui,
  workFlowRequests,
  documents: documentsSlice,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [stakeholdersApi.reducerPath]: stakeholdersApi.reducer,
  [boardOfDirectorsApi.reducerPath]: boardOfDirectorsApi.reducer,
  [companySecretariesApi.reducerPath]: companySecretariesApi.reducer,
  [corporateManagementApi.reducerPath]: corporateManagementApi.reducer,
  [statutoryDocumentsApi.reducerPath]: statutoryDocumentsApi.reducer,
  [workflowApi.reducerPath]: workflowApi.reducer,
  [companyAddressChangeApi.reducerPath]: companyAddressChangeApi.reducer,
  [companyDivestmentApi.reducerPath]: companyDivestmentApi.reducer,
  [companyAuditorsChangeApi.reducerPath]: companyAuditorsChangeApi.reducer,
  [companyAcquisitionApi.reducerPath]: companyAcquisitionApi.reducer,
  [removeDirectorsRequestApi.reducerPath]: removeDirectorsRequestApi.reducer,
  [dividendDeclarationApi.reducerPath]: dividendDeclarationApi.reducer,
  [workFlowTasksApi.reducerPath]: workFlowTasksApi.reducer,
  [companyAuditorsApi.reducerPath]: companyAuditorsApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [sectorsApi.reducerPath]: sectorsApi.reducer,
  [geographiesApi.reducerPath]: geographiesApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [accessControlsApi.reducerPath]: accessControlsApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [boardResolutionsApi.reducerPath]: boardResolutionsApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [corporateAuthoritiesApi.reducerPath]: corporateAuthoritiesApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [bankingApi.reducerPath]: bankingApi.reducer,
  [leasingApi.reducerPath]: leasingApi.reducer,
  [lessorsApi.reducerPath]: lessorsApi.reducer,
  [corporateRoleApi.reducerPath]: corporateRoleApi.reducer,
});
