// hooks

import EmployeeDetailFeature from "src/features/admin/employees/detail";
import usePageTitle from "src/hooks/usePageTitle";

// features

// other
import { pageTitles } from "src/pageTitles";

const ViewEmployee = () => {
  usePageTitle({ title: pageTitles.admin.employees.EmployeeDetail });

  return <EmployeeDetailFeature />;
};

export default ViewEmployee;
