import PropTypes from "prop-types";

// mui
import { Divider, Tab, Tabs as MuiTabs } from "@mui/material";

const Tabs = ({ tabs, current, sx, onTabChange }) => {
  if (!current) return null;

  return (
    <>
      <div>
        <MuiTabs
          indicatorColor="primary"
          onChange={onTabChange}
          scrollButtons="auto"
          sx={{ px: 3, ...sx }}
          textColor="primary"
          value={current}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={tab.disabled}
            />
          ))}
        </MuiTabs>
      </div>
      <Divider />
    </>
  );
};

Tabs.propTypes = {
  sx: PropTypes.object,
  tabs: PropTypes.array,
  current: PropTypes.string,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  tabs: [],
  sx: {},
  current: "",
};

export default Tabs;
