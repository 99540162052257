import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { COMPANY_ADDRESS_CHANGE } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  COMPANY_ADDRESS_CHANGE_DETAIL: "COMPANY_ADDRESS_CHANGE_DETAIL",
};

export const companyAddressChangeApi = createApi({
  reducerPath: "companyAddressChangeApi",
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query({
      query: (id) => `${COMPANY_ADDRESS_CHANGE}/${id}`,
      providesTags: [TAGS.COMPANY_ADDRESS_CHANGE_DETAIL],
    }),
    createRequest: builder.mutation({
      query: (payload) => ({
        url: COMPANY_ADDRESS_CHANGE,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateRequest: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_ADDRESS_CHANGE}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.COMPANY_ADDRESS_CHANGE_DETAIL],
    }),
  }),
});

export const {
  useGetByIdQuery,
  useLazyGetByIdQuery,
  useCreateRequestMutation,
  useUpdateRequestMutation,
} = companyAddressChangeApi;
