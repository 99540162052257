import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES, SHAREHOLDERS, SHARES, REMARKS } from "src/helpers/url";
import { HTTP_METHODS } from "src/constants";

import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "src/redux/apiService";
const TAGS = {
  SHAREHOLDER_LEDGER: "SHAREHOLDER_LEDGER",
  SHAREHOLDER_REMARKS: "SHAREHOLDER_REMARKS",
  SHARE: "SHARE",
};

export const statutoryDocumentsApi = createApi({
  reducerPath: "statutoryDocumentsApi",
  baseQuery,
  tagTypes: [TAGS.SHAREHOLDER_LEDGER, TAGS.SHARE, TAGS.SHAREHOLDER_REMARKS],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => COMPANIES,
    }),
    getCompanyShareholders: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/${SHAREHOLDERS}`,
    }),
    getCompanyShares: builder.query({
      query: (id) => `${COMPANIES}/${id || getCurrentTenant()}/${SHARES}`,
      providesTags: [TAGS.SHAREHOLDER_LEDGER],
    }),
    getShareById: builder.query({
      query: (id) => `${COMPANIES}/${getCurrentTenant()}/${SHARES}/${id}`,
      providesTags: [TAGS.SHARE],
    }),
    createShareholder: builder.mutation({
      query: (payload) => {
        const { isIndividual } = payload;
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${SHAREHOLDERS}`,
          method: HTTP_METHODS.POST,
          body: {
            ...payload,
            ...((isIndividual && { shareholderType: 1 }) || {
              shareholderType: 2,
            }),
          },
        };
      },
    }),
    issueShares: builder.mutation({
      query: (payload) => {
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${SHARES}`,
          method: HTTP_METHODS.POST,
          body: payload,
        };
      },
    }),
    updateRemarks: builder.mutation({
      query: (payload) => {
        const { shareId } = payload;
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${SHARES}/${shareId}/${REMARKS}`,
          method: HTTP_METHODS.PUT,
          body: payload,
        };
      },
      invalidatesTags: [TAGS.SHAREHOLDER_REMARKS],
    }),
    updateIssueShare: builder.mutation({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `${COMPANIES}/${getCurrentTenant()}/${SHARES}/${id}`,
          method: HTTP_METHODS.PUT,
          body: payload,
        };
      },
      invalidatesTags: [TAGS.SHARE],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyShareholdersQuery,
  useGetCompanySharesQuery,
  useGetShareByIdQuery,
  useCreateShareholderMutation,
  useIssueSharesMutation,
  useUpdateRemarksMutation,
  useUpdateIssueShareMutation,
} = statutoryDocumentsApi;
