import { Box, Skeleton } from "@mui/material";

const TabsSkeleton = () => (
  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
    {[80, 100, 110, 75].map((tab) => (
      <Skeleton
        key={tab}
        variant="text"
        width={tab}
        sx={{ fontSize: "1rem" }}
      />
    ))}
  </Box>
);

export default TabsSkeleton;
