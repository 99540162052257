// mui
import {
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  RadioGroup,
  Radio,
  MenuItem,
  Box,
  Chip,
  FormControl,
  FormLabel,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dateChangeHandler from "./dateChange";
import checkboxChange from "./checkboxChange";
import { FormHelperText } from "@mui/material";

// constants
import { INPUT_TYPES } from "src/constants";

/**
 * return input field based on type
 * @param {Object} input
 * @param {Object} formik
 * @param {Array} options
 * @returns component
 */
const generateInputField = (input, formik, options = [], maxDate) => {
  if (input.type === INPUT_TYPES.DATE) {
    return (
      <DatePicker
        key={input.key}
        label={input.label}
        name={input.name}
        value={formik.values[input.name]}
        maxDate={maxDate}
        disabled={input.disabled}
        inputFormat="dd/MM/yyyy"
        onChange={(newValue) => dateChangeHandler(formik, newValue, input.name)}
        renderInput={(params) => (
          <TextField
            required={input.required}
            fullWidth
            {...params}
            error={formik.errors[input.name] && formik.touched[input.name]}
            helperText={
              formik.errors[input.name] &&
              formik.touched[input.name] &&
              formik.errors[input.name]
            }
          />
        )}
        {...input}
      />
    );
  }

  if (input.type === INPUT_TYPES.SELECT) {
    const selectValue = input.multiple
      ? Array.isArray(formik.values[input.name])
        ? formik.values[input.name]
        : []
      : formik.values[input.name];
    return (
      <TextField
        key={input.key}
        fullWidth
        required={input.required}
        label={input.label}
        name={input.name}
        disabled={input.disabled}
        onChange={formik.handleChange}
        select
        SelectProps={{
          multiple: input.multiple,
          ...(input.multiple && {
            renderValue: (selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {Array.isArray(selected) &&
                  selected.map((value) => (
                    <Chip
                      key={value}
                      label={options.find((o) => o.value === value)?.label}
                    />
                  ))}
              </Box>
            ),
          }),
        }}
        value={selectValue}
        error={formik.errors[input.name] && formik.touched[input.name]}
        helperText={
          formik.errors[input.name] &&
          formik.touched[input.name] &&
          formik.errors[input.name]
        }
      >
        <MenuItem value={0} disabled>
          Select
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (input.type === INPUT_TYPES.CHECKBOX) {
    return (
      <FormControlLabel
        key={input.key}
        label={<Typography variant="subtitle2">{input.label}</Typography>}
        labelPlacement="start"
        control={
          <Switch
            checked={formik.values[input.name]}
            onChange={checkboxChange.bind(this, formik)}
            name={input.name}
          />
        }
      />
    );
  }

  if (input.type === INPUT_TYPES.RADIO) {
    return (
      <FormControl
        key={input.key}
        disabled={input.disabled}
        error={formik.errors[input.name] && formik.touched[input.name]}
      >
        <FormLabel required={input.required}>{input.label}</FormLabel>
        <RadioGroup
          value={formik.values[input.name]}
          onChange={formik.handleChange}
          name={input.name}
          sx={{ flexDirection: "row" }}
        >
          {input.options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={<Radio />}
              label={<Typography variant="body1">{option.label}</Typography>}
              value={option.value}
            ></FormControlLabel>
          ))}
        </RadioGroup>
        {formik.errors[input.name] && formik.touched[input.name] && (
          <FormHelperText>{formik.errors[input.name]}</FormHelperText>
        )}
      </FormControl>
    );
  }

  if (input.type === INPUT_TYPES.AUTO_COMPLETE) {
    return (
      <Autocomplete
        name={input.name}
        value={formik.values[input.name]}
        fullWidth
        size={input?.size || "medium"}
        options={options}
        getOptionLabel={(option) => option.label}
        onChange={(_, newValue) => formik.setFieldValue(input.name, newValue)}
        renderOption={(props, option) => (
          <MenuItem {...props} id={props.key}>
            {option.label}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={input.label}
            size={input?.size || "medium"}
            fullWidth
            error={formik.errors[input.name] && formik.touched[input.name]}
            helperText={
              formik.errors[input.name] &&
              formik.touched[input.name] &&
              formik.errors[input.name]
            }
          />
        )}
      />
    );
  }

  return (
    <TextField
      key={input.key}
      label={input.label}
      name={input.name}
      required={input.required}
      multiline={input.multiline}
      rows={input.rows}
      disabled={input.disabled}
      fullWidth
      value={formik.values[input.name]}
      onChange={formik.handleChange}
      error={formik.errors[input.name] && formik.touched[input.name]}
      helperText={
        formik.errors[input.name] &&
        formik.touched[input.name] &&
        formik.errors[input.name]
      }
    />
  );
};

export default generateInputField;
