// mui
import { Skeleton } from "@mui/material";

const DocumentListSkeleton = () => {
  return (
    <>
      {[1, 2, 3].map((document) => (
        <Skeleton
          key={document}
          variant="rectangular"
          width="100%"
          height={80}
          animation="wave"
          sx={{ borderRadius: 2, marginBottom: 2 }}
        />
      ))}
    </>
  );
};

export default DocumentListSkeleton;
