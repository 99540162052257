import React, { useMemo } from "react";
import PropTypes from "prop-types";

// mui
import { Button, Grid } from "@mui/material";

// components
import SimpleTable from "src/components/tables/SimpleTable";
import CorporateRoleRows from "./CorporateRoleRows";
import { Box } from "@mui/system";
import generateInputField from "src/helpers/generateInputField";
import generateUniqueKey from "src/utils/generateUniqueKeys";
import { INPUT_TYPES } from "src/constants";
import useUpdateEmployeeCorporateDetails from "src/features/admin/employees/hooks/useUpdaetEmployeeCorporateDetails";
import generateOptions from "src/utils/generateOptions";
import convertToModel from "src/helpers/convertToModel";
import { useGetApplicationUsersQuery } from "src/features/admin/employees/redux/api";
import ApplicationUser from "src/models/organization/ApplicationUser";
import { corporateProfileDetails } from "src/features/admin/employees/common/formDetails";

const columns = [
  {
    title: "Role",
  },
  {
    title: "Company",
  },
];

const FORM_DETAILS = [
  {
    key: generateUniqueKey("corporateName"),
    name: "corporateName",
    label: "Corporate Profile Name",
    type: INPUT_TYPES.TEXT,
    xs: 12,
    lg: 12,
  },
  {
    key: generateUniqueKey("userId"),
    name: "userId",
    label: "User",
    type: INPUT_TYPES.SELECT,
    xs: 12,
    lg: 12,
  },
];
const CorporateRoles = ({ roles }) => {
  const { formik } = useUpdateEmployeeCorporateDetails(roles[0]?.employeeId);
  const { data: applicationUsers = [] } = useGetApplicationUsersQuery();
  const users = useMemo(
    () => convertToModel(applicationUsers, ApplicationUser),
    [applicationUsers]
  );
  const memoizedUsers = useMemo(
    () => generateOptions(users, "id", "fullName"),
    [users]
  );

  const memoizedCorporateRoles = useMemo(
    () => generateOptions(users, "id", "fullName"),
    [users]
  );

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <SimpleTable title="Corporate Roles" columns={columns}>
            <CorporateRoleRows roles={roles} />
          </SimpleTable>
        </Grid>

        <Grid item md={12} xs={12}>
          <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <Box sx={{ p: 3, backgroundColor: "white", borderRadius: "8px" }}>
              {corporateProfileDetails.map((item) => (
                <Grid
                  item
                  key={item.key}
                  xs={item.xs}
                  lg={item.lg}
                  sx={{ marginBottom: 2 }}
                >
                  {generateInputField(item, formik, memoizedUsers)}
                </Grid>
              ))}

              <Grid container justifyContent="flex-end">
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

CorporateRoles.propTypes = {
  roles: PropTypes.array,
};

CorporateRoles.defaultProps = {
  roles: [],
};

export default CorporateRoles;
