import { useEffect } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";

//actions
import { closeFormDialog } from "src/redux/slice";

// hooks
import { useDispatch } from "src/store";

// other
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import validationSchema from "../validations";
import { useUpdateDepartmentsMutation } from "src/redux/company/api";

const useUpdateDepartment = (selectedDepartment) => {
  const dispatch = useDispatch();
  const [update, { isLoading, isError, isSuccess, error, data }] =
    useUpdateDepartmentsMutation();

  const formik = useFormik({
    initialValues: {
      id: selectedDepartment.row.id,
      name: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Department Updated Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch(closeFormDialog());
    }
  }, [data, dispatch, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Updating: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
      dispatch(closeFormDialog());
    }
  }, [dispatch, isError, error]);

  return {
    formik,
    isLoading,
  };
};

export default useUpdateDepartment;
