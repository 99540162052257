import React from "react";

// mui
import {
  Card,
  CardHeader,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const AuthorityDetailsSkeleton = ({ title = "Authority Details" }) => (
  <Card>
    <CardHeader title={title} />
    <Divider />
    <TableContainer sx={{ pl: 1, pr: 1 }}>
      <Table>
        <TableBody>
          {[
            { key: 1, cells: [1.1, 1.2, 1.3] },
            { key: 2, cells: [2.1, 2.2, 2.3] },
          ].map(({ key, cells }) => (
            <TableRow key={key}>
              {cells.map(({ label }) => (
                <TableCell key={label}>
                  <Skeleton variant="text" height={30} width={100} />
                  <Skeleton variant="text" height={30} width={120} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Card>
);

export default AuthorityDetailsSkeleton;
