// mui
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";

// helpers
import generateInputField from "src/helpers/generateInputField";

// utils
import generateUniqueKey from "src/utils/generateUniqueKeys";

// other
import { INPUT_TYPES } from "src/constants";
import useUpdateDepartment from "../hooks/useUpdateDepartment";

const FORM_DETAILS = [
  {
    key: generateUniqueKey("userName"),
    name: "name",
    label: "New Department Name",
    required: true,
    type: INPUT_TYPES.TEXT,
  },
];

const UpdateDepartmentForm = ({ selectedDepartment }) => {
  const { isLoading, formik } = useUpdateDepartment(selectedDepartment);
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={1} mt={1} mb={2} pr={2} pl={2}>
        {FORM_DETAILS.map((item) => (
          <Grid item key={item.key} xs={12}>
            <Typography variant="body1" color="textSecondary">
              Selected Department Is :
              <span style={{ color: "red" }}>
                {selectedDepartment?.row?.name}
              </span>
            </Typography>
            {generateInputField(item, formik)}
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />
        <div>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {!isLoading && "Update"}
            {isLoading && "Please wait..."}
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default UpdateDepartmentForm;
