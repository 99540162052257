import PropTypes from "prop-types";

// mui
import { Box, Container } from "@mui/material";

const Layout = ({ children }) => (
  <Box
    sx={{
      width: "100%",
      height: "100vh",
      display: "flex",
    }}
  >
    <Container
      maxWidth="lg"
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Container>
  </Box>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
