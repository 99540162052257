import PropTypes from "prop-types";

// mui
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

// components
import Scrollbar from "../Scrollbar";

const SimpleTable = ({ title, columns, action, footer, children }) => (
  <Card>
    <CardHeader action={action} title={title} />

    <Scrollbar>
      <Table sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.title} {...column.props}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Scrollbar>
    {footer}
  </Card>
);

SimpleTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  action: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

SimpleTable.defaultProps = {
  columns: [],
};

export default SimpleTable;
