import Base from "src/models/Base";
import CorporateAuthority from "./CorporateAuthority";
import CorporateAuthorityLevel from "./CorporateAuthorityLevel";
import Account from "../bank/Account";

class CorporateAuthorization extends Base {
  constructor({
    id,
    employeeId,
    corporateAuthority = {},
    corporateAuthorityLevel = {},
    account = {},
    companyId,
  }) {
    super({ id });
    Object.assign(this, {
      employeeId,
      companyId,
      account: new Account({ ...account }),
      corporateAuthority: new CorporateAuthority({ ...corporateAuthority }),
      corporateAuthorityLevel: new CorporateAuthorityLevel({
        ...corporateAuthorityLevel,
      }),
    });
  }

  get title() {
    return this.corporateAuthority.title;
  }

  get description() {
    return this.corporateAuthority.description;
  }

  get authorityLevel() {
    return this.corporateAuthorityLevel.name;
  }

  get bankName() {
    return this.account.bank.name;
  }

  get branchName() {
    return this.account.branch.name;
  }

  get accountNumber() {
    return this.account.accountNumber;
  }

  get financeLimit() {
    return this.corporateAuthorityLevel.financeLimit;
  }

  get levelDescription() {
    return this.corporateAuthorityLevel.description;
  }
}

export default CorporateAuthorization;
