import { useState } from "react";
import { openFormDialog } from "src/redux/slice";
// hooks
// mui
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import TrashIcon from "@untitled-ui/icons-react/build/esm/Trash04";

// untitled-ui
import { useDispatch } from "src/store";

const COLUMNS = [
  {
    field: "id",
    headerName: "Department Id ",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Department Name",
    flex: 1,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
  },
];

const useDepartments = () => {
  const [selectedDepartment, setDepartment] = useState(null);
  const dispatch = useDispatch();

  const DeleteDepartmentHandler = (row, event) => {
    event.stopPropagation();
    setDepartment(row);
    dispatch(
      openFormDialog({
        type: "DELETE_DEPARTMENT",
        message: `Are you sure you want to remove ${row?.name} department ?`,
      })
    );
  };
  const UpdateDepartmentHandler = (row) => {
    setDepartment(row);
    dispatch(
      openFormDialog({
        type: "UPDATE_DEPARTMENT",
        message: `Are you sure you want to update ${row?.name} department ? `,
      })
    );
  };
  const onActionsCloseHandler = () => {
    setDepartment(null);
  };

  const columns = [
    ...COLUMNS,
    {
      field: "action",
      headerName: "action",
      width: 120,
      align: "left",
      renderCell: (params) => (
        <>
          <Tooltip title="Remove">
            <IconButton
              onClick={(event) => DeleteDepartmentHandler(params.row, event)}
              style={{
                marginRight: "15px",
                color: "red",
              }}
            >
              <SvgIcon fontSize="small">
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return {
    columns,
    selectedDepartment,
    onClose: onActionsCloseHandler,
    UpdateDepartmentHandler,
  };
};

export default useDepartments;
