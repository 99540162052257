const getCurrentUserId = () => {
  const userIdString = window.sessionStorage.getItem("user");

  if (userIdString) {
    const { id: userId } = JSON.parse(userIdString);
    return userId;
  }
  return 0;
};

export default getCurrentUserId;
