import { useEffect } from "react";
import { useGetDocumentTypesQuery } from "src/redux/documents/api";
import toast from "react-hot-toast";
import { ERROR_TOAST_DURATION } from "src/constants";

const useGetDocumentType = () => {
  const { data = [], isLoading, isError, error } = useGetDocumentTypesQuery();

  useEffect(() => {
    if (isError) {
      toast.error(`Error when Loading Document Types: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  const title = `Total (${data?.length ?? 0})`;
  return {
    data,
    isLoading,
    title,
  };
};

export default useGetDocumentType;
