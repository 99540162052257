import { format } from "date-fns";
import Base from "../Base";

// model
import Document from "./Document";

// untitled-ui
import LockUnlocked04 from "@untitled-ui/icons-react/build/esm/LockUnlocked04";
import Lock04 from "@untitled-ui/icons-react/build/esm/Lock04";

// constants
import { DOCUMENT_DOCKET_OPTIONS } from "src/components/docket/constants";

class Docket extends Base {
  constructor({
    id,
    name,
    guid,
    containerName,
    companyProfileId,
    docketTypeId,
    docketType,
    isMandatory,
    isPrimary,
    isLocked,
    documents = [],
    subDockets = [],
    tenantId,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    status,
    isDocket = true,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      name,
      guid,
      containerName,
      companyProfileId,
      docketTypeId,
      docketType,
      isMandatory,
      isPrimary,
      isLocked,
      tenantId,
      status,
      isDocket,
      _maxCharacters: 25,
    });

    this.documents = documents.map((document) => new Document({ ...document }));
    this.subDockets = subDockets.map((docket) => new Docket({ ...docket }));
  }
  get truncatedName() {
    return this.name.length > this._maxCharacters
      ? this.name.slice(0, this._maxCharacters) + "..."
      : this.name;
  }
  set maxCharacters(char) {
    this._maxCharacters = char;
  }

  get noOfDocuments() {
    return `${this.documents.length} items`;
  }

  get lockUnlockIcon() {
    if (!this.isLocked) return <Lock04 />;
    return <LockUnlocked04 />;
  }

  get lockUnlockLabel() {
    if (this.isLocked) return DOCUMENT_DOCKET_OPTIONS.UNLOCK;
    return DOCUMENT_DOCKET_OPTIONS.LOCK;
  }

  getLastModified() {
    return format(new Date(this.updatedAt), "dd/MM/yyyy");
  }
}

export default Docket;
