import { useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { createMongoAbility } from "@casl/ability";

// hooks
import { useMsal } from "@azure/msal-react";
import { useSignInMutation } from "src/redux/authentication/api";
import useSessionStorage from "src/hooks/useSessionStorage";

// context
import AuthContext from "./AuthContext";

// other
import { isValidToken, handleTokenExpired } from "src/utils/auth";
import { loginRequest, graphConfig } from "src/authConfig";
import { getProfilePic } from "src/helpers/authentication";
import { ERROR_TOAST_DURATION } from "src/constants";

// constants
const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const REFRESH = "REFRESH";
const SET_GRAPH_TOKEN = "SET_GRAPH_TOKEN";
const SET_PROFILE_PIC = "SET_PROFILE_PIC";
const SET_ABILITY = "SET_ABILITY";

const initial = {
  user: null,
  token: null,
  isAuthenticated: false,
  isInitialized: false,
  userProfilePicURL: null,
  ability: createMongoAbility([
    {
      action: "update",
      subject: "Approval",
      conditions: { "approver.userId": 1 },
    },
  ]), // TODO: API bind
};

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
        user: action.payload.user,
      };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };

    case REFRESH:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      };

    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: initial.isAuthenticated,
        token: initial.token,
        user: initial.user,
        userProfilePicURL: null,
      };

    case SET_PROFILE_PIC:
      return {
        ...state,
        userProfilePicURL: action.payload,
      };

    case SET_GRAPH_TOKEN:
      return {
        ...state,
        graphToken: action.payload.graphToken,
      };

    case SET_ABILITY:
      return {
        ...state,
        ability: createMongoAbility(action.payload),
      };

    default:
      return { ...state };
  }
};

const AuthProvider = ({ children }) => {
  const { instance, accounts } = useMsal();

  const [token, setToken, removeToken] = useSessionStorage("ad_token");
  const [graphToken, setGraphToken, removeGraphToken] =
    useSessionStorage("graph_token");
  const [isAdmin] = useSessionStorage("is_admin");
  const [isLoading, setIsLoading] = useSessionStorage("processing", false);
  const [user, setUser, removeUser] = useSessionStorage("user");
  const [, , removeTenant] = useSessionStorage("tenant");

  const [state, dispatch] = useReducer(reducer, initial);

  const [signIn, { isError, error, isSuccess, data, reset }] =
    useSignInMutation();

  // get new access token
  const acquireToken = useCallback(async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      dispatch({
        type: REFRESH,
        payload: { token: response.accessToken },
      });

      setToken(response.accessToken);
    } catch (error) {
      console.error(error);
    }
  }, [accounts, instance, setToken]);

  // initialize token and user details when mounting
  const initialize = useCallback(async () => {
    try {
      const isValid = token && isValidToken(token);

      // if token valid
      if (isValid && user) {
        const userProfilePicURL = await getProfilePic();
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            token,
            user,
            userProfilePicURL,
          },
        });
        dispatch({
          type: SET_ABILITY,
          payload: [
            {
              action: "update",
              subject: "Approval",
              conditions: { userId: user.id },
            },
          ],
        });
      }
      // if token not valid
      if (token && !isValid) acquireToken();

      // if no token
      if (!token)
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            token: null,
            user: null,
            userProfilePicURL: null,
          },
        });
    } catch (error) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          token: null,
          user: null,
          userProfilePicURL: null,
        },
      });
    }
  }, [acquireToken, token, user]);

  // initial requests
  useEffect(() => {
    initialize();
  }, [initialize]);

  // handle token expiration
  useEffect(() => {
    var timerId;

    if (token) {
      const timeLeft = handleTokenExpired(token);
      timerId = setTimeout(() => {
        removeToken();
        acquireToken();
      }, timeLeft);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [acquireToken, removeToken, token]);

  // handle application sign-in success
  useEffect(() => {
    if (isSuccess) {
      setUser(data);
      dispatch({
        type: SIGN_IN,
        payload: { token, user: data },
      });
      dispatch({
        type: SET_ABILITY,
        payload: [
          {
            action: "update",
            subject: "Approval",
            conditions: { "approver.userId": data.id },
          },
        ],
      });
      setIsLoading(false);
      reset();
    }
  }, [data, isSuccess, reset, setIsLoading, setUser, token]);

  // handle application sign-in error
  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      toast.error(
        `Error on Login: ${error?.data?.detail ?? "Something went wrong"}.`,
        {
          duration: ERROR_TOAST_DURATION,
        }
      );
      removeToken();
    }
  }, [isError, removeToken, setIsLoading, error]);

  // acquire graph token
  const acquireGraphAPIToken = useCallback(async () => {
    return instance
      .acquireTokenSilent({
        scopes: graphConfig.scopes,
        account: accounts[0],
      })
      .then(async (response) => {
        setGraphToken(response.accessToken);

        const proPic = await getProfilePic();

        dispatch({ type: SET_PROFILE_PIC, payload: proPic });

        // handle expires again
        handleTokenExpired(response.accessToken, acquireGraphAPIToken);
      })
      .catch((e) => console.log("graph", e));
  }, [accounts, instance, setGraphToken]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      acquireGraphAPIToken();
    }
  }, [accounts, acquireGraphAPIToken]);

  // initialize graph token
  const initializeGraphToken = useCallback(() => {
    try {
      const isValidGraphAPIToken = graphToken && isValidToken(graphToken);

      // if graph token valid
      if (isValidGraphAPIToken) {
        dispatch({
          type: SET_GRAPH_TOKEN,
          payload: graphToken,
        });
      }

      // if token not valid
      if (graphToken && !isValidToken) acquireGraphAPIToken();

      // if no token
      if (!graphToken)
        dispatch({
          type: SET_PROFILE_PIC,
          payload: null,
        });
    } catch (error) {
      dispatch({
        type: SET_PROFILE_PIC,
        payload: null,
      });
    }
  }, [acquireGraphAPIToken, graphToken]);

  // initialGraphToken requests
  useEffect(() => {
    initializeGraphToken();
  }, [initializeGraphToken]);

  // handle graph_token expiration
  useEffect(() => {
    var timerId;

    if (graphToken) {
      const timeLeft = handleTokenExpired(graphToken);
      timerId = setTimeout(() => {
        removeGraphToken();
        acquireGraphAPIToken();
      }, timeLeft);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [acquireGraphAPIToken, removeGraphToken, graphToken]);

  // handle user sign-in
  const signInHandler = useCallback(
    (response) => {
      signIn(response.accessToken);
      setToken(response.accessToken);
    },
    [setToken, signIn]
  );

  // handle user sign out
  const signOutHandler = () => {
    removeToken();
    removeUser();
    removeTenant();
    removeGraphToken();
    instance
      .logoutRedirect({ postLogoutRedirectUri: "/" })
      .then(() => dispatch({ type: SIGN_OUT }))
      .catch((error) => console.error(error));
  };

  const loadingHandler = useCallback(
    (value) => setIsLoading(value),
    [setIsLoading]
  );

  const valueObject = {
    ...state,
    isAdmin,
    isLoading,
    graphToken,
    onSignIn: signInHandler,
    onSignOut: signOutHandler,
    onLoadingChange: loadingHandler,
  };

  return (
    <AuthContext.Provider value={valueObject}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AuthProvider;
