// mui
import Grid from "@mui/material/Grid";

// hooks
import useAssignNewAuthorityFormik from "../hooks/useAssignNewAuthorityFormik";

// components
import FormFooter from "src/components/FormFooter";

// other
import { INPUT_TYPES } from "src/constants";
import generateUniqueKey from "src/utils/generateUniqueKeys";

const formDetails = [
  {
    key: generateUniqueKey("institution"),
    name: "institutionId",
    label: "Institution",
    type: INPUT_TYPES.SELECT,
    required: true,
    xs: 12,
  },
  {
    key: generateUniqueKey("accountId"),
    name: "accountId",
    label: "Account",
    type: INPUT_TYPES.SELECT,
    required: true,
    show: (institutionType) => institutionType === "Bank",
    xs: 12,
  },
  {
    key: generateUniqueKey("authority"),
    name: "corporateAuthorityId",
    label: "Corporate Authority",
    type: INPUT_TYPES.SELECT,
    required: true,
    xs: 12,
  },
  {
    key: generateUniqueKey("authority_level"),
    name: "corporateAuthorityLevelId",
    label: "Corporate Authority Levels",
    type: INPUT_TYPES.SELECT,
    xs: 12,
  },
];

const AssignNewAuthorityForm = () => {
  const { isLoading, handleSubmit, renderFields } =
    useAssignNewAuthorityFormik();

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2} mt={3} mb={2} pr={2} pl={2}>
        {formDetails.map((item) => renderFields(item))}
      </Grid>
      <FormFooter sx={{ mb: 1, pr: 2 }} isLoading={isLoading} />
    </form>
  );
};

export default AssignNewAuthorityForm;
