import Base from "../../Base";
import Currency from "../Currency";
import Bank from "./Bank";
import Branch from "./Branch";
import { ACCOUNT_TYPES } from "src/features/banking/accounts/common/constants";

class Account extends Base {
  constructor({
    id,
    accountType,
    accountNumber,
    accountStatus,
    amount,
    bank,
    branch,
    currency,
    signatories,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });

    Object.assign(this, {
      _accountType: accountType,
      accountNumber,
      accountStatus,
      _amount: amount,
      bank: new Bank({ ...bank }),
      branch: new Branch({ ...branch }),
      currency: new Currency({ ...currency }),
      signatories,
    });
  }

  get currencyCode() {
    return this.currency.code;
  }

  set accountType(accountType) {
    this._accountType = accountType;
  }

  get accountType() {
    const accountType = ACCOUNT_TYPES.find(
      (type) => type.value === this._accountType
    );

    if (accountType) return accountType.label;
    return "";
  }
}

export default Account;
