import { ImageListItem, Stack, Typography } from "@mui/material";

const withNoData = (Component, NoDataComponent) => (props) => {
  const { isEmpty, message, sx, ...other } = props;

  if (isEmpty) {
    if (NoDataComponent) return <NoDataComponent {...props} />;
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        mt={2}
        pl={2}
        pr={2}
        pb={2}
      >
        <ImageListItem sx={{ marginTop: '100px', width: 250, height: 350, ...sx }}>
          <img src="/assets/file_searching.png" alt="no-data" />
        </ImageListItem>
        <div>
          <Typography color="text.secondary">{`${
            message || "No Data Available."
          }`}</Typography>
        </div>
      </Stack>
    );
  }

  return <Component {...other} />;
};

export default withNoData;
