import PropTypes from "prop-types";

// mui
import { Tooltip, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// other
import DocumentModel from "src/models/documents/Document";
import DocketModel from "src/models/documents/Docket";
import { MAX_CHARACTER_FOR_LARGE_SCREEN } from "src/constants";
import { DOCUMENT_REPOSITORY_VIEW } from "../../constants";

const ItemTitle = ({ item, isVersion, view }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  if (item)
    item.maxCharacters = isLargeScreen && MAX_CHARACTER_FOR_LARGE_SCREEN; // set max characters for truncate

  const isDocument = item instanceof DocumentModel;
  // const isDocket = item instanceof DocketModel;

  return (
    <div>
      {view === DOCUMENT_REPOSITORY_VIEW.LIST && (
        <Tooltip title={item?.name}>
          <Typography noWrap variant="subtitle2">
            {item?.truncatedName}
          </Typography>
        </Tooltip>
      )}

      {view === DOCUMENT_REPOSITORY_VIEW.GRID && (
        <Typography
          sx={{
            cursor: "pointer",
            maxWidth: "180px",
            whiteSpace: "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1.2",
            height: "2.4em",
          }}
          variant="subtitle2"
        >
          {item?.name}
        </Typography>
      )}

      {isDocument && !isVersion && document?.noOfVersions > 0 && (
        <Typography color="text.secondary" noWrap variant="body2">
          {item?.noOfVersions} versions
        </Typography>
      )}

      {/* TODO: future implementation */}
      {/* {view === DOCUMENT_REPOSITORY_VIEW.LIST && isDocket && (
        <Typography color="text.secondary" noWrap variant="body2">
          {item?.noOfDocuments}
        </Typography>
      )} */}
    </div>
  );
};

ItemTitle.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(DocumentModel),
    PropTypes.instanceOf(DocketModel),
  ]),
  isVersion: PropTypes.bool,
  view: PropTypes.oneOf([
    DOCUMENT_REPOSITORY_VIEW.GRID,
    DOCUMENT_REPOSITORY_VIEW.LIST,
  ]),
};

ItemTitle.defaultProps = {
  view: DOCUMENT_REPOSITORY_VIEW.LIST,
};

export default ItemTitle;
