import { Stack, Skeleton } from "@mui/material";

const HeaderSkeleton = () => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    spacing={2}
    sx={{ p: 2 }}
  >
    <Skeleton variant="text" width={140} height={20} />
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
      spacing={1}
    >
      <Skeleton variant="circular" width={15} height={15} />
      <Skeleton variant="circular" width={15} height={15} />
    </Stack>
  </Stack>
);

export default HeaderSkeleton;
