import jwtDecode from "jwt-decode";

export var Issuer;
(function (Issuer) {
  Issuer["Auth0"] = "Auth0";
  Issuer["Firebase"] = "Firebase";
  Issuer["JWT"] = "JWT";
  Issuer["Amplify"] = "Amplify";
})(Issuer || (Issuer = {}));

const decodeToken = (token) => jwtDecode(token);

export const isValidToken = (token) => {
  const currentTime = Date.now() / 1000;

  return decodeToken(token)?.exp > currentTime;
};

export const handleTokenExpired = (token) => {
  // let expiredTimer;

  // window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  return decodeToken(token)?.exp * 1000 - currentTime;
  // expiredTimer = window.setTimeout(() => {
  //   console.log("expired");
  // }, timeLeft);
}

