// untitled-ui
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";

// mui
import { IconButton, SvgIcon, Tooltip } from "@mui/material";

// dialog
import useDialog from "src/hooks/useDialog";

// components
import SearchDialog from "./SearchDialog";

const SearchButton = () => {
  const dialog = useDialog();

  return (
    <>
      <Tooltip title="Search">
        <IconButton onClick={dialog.handleOpen}>
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <SearchDialog onClose={dialog.handleClose} open={dialog.open} />
    </>
  );
};

export default SearchButton;
