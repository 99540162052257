import PropTypes from "prop-types";

// mui
import {Box, Divider, Grid, FormControlLabel, Checkbox, LinearProgress} from "@mui/material";

// hooks
import useUploadLogic from "src/hooks/documents/useUploadLogic";
import useNewDocUploadLogic from "src/hooks/documents/useNewDocUploadLogic";

// components
import FileDropzone from "../fileDropzone";
import FormFooter from "../FormFooter";

// other
import generateUniqueKey from "src/utils/generateUniqueKeys";
import { INPUT_TYPES } from "src/constants";

export const formDetails = [
  {
    key: generateUniqueKey("name"),
    name: "name",
    label: "Document Name",
    required: true,
    type: INPUT_TYPES.TEXT,
  },
  {
    key: generateUniqueKey("documentTypeId"),
    name: "documentTypeId",
    label: "Document Type",
    required: true,
    options: [],
    type: INPUT_TYPES.AUTO_COMPLETE,
  },
  {
    key: generateUniqueKey("docketId"),
    name: "docketId",
    label: "Docket",
    required: true,
    options: [],
    type: INPUT_TYPES.SELECT,
    show: (isDocketOption) => isDocketOption,
  },
  {
    key: generateUniqueKey("renameDocumentName"),
    name: "renameDocumentName",
    label: "Rename file name on upload",
    type: INPUT_TYPES.CHECKBOX,
  },
];

const DocUploadForm = ({ docketId, isDocketOption }) => {
  const { files, isUploadDisabled, dropHandler, removeHandler } =
    useUploadLogic({});

  const {
    renameDocumentName,
    isLoading,
    formik,
    handleRenameDocumentNameChange,
    renderInputFields,
  } = useNewDocUploadLogic({ isDocketOption, docketId, files });

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} mt={0} mb={2} pr={2} pl={2}>
          {formDetails.map((item) => (
            <Grid item key={item.key} xs={12}>
              {item.name === "renameDocumentName" ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "20px",
                        mt: 0,
                        mb: 0,
                      }}
                      checked={renameDocumentName}
                      onChange={handleRenameDocumentNameChange}
                    />
                  }
                  label={item.label}
                />
              ) : (
                renderInputFields(item, formik)
              )}
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 0, mb: 2, mr: 2, ml: 2 }}>
          <FileDropzone
            multiple={false}
            files={files}
            onDrop={(droppedFiles) =>
              dropHandler(droppedFiles, renameDocumentName)
            }
            onRemove={removeHandler}
          />
        </Box>
        {isLoading ? <LinearProgress /> : <Divider />}
        <FormFooter
          sx={{ pt: 2, pb: 2, pr: 2 }}
          isLoading={isLoading}
          btnLabel={'Upload'}
          isDisabled={isUploadDisabled}
        />
      </form>
    </>
  );
};

DocUploadForm.propTypes = {
  docketId: PropTypes.any,
  isDocketOption: PropTypes.bool,
};

DocUploadForm.defaultProps = {
  isDocketOption: false,
};

export default DocUploadForm;
