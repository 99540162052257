import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

// hooks
import { documentsApi } from "src/redux/documents/api";

// other
import FileUtility from "src/utils/FileUtility";

const useDownloadLogic = ({ parent, docketId, isVersion }) => {
  const [downloadDocument, { data: file, isFetching, isError, error }] =
    documentsApi.endpoints.downloadDocument.useLazyQuery();

  // download feedback
  useEffect(() => {
    if (isFetching)
      toast.loading("Downloading...", {
        duration: DEFAULT_TOAST_DURATION,
      });
  }, [isFetching]);

  // handle document download
  useEffect(() => {
    if (file) {
      FileUtility.saveByteArray(
        file.name,
        file.fileType,
        FileUtility.base64ToArrayBuffer(file.fileBytes)
      );
    }
  }, [file]);

  // handle document download error
  useEffect(() => {
    if (isError)
      toast.error(`Error on Downloading: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [error, isError]);

  const downloadHandler = (document) =>
    downloadDocument({
      docketId: docketId,
      documentId: (!isVersion && document.id) || parent.id,
      ...(isVersion && {
        versionId: document.id,
      }),
    });

  return {
    downloadHandler,
    downloadDocument,
  };
};

export default useDownloadLogic;
