import PropTypes from "prop-types";

// icons
import XIcon from "@untitled-ui/icons-react/build/esm/X";

// mui
import {Dialog, Divider, IconButton, Stack, SvgIcon, Typography} from "@mui/material";

// hooks
import { useSelector, useDispatch } from "src/store/index";

// components
import Scrollbar from "../Scrollbar";

// redux
import { closeFormDialog } from "src/redux/slice";
import { formDialogSelector } from "src/redux/selectors";

const FormModal = ({ title, children, sx, maxWidth, onReset }) => {
  const dispatch = useDispatch();

  const { open } = useSelector(formDialogSelector);

  const closeHandler = (event) => {
    if (onReset) onReset();
    dispatch(closeFormDialog());
    event.stopPropagation();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={closeHandler}
      sx={{ zIndex: 1300 , ...sx }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton color="inherit" onClick={closeHandler}>
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <Divider />
      <Scrollbar sx={{ maxHeight: 400 }}>{children}</Scrollbar>
    </Dialog>
  );
};

FormModal.propTypes = {
  title: PropTypes.string,
  maxWidth: PropTypes.oneOf(["sm", "md", "lg"]),
  children: PropTypes.node,
  onReset: PropTypes.func,
};

FormModal.defaultProps = {
  maxWidth: "sm",
};

export default FormModal;
