import PropTypes from "prop-types";

// mui
import { Button, Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const FormFooter = ({
  isLoading,
  type,
  btnLabel,
  spacing,
  sx,
  isDisabled,
  endIcon,
  onClick,
  onBack,
}) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="flex-end"
    spacing={spacing}
    sx={{ ...sx }}
  >
    {onBack && (
      <Button onClick={onBack} startIcon={<ArrowBack />}>
        Back
      </Button>
    )}
    <Button
      type={type}
      variant="contained"
      disabled={isLoading || isDisabled}
      onClick={type !== "submit" ? onClick : undefined}
      endIcon={endIcon}
    >
      {!isLoading && btnLabel}
      {isLoading && "Please wait..."}
    </Button>
  </Stack>
);

FormFooter.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(["submit", "button"]),
  btnLabel: PropTypes.string,
  spacing: PropTypes.number,
  sx: PropTypes.object,
  isDisabled: PropTypes.bool,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  onBack: PropTypes.func,
};

FormFooter.defaultProps = {
  btnLabel: "Submit",
  type: "submit",
  spacing: 1,
  isDisabled: false,
};

export default FormFooter;
