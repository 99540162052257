import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "src/constants";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { DOCKET_TYPES } from "src/helpers/url";
import {
  WORK_FLOW_TASK,
  COMPANIES,
  DOCKETS,
  DOCUMENTS,
  DOCUMENT_TYPE,
} from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  DOCKET: "DOCKET",
  DOCUMENT: "DOCUMENT",
  LIBRARIES: "LIBRARIES",
  WORK_FLOW_TASK: "WORKFLOWTASKS",
};

export const workFlowTasksApi = createApi({
  reducerPath: "workFlowTasksApi",
  baseQuery,
  tagTypes: [WORK_FLOW_TASK, TAGS.DOCKET, TAGS.DOCUMENT, TAGS.WORK_FLOW_TASK],
  endpoints: (builder) => ({
    getDocketTypes: builder.query({
      query: () => DOCKET_TYPES,
      providesTags: [TAGS.DOCKET],
    }),
    // Get Tasks
    getTasks: builder.query({
      query: () => "Task",
      transformResponse: (res) => res.sort((a, b) => b.id - a.id),
      providesTags: [TAGS.WORK_FLOW_TASK],
    }),
    // Create Task
    createTask: builder.mutation({
      query: (task) => ({
        url: "Task",
        method: HTTP_METHODS.POST,
        body: task,
      }),
      invalidatesTags: [TAGS.WORK_FLOW_TASK],
    }),
    //get task by id
    getTaskById: builder.query({
      query: (taskId) => ({
        url: `Task/${taskId}`,
        method: HTTP_METHODS.GET,
      }),
      providesTags: [TAGS.WORK_FLOW_TASK],
    }),
    //update task
    updateTask: builder.mutation({
      query: (task) => ({
        url: `Task/${task.id}`,
        method: HTTP_METHODS.PATCH,
        body: task,
      }),
      invalidatesTags: [TAGS.WORK_FLOW_TASK],
    }),
    //getDocket
    getTaskDocket: builder.query({
      query: (args) => {
        const { id, fetchDocuments } = args;
        return {
          url: `${DOCKETS}/${id}`,
          params: { fetchDocuments },
        };
      },
      providesTags: [TAGS.DOCKET],
    }),
    //GetDocuments
    getTaskDocument: builder.query({
      query: (data) => `${DOCKETS}/${data.docket}/${DOCUMENTS}/${data.id}`,
      providesTags: [TAGS.DOCUMENT],
    }),
    //GetDocument Types
    getTaskDocumentTypes: builder.query({
      query: () => DOCUMENT_TYPE,
      providesTags: [TAGS.DOCUMENT],
    }),
    //Download Document
    downloadTaskDocument: builder.query({
      query: (args) => {
        const { docketId, documentId, versionId } = args;
        return {
          url: `${DOCKETS}/${docketId}/${DOCUMENTS}/${documentId}/download`,
          params: { versionId },
        };
      },
    }),
    //Upload Task Document
    uploadTaskDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docketId}/${DOCUMENTS}/${payload.documentId}/upload`,
        method: HTTP_METHODS.PUT,
        body: payload.formData,
      }),
    }),
    //Delete Task Document
    deleteTaskDocument: builder.mutation({
      query: (payload) => ({
        url: `${DOCKETS}/${payload.docket}/${DOCUMENTS}/${payload.id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.DOCKET],
    }),
    getEmployeeList: builder.query({
      query: () => ({
        url: `Companies/${getCurrentTenant()}/Employees`,
        method: HTTP_METHODS.GET,
      }),
      invalidatesTags: [COMPANIES],
    }),

    updateTaskStatus: builder.mutation({
      query: (params) => ({
        url: `Task/${params.id}`,
        method: HTTP_METHODS.PATCH,
        body: {
          state: params.state,
        },
      }),
      invalidatesTags: [TAGS.WORK_FLOW_TASK],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
  useGetDocketTypesQuery,
  useGetTaskDocketQuery,
  useGetTaskDocumentQuery,
  useGetTaskDocumentTypesQuery,
  useDownloadTaskDocumentQuery,
  useUploadTaskDocumentMutation,
  useDeleteTaskDocumentMutation,
  useGetEmployeeListQuery,
} = workFlowTasksApi;
