import AuthGuard from "src/guards/AuthGuard";

const withAuthGuard = (Component) => (props) =>
  (
    <AuthGuard>
      <Component {...props} />
    </AuthGuard>
  );

export default withAuthGuard;
