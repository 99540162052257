import PropTypes from "prop-types";

const icons = {
  // albums: "/assets/icons/icon-albums.svg",
  albums: "/assets/icons/icon-library.svg",
  jpeg: "/assets/icons/icon-jpg.svg",
  jpg: "/assets/icons/icon-jpg.svg",
  mp4: "/assets/icons/icon-mp4.svg",
  pdf: "/assets/icons/icon-pdf.svg",
  png: "/assets/icons/icon-png.svg",
  svg: "/assets/icons/icon-svg.svg",
  "svg+xml": "/assets/icons/icon-svg.svg",
  docx: "/assets/icons/icon-word.svg",
  msword: "/assets/icons/icon-word.svg",
  "vnd.openxmlformats-officedocument.wordprocessingml.document":
    "/assets/icons/icon-word.svg",
  doc: "/assets/icons/icon-word.svg",
  folder: "/assets/icons/icon-folder.svg",
  xls: "/assets/icons/icon-excel.svg",
  xlsx: "/assets/icons/icon-excel.svg",
  csv: "/assets/icons/icon-excel.svg",
  "vnd.ms-excel": "/assets/icons/icon-excel.svg",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "/assets/icons/icon-excel.svg",
};

const FileIcon = ({ extension, styles = {} }) => {
  let icon;

  if (!extension) {
    icon = "/assets/icons/icon-other.svg";
  } else {
    icon = icons[extension] || "/assets/icons/icon-other.svg";
  }

  return <img style={{ ...styles }} src={icon} alt={extension} />;
};

FileIcon.propTypes = {
  styles: PropTypes.object,
  extension: PropTypes.string,
};

export default FileIcon;
