import * as Yup from "yup";

export const corporateProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  corporateRoleId: Yup.string().required("Corporate Role is required"),
});

// some validations are based current tab
export const employeeValidationSchema = (currentTab) =>
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    dateOfBirth: Yup.date().typeError("Provide valid date").nullable(),
    //gender: Yup.string().nullable().required("Gender is required"),
    //employeeNumber: Yup.string().required("Employee number is required"),
    nicNumber: Yup.string().required("NIC number is required"),
    //passportNumber: Yup.string().required("Passport number is required"),
    //companyEmail: Yup.string()
    //.email("Invalid email")
    //.required("Company email is required"),
    //personalEmail: Yup.string()
    // .email("Invalid email")
    //  .required("Personal email is required"),
    //mobileNumber: Yup.string().required("Mobile number is required"),
    //officeNumber: Yup.string().required("Office number is required"),
    //addressLine1: Yup.string().required("Address Line 1 is required"),
    //departmentId: Yup.string().required("Department is required"),
    //country: Yup.string().required("Country is required"),
    //dateOfAppoinment: Yup.date()
    //  .typeError("Date of appoint is required")
    //  .required("Date of appoint is required"),
    dateOfAppoinment: Yup.date().typeError("Provide valid date").nullable(),
    //corporateRoleId: Yup.string().when("name", {
    //  is: (name) => name && currentTab === 2,
    //  then: (schema) => schema.required("Corporate role is required"),
    //  otherwise: (schema) => schema.nullable(),
    //}),
    name: Yup.string().when("corporateRoleId", {
      is: (corporateRoleId) => corporateRoleId && currentTab === 2,
      then: (schema) => schema.required("Name is required"),
      otherwise: (schema) => schema.nullable(),
    }),

    // corporateRoleId: Yup.string().when("name", {
    //   is: (name) => name && currentTab === 2,
    //   then: (schema) => schema.required("Corporate role is required"),
    //   otherwise: (schema) => schema.nullable(),
    // }),
    // userId: Yup.number().when("corporateRoleId", {
    //   is: (corporateRoleId) => corporateRoleId > 0 && currentTab === 2,
    //   then: (schema) => schema.min(1, "User is required"),
    //   otherwise: (schema) => schema.nullable(),
    // }),
  });
