import React from "react";
import PropTypes from "prop-types";

// mui
import { TableCell, TableRow, Typography } from "@mui/material";
import useGetCompanies from "src/hooks/company/useGetCompanies";

const CorporateRoleRows = ({ roles }) => {
  const { selectOptions } = useGetCompanies();
  const getCompanyName = (companyId) => {
    const company = selectOptions.find((option) => option.value === companyId);
    return company ? company.label : "Unknown Company";
  };
  return (
    <>
      {roles.map((role) => (
        <TableRow hover key={role.id}>
          <TableCell>
            <Typography variant="subtitle2">{role?.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle2">
              {getCompanyName(role?.companyId)}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

CorporateRoleRows.propTypes = {
  roles: PropTypes.array,
};

CorporateRoleRows.defaultProps = {
  roles: [],
};

export default CorporateRoleRows;
