import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import {
  CORPORATE_AUTHORITIES,
  COMPANIES,
  INSTITUTIONS,
  SIGNATORY_TAG,
  ACCOUNTS,
  AUTHORITY_TYPE,
  CORPORATE_AUTHORIZATIONS,
  EMPLOYEES,
  CORPORATE_AUTHORITIES_WITH_TYPE,
} from "src/helpers/url";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "../apiService";

export const TAGS = {
  CORPORATE_AUTHORITIES: "CORPORATE_AUTHORITIES",
  CORPORATE_AUTHORITY: "CORPORATE_AUTHORITY",
  INSTITUTIONS: "INSTITUTIONS",
  AUTHORIZATION: "AUTHORIZATION",
};

export const corporateAuthoritiesApi = createApi({
  reducerPath: "corporateAuthoritiesApi",
  baseQuery,
  tagTypes: [
    TAGS.CORPORATE_AUTHORITIES,
    TAGS.CORPORATE_AUTHORITY,
    TAGS.INSTITUTIONS,
    TAGS.AUTHORIZATION,
  ],
  endpoints: (builder) => ({
    getCorporateAuthorities: builder.query({
      query: () =>
        `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthority: builder.query({
      query: (id) =>
        `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES}/${id}`,
      providesTags: [TAGS.CORPORATE_AUTHORITY],
    }),
    getCorporateAuthoritiesByInstitution: builder.query({
      query: (institutionId) =>
        `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}/${institutionId}/${CORPORATE_AUTHORITIES}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthoritiesByInstitutionWithType: builder.query({
      query: ({ institutionId, authorityType }) =>
        `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}/${institutionId}/${CORPORATE_AUTHORITIES}/${authorityType}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthorization: builder.query({
      query: ({ employeeId, id }) =>
        `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${employeeId}/${CORPORATE_AUTHORIZATIONS}/${id}`,
      providesTags: [TAGS.AUTHORIZATION],
    }),
    createCorporateAuthority: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    updateCorporateAuthority: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES}/${
          payload.id
        }`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_AUTHORITY],
    }),
    deleteCorporateAuthority: builder.mutation({
      query: (id) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthorityLevels: builder.query({
      query: (id) =>
        `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}/${id}/${CORPORATE_AUTHORITIES}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthoritySignatoryLevelsWithType: builder.query({
      query: ({ id, type }) =>
        `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}/${id}/${CORPORATE_AUTHORITIES}/${type}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAccountAuthoritySignatory: builder.query({
      query: ({ id, type }) =>
        `${ACCOUNTS}/${id}/${CORPORATE_AUTHORIZATIONS}/${AUTHORITY_TYPE}/${type}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
    getCorporateAuthorityLevelsWithType: builder.query({
      query: ({ type }) =>
        `${COMPANIES}/${getCurrentTenant()}/${CORPORATE_AUTHORITIES_WITH_TYPE}/${type}`,
      providesTags: [TAGS.CORPORATE_AUTHORITIES],
    }),
  }),
});

export const {
  useGetCorporateAuthoritiesQuery,
  useGetCorporateAuthorityQuery,
  useGetCorporateAuthoritiesByInstitutionWithTypeQuery,
  useGetCorporateAuthoritiesByInstitutionQuery,
  useGetCorporateAuthorizationQuery,
  useCreateCorporateAuthorityMutation,
  useUpdateCorporateAuthorityMutation,
  useDeleteCorporateAuthorityMutation,
  useGetCorporateAuthorityLevelsQuery,
  useGetCorporateAuthoritySignatoryLevelsWithTypeQuery,
  useGetCorporateAccountAuthoritySignatoryQuery,
  useGetCorporateAuthorityLevelsWithTypeQuery,
} = corporateAuthoritiesApi;
