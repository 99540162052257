import filteredObj from "src/utils/filteredObj";
import Base from "../../Base";

class Branch extends Base {
  constructor({
    id,
    name,
    code,
    address,
    contactNumber,
    contactPerson,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      name,
      code,
      _address: address,
      contactNumber,
      contactPerson,
    });
  }

  set address(address) {
    this._address = address;
  }

  get address() {
    const filtered = filteredObj(this._address);
    let address = "";
    for (const key in filtered) {
      address += `${filtered[key]}, `;
    }
    return address;
  }
}

export default Branch;
