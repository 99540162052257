import { useContext, useEffect } from "react";
import PropTypes from "prop-types";

// hooks
import useRouter from "src/hooks/useRouter";

// context
import AuthContext from "src/contexts/auth/AuthContext";

const GuestGuard = ({ children }) => {
  const router = useRouter();
  const { isAuthenticated, isInitialized, isAdmin } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && isInitialized && !isAdmin)
      router.replace("/dashboard");

    if (isAuthenticated && isInitialized && isAdmin) router.replace("/admin");
  }, [isAuthenticated, isInitialized, isAdmin, router]);

  if (isAuthenticated && isInitialized) {
    return null;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
