import numeral from "numeral";
import Base from "src/models/Base";

class CorporateAuthorityLevel extends Base {
  constructor({
    id,
    name,
    description,
    financeLimit,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      name,
      description,
      financeLimit: numeral(financeLimit).format("0,0.00"),
    });
  }
}

export default CorporateAuthorityLevel;
