import { useEffect } from "react";

// hooks
import { useDispatch } from "src/store";

// actions
import { setPageTitle } from "src/redux/slice";

const usePageTitle = ({ title }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(title));
  }, [dispatch, title]);
};

export default usePageTitle;
