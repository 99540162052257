import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// mui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// context
import SettingsContext from "./contexts/settings/SettingsContext";
import SettingsProvider from "./contexts/settings/SettingsProvider";
import AuthProvider from "./contexts/auth/AuthProvider";
import DocumentProvider from "./contexts/documents/DocumentProvider";

// components
import RTL from "./components/RTL";
import AlertModal from "./components/modals/AlertModal";
import Toaster from "./components/Toaster";

// routes
import { routes } from "./routes";

// redux store
import { useSelector } from "./store";

// theme
import { createTheme } from "./theme";

import "simplebar-react/dist/simplebar.min.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { loadingSelector } from "./redux/selectors";
import Fallback from "./components/Fallback";
import { Box } from "@mui/material";

const App = () => {
  const element = useRoutes(routes);

  const loading = useSelector(loadingSelector);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <SettingsContext.Consumer>
          {(settings) => {
            const theme = createTheme({
              colorPreset: settings.colorPreset,
              contrast: settings.contrast,
              direction: settings.direction,
              paletteMode: settings.paletteMode,
              responsiveFontSizes: settings.responsiveFontSizes,
            });

            return (
              <AuthProvider>
                <DocumentProvider>
                  <ThemeProvider theme={theme}>
                    <Helmet>
                      <meta
                        name="color-scheme"
                        content={settings.paletteMode}
                      />
                      <meta
                        name="theme-color"
                        content={theme.palette.neutral[900]}
                      />
                    </Helmet>
                    <ErrorBoundary>
                      <RTL direction={settings.direction}>
                        <CssBaseline />
                        {loading && (
                          <Box sx={{ p: 2 }}>
                            <Fallback />
                          </Box>
                        )}
                        {!loading && element}
                        <Toaster />
                        <AlertModal />
                      </RTL>
                    </ErrorBoundary>
                  </ThemeProvider>
                </DocumentProvider>
              </AuthProvider>
            );
          }}
        </SettingsContext.Consumer>
      </SettingsProvider>
    </LocalizationProvider>
  );
};

export default App;
