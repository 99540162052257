import { useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import { useDeleteEmployeeMutation } from "../redux/api";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useDeleteEmployee = () => {

  const [deleteEmployeeById, { isError, error, isSuccess }] =
    useDeleteEmployeeMutation();

  const deleteEmployee = (id) => {
    deleteEmployeeById(id);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Employee Deleted Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Employee Updating: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  return {
    deleteEmployee,
  };
};

export default useDeleteEmployee;
