import PropTypes from "prop-types";

// mui
import { MenuItem, Popover } from "@mui/material";

const TenantPopover = ({ anchorEl, onChange, onClose, open, tenants }) => (
  <Popover
    anchorEl={anchorEl}
    anchorOrigin={{
      horizontal: "right",
      vertical: "bottom",
    }}
    disableScrollLock
    transformOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
    keepMounted
    onClose={onClose}
    open={open}
    PaperProps={{ sx: { width: 180 } }}
  >
    {tenants.map((tenant) => (
      <MenuItem key={tenant} onClick={onChange.bind(null, tenant)}>
        {tenant}
      </MenuItem>
    ))}
  </Popover>
);

TenantPopover.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  tenants: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

TenantPopover.defaultProps = {
  open: false,
};

export default TenantPopover;
