// mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
} from "@mui/material";

const Authority = () => (
  <Card sx={{ cursor: "pointer" }}>
    <CardHeader
      title={<Skeleton variant="text" width={80} />}
      subheader={<Skeleton variant="text" width={100} />}
      action={<Skeleton variant="circular" width={15} height={15} />}
    />
    <Divider />
    <CardContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0.5}
        >
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={80} />
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={0.5}
        >
          <Skeleton variant="text" width={80} />
          <Skeleton variant="text" width={90} />
        </Stack>
      </Box>
    </CardContent>
  </Card>
);

export default Authority;
