import { format } from "date-fns";
import Base from "../Base";

// untitled-ui
import Lock04 from "@untitled-ui/icons-react/build/esm/Lock04";
import LockUnlocked04 from "@untitled-ui/icons-react/build/esm/LockUnlocked04";

// constants
import { DOCUMENT_DOCKET_OPTIONS } from "src/components/docket/constants";

class Document extends Base {
  constructor({
    id,
    guid,
    name,
    metaData,
    isLocked = false,
    documentTypeId,
    documentType = null,
    containerName = null,
    versions = [],
    isCurrentVersion = false,
    fileType,
    azureBlobStorageUrl,
    tenantId,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    status,
    // maxCharacters = 20,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      guid,
      name,
      metaData,
      isLocked,
      documentTypeId,
      documentType,
      containerName,
      versions,
      isCurrentVersion,
      fileType,
      azureBlobStorageUrl,
      tenantId,
      status,
      _maxCharacters: 25,
    });

    this.currentDocumentVersion = this.#getCurrentVersion();
  }

  get noOfVersions() {
    return this.versions.length;
  }

  get truncatedName() {
    return this.name.length > this._maxCharacters
      ? this.name.slice(0, this._maxCharacters) + "..."
      : this.name;
  }
  set maxCharacters(char) {
    this._maxCharacters = char;
  }

  get lockUnlockIcon() {
    if (!this.isLocked) return <Lock04 />;
    return <LockUnlocked04 />;
  }

  get lockUnlockLabel() {
    if (this.isLocked) return DOCUMENT_DOCKET_OPTIONS.UNLOCK;
    return DOCUMENT_DOCKET_OPTIONS.LOCK;
  }

  get isAllowedToView() {
    return !!this.azureBlobStorageUrl;
  }

  #getCurrentVersion = () => {
    return this.versions.find((version) => version.isCurrentVersion);
  };

  /**
   * get the last modified date of the document
   * @param {Boolean} isVersion
   * @returns {String}
   */
  getLastModified(isVersion) {
    const current = this.#getCurrentVersion();

    if (!isVersion && current) {
      return format(new Date(current.updatedAt), "dd/MM/yyyy");
    }

    if (isVersion && this.updatedAt) {
      return format(new Date(this.updatedAt), "dd/MM/yyyy");
    }

    return;
  }
}

export default Document;
