export const DOCUMENT_DOCKET_OPTIONS = {
  VIEW: "View",
  REMOVE: "Remove",
  SET_CURRENT: "Set as Current",
  UPLOAD: "Upload",
  DOWNLOAD: "Download",
  LOCK: "Lock",
  UNLOCK: "Unlock",
  RENAME: "Rename",
  MOVE: "Move",
  NEW_FOLDER: "New Folder",
  NEW_DOCUMENT: "New Document",
  PREVIEW: "Preview",
};

export const DIALOG_TYPE = {
  UPLOAD: "UPLOAD",
  NEW_DOCUMENT: "NEW_DOCUMENT",
  NEW_FOLDER: "NEW_FOLDER",
  NEW_LIBRARY: "NEW_LIBRARY",
  NEW_DOCUMENT_UPLOAD: "NEW_DOCUMENT_UPLOAD",
  CONFIRMATION: "CONFIRMATION",
  MOVE_DOCUMENT: "MOVE_DOCUMENT",
  MULTIPLE_UPLOAD: "MULTIPLE UPLOAD",
  DOCUMENT_TYPE: "DOCUMENT TYPE",
  PREVIEW: "PREVIEW",
};

export const WORD_TYPES = [
  "msword",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
  "vnd.openxmlformats-officedocument.word",
];

export const EXCEL_TYPES = [
  "vnd.ms-excel",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

export const DOCUMENT_REPOSITORY_VIEW = {
  GRID: "grid",
  LIST: "list",
};
