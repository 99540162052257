import { useFormik } from "formik";
import { useDispatch } from "src/store";
import { useEffect } from "react";
import { useUpdateDocumentTypeMutation } from "src/redux/documents/api";
import useToast from "../../../../hooks/useToast";
import toast from "react-hot-toast";
import { validationSchema } from "../validation";
import { closeFormDialog } from "src/redux/slice";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useUpdateDocumentType = (type) => {
    const dispatch = useDispatch();
    const { toastIdHandler, cleanup, toastId } = useToast();
    const [
        update, { isLoading, isError, isSuccess, error, data
    }] = useUpdateDocumentTypeMutation();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: type?.name ?? "",
            documentCategoryId: type?.documentCategoryId ?? 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.name !== type.name || values.documentCategoryId !== type.documentCategoryId) {
                update({ ...values, id: type.id});
            } else {
                toast.error("No changes made.", {
                    duration: ERROR_TOAST_DURATION,
                });
                dispatch(closeFormDialog());
            }
        },
    });

    useEffect(() => {
        if (isLoading && !toastId) {
            const _toastId = toast.loading("Updating", {
                duration: DEFAULT_TOAST_DURATION,
            });
            toastIdHandler(_toastId);
        }
        if (isSuccess && toastId) {
            toast.success("Document Type Updated Successfully.", {
                duration: DEFAULT_TOAST_DURATION,
                id: toastId,
            });
            dispatch(closeFormDialog());
        }
        if (isError && toastId) {
            toast.error(`${error?.data?.detail}`, {
                duration: ERROR_TOAST_DURATION,
                id: toastId,
            });
        }
        return () => {
            cleanup();
        };
    }, [
        data,
        isSuccess,
        isLoading,
        toastId,
        cleanup,
        error,
        toastIdHandler,
        dispatch,
    ]);

    return {
        formik,
        isLoading,
        update,
    };
};

export default useUpdateDocumentType;
