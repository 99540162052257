import PropTypes from "prop-types";

// mui
import { IconButton, Tooltip } from "@mui/material";

const Action = ({ option }) => (
  <Tooltip title={option.title}>
    <IconButton
      sx={{ ...option.sx }}
      onClick={option.onClick.bind(null, document)}
    >
      {option.icon}
    </IconButton>
  </Tooltip>
);

Action.propTypes = {
  option: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    sx: PropTypes.any,
    icon: PropTypes.any,
    onClick: PropTypes.func,
  }),
};

export default Action;
