import PropTypes from "prop-types";

// mui
import {
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  tableCellClasses,
  TableRow,
  Tooltip,
} from "@mui/material";

// untitled-ui
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import DownloadIcon from "@untitled-ui/icons-react/build/esm/Download01";
import UploadIcon from "@untitled-ui/icons-react/build/esm/Share01";
import LinkBrokenIcon from "@untitled-ui/icons-react/build/esm/LinkBroken01";

// models
import DocumentModel from "src/models/documents/Document";

// hooks
import usePopover from "src/hooks/usePopover";

// components
import DocumentOptions from "../../DocumentOptions";
import LastModified from "../LastModified";
import DocumentIcons from "../../DocumentIcons";
import ItemTitle from "./ItemTitle";
import Action from "./Action";
import ModifiedBy from "../ModifiedBy";

// utils
import generateUniqueKey from "src/utils/generateUniqueKeys";

// other
import { info } from "src/theme/colors";
import { TOOLTIP_TEXT } from "../../../../constants";

export const sx = {
  backgroundColor: "background.paper",
  borderRadius: 1.5,
  boxShadow: 0,
  transition: (theme) =>
    theme.transitions.create(["background-color", "box-shadow"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 200,
    }),
  "&:hover": {
    boxShadow: 10,
  },
  [`& .${tableCellClasses.root}`]: {
    borderBottomWidth: 1,
    borderBottomColor: "divider",
    borderBottomStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: "divider",
    borderTopStyle: "solid",
    "&:first-of-type": {
      borderTopLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
      borderBottomLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
      borderLeftWidth: 1,
      borderLeftColor: "divider",
      borderLeftStyle: "solid",
    },
    "&:last-of-type": {
      borderTopRightRadius: (theme) => theme.shape.borderRadius * 1.5,
      borderBottomRightRadius: (theme) => theme.shape.borderRadius * 1.5,
      borderRightWidth: 1,
      borderRightColor: "divider",
      borderRightStyle: "solid",
    },
  },
};

const Document = ({
  docketId,
  document,
  isVersion,
  isMore,
  isUploadAllowed,
  downloadOnly,
  isMoveAllowed,
  onUpload,
  onDownload,
  onView,
  onMove,
  onUnlink,
}) => {
  const { anchorRef, open, handleClose, handleOpen } = usePopover();

  const getOptions = () => {
    const options = [
      {
        key: generateUniqueKey("upload"),
        title: "Upload",
        icon: (
          <SvgIcon fontSize="medium">
            <UploadIcon />
          </SvgIcon>
        ),
        sx: { color: "primary.main" },
        onClick: onUpload.bind(null, document),
      },
      {
        key: generateUniqueKey("download"),
        title: "Download",
        icon: (
          <SvgIcon fontSize="medium">
            <DownloadIcon />
          </SvgIcon>
        ),
        sx: { color: info["dark"] },
        onClick: onDownload.bind(null, document),
      },
    ];

    let temp = options;

    if (isVersion)
      return [...options.filter((option) => option.title !== "Upload")];

    if (!isUploadAllowed || downloadOnly)
      temp = [...temp.filter((option) => option.title !== "Upload")];

    if (!document.azureBlobStorageUrl)
      temp = [...temp.filter((option) => option.title !== "Download")];

    return temp;
  };

  return (
    <>
      <TableRow key={document.id} sx={sx}>
        <TableCell>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={onView.bind(null, document)}
          >
            <DocumentIcons isVersion={isVersion} item={document} />
            <ItemTitle item={document} isVersion={isVersion} />
          </Stack>
        </TableCell>
        <TableCell align="right">
          <LastModified object={document} isVersion={isVersion} />
        </TableCell>
        <TableCell align="right">
          <ModifiedBy document={document} />
        </TableCell>
        <TableCell align="right">
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={0.2}
          >
            {getOptions().map((option) => (
              <Action key={option.key} option={option} />
            ))}
          </Stack>
        </TableCell>
        {isMore && (
          <TableCell align="right">
            <Tooltip title={TOOLTIP_TEXT.MORE_OPTIONS}>
              <IconButton onClick={handleOpen} ref={anchorRef}>
                <SvgIcon fontSize="small">
                  <DotsVerticalIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {onUnlink && (
          <TableCell align="right">
            <Tooltip title="Unlink">
              <IconButton onClick={onUnlink.bind(null, document)}>
                <SvgIcon>
                  <LinkBrokenIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
      <DocumentOptions
        document={document}
        docketId={docketId}
        anchorEl={anchorRef.current}
        open={open}
        isVersion={isVersion}
        isMoveAllowed={isMoveAllowed}
        onClose={handleClose}
        onMove={onMove}
      />
    </>
  );
};

Document.propTypes = {
  docketId: PropTypes.any,
  document: PropTypes.instanceOf(DocumentModel),
  isVersion: PropTypes.bool,
  isMore: PropTypes.bool,
  isMoveAllowed: PropTypes.bool,
  downloadOnly: PropTypes.bool,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onView: PropTypes.func,
  onUnlink: PropTypes.func,
};

Document.defaultProps = {
  isVersion: false,
  isMore: true,
  downloadOnly: false,
  isUploadAllowed: true,
};

export default Document;
