import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../apiService";
import { LESSORS, LEASING } from "src/helpers/url";
import { HTTP_METHODS } from "../../constants";

export const TAGS = {
  LEASING: "LEASING",
  LESSORS: "LESSORS",
  LESSOR: "LESSOR",
};

export const lessorsApi = createApi({
  reducerPath: "lessorsApi",
  baseQuery,
  tagTypes: [TAGS.LESSOR, TAGS.LESSORS],
  endpoints: (builder) => ({
    getLessors: builder.query({
      query: () => LESSORS,
      providesTags: [TAGS.LESSOR, TAGS.LESSORS],
    }),
    getLeaseById: builder.query({
      query: (id) => `${LEASING}/${id}`,
      providesTags: [TAGS.LEASING],
    }),
    createLessor: builder.mutation({
      query: (payload) => ({
        url: LESSORS,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.LESSORS],
    }),
    updateLessor: builder.mutation({
      query: (payload) => ({
        url: `${LESSORS}/${payload.lessorId}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.LESSORS],
    }),
    // deleteLessor: builder.mutation({
    //     query: (id) => ({
    //         url: `${LESSORS}/${id}`,
    //         method: HTTP_METHODS.DELETE,
    //     }),
    //     invalidatesTags: [TAGS.LESSORS],
    // }),
  }),
});

export const {
  useGetLessorsQuery,
  useGetLeaseByIdQuery,
  useLazyGetLeaseByIdQuery,
  useCreateLessorMutation,
  useUpdateLessorMutation,

  // useGetLessorQuery,
  // useLazyGetLessorQuery,
  // useDeleteLessorMutation,
} = lessorsApi;
