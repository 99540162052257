import React from "react";
import Authority from "./Authority";
import { Grid } from "@mui/material";

const AuthoritiesSkeleton = () => (
  <Grid container spacing={2}>
    {[1, 2, 3].map((authority) => (
      <Grid key={authority} item xs={12} md={4} lg={4}>
        <Authority />
      </Grid>
    ))}
  </Grid>
);

export default AuthoritiesSkeleton;
