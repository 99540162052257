// hooks

import usePageTitle from "src/hooks/usePageTitle";

// features
import NewEmployeeFeature from "src/features/admin/employees/create";

// other
import { pageTitles } from "src/pageTitles";

const NewEmployee = () => {
  usePageTitle({ title: pageTitles.admin.employees.newEmployee });

  return <NewEmployeeFeature />;
};

export default NewEmployee;
