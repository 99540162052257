import AdminGuard from "src/guards/AdminGuard";

const withAdminGuard = (Component) => (props) =>
  (
    <AdminGuard>
      <Component {...props} />
    </AdminGuard>
  );

export default withAdminGuard;
