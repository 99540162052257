import { useMemo } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";

//actions
import { useEffect } from "react";

// hooks
import { useDispatch } from "src/store";
import {
  useGetApplicationUsersQuery,
  useUpdateEmployeeMutation,
} from "../redux/api";
import useGetEmployee from "./useGetEmployee";
import useRouter from "src/hooks/useRouter";

// other
import employeeValidationSchema from "../validations";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { paths } from "src/paths";
import generateOptions from "src/utils/generateOptions";
import convertToModel from "src/helpers/convertToModel";
import { Grid } from "@mui/material";
import generateInputField from "src/helpers/generateInputField";
import ApplicationUser from "src/models/organization/ApplicationUser";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useUpdateEmployee = (id) => {
  const { employee } = useGetEmployee(id);
  const { push } = useRouter();
  const dispatch = useDispatch();

  const [update, { isLoading, isError, isSuccess, error, data }] =
    useUpdateEmployeeMutation();
  const { data: applicationUsers = [] } = useGetApplicationUsersQuery();

  const formik = useFormik({
    initialValues: {
      firstName: employee?.firstName ?? "",
      lastName: employee?.lastName ?? "",
      dateOfBirth: employee?.dateOfBirth ?? null,
      gender: employee?.gender ?? "",
      employeeNumber: employee?.employeeNumber ?? "",
      nicNumber: employee?.nicNumber ?? "",
      passportNumber: employee?.passportNumber ?? "",
      companyEmail: employee?.companyEmail ?? "",
      personalEmail: employee?.personalEmail ?? "",
      mobileNumber: employee?.mobileNumber ?? "",
      officeNumber: employee?.officeNumber ?? "",
      dateOfAppoinment: employee?.dateofAppoinment ?? "",
      addressNumber: employee?.residentialAddress?.number ?? "",
      addressLine1: employee?.residentialAddress?.addressLine1 ?? "",
      addressLine2: employee?.residentialAddress?.addressLine2 ?? "",
      street: employee?.residentialAddress?.street ?? "",
      city: employee?.residentialAddress?.city ?? "",
      userId: employee?.userId ?? 0,
      province: employee?.residentialAddress?.province ?? "",
      country: employee?.residentialAddress?.country ?? "",
      departmentId: employee?.departmentId !== 0 ? employee?.departmentId : undefined,
      zipCode: employee?.residentialAddress?.zipCode ?? "",
    },
    enableReinitialize: true,
    validationSchema: employeeValidationSchema,
    onSubmit: (values) => {
      const data = {
        employeeId: id,
        updateEmployeeDto: {
          firstName: values?.firstName || "",
          lastName: values?.lastName || "",
          dateOfBirth: values?.dateOfBirth || employee?.dateOfBirth,
          gender: values?.gender || "",
          employeeNumber: values?.employeeNumber || "",
          nicNumber: values?.nicNumber || "",
          passportNumber: values?.passportNumber || "",
          companyEmail: values?.companyEmail || "",
          personalEmail: values?.personalEmail || "",
          mobileNumber: values?.mobileNumber || "",
          officeNumber: values?.officeNumber || "",
          departmentId: values?.departmentId || "",
          userId: values.userId,
          companyId: getCurrentTenant(),
          residentialAddress: {
            number: values?.addressNumber || "",
            addressLine1: values?.addressLine1 || "",
            addressLine2: values?.addressLine2 || "",
            street: values?.street || "",
            city: values?.city || "",
            province: values?.province || "",
            country: values?.country || "",
            zipCode: values?.zipCode || "",
          },
        },
      };
      update(data);
    },
  });

  const users = useMemo(
    () => convertToModel(applicationUsers, ApplicationUser),
    [applicationUsers]
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success("Employee Updated Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      push(paths.admin.employee.index);
    }
  }, [data, dispatch, isSuccess, push]);

  useEffect(() => {
    if (isError) {
      toast.error(`Error on employee updating: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [dispatch, isError, error]);

  const renderFields = (item, departments, geographies) => {
    let field = { ...item };
    let options = [...(field.options || [])];

    if (field.name === "userId")
      options = generateOptions(users, "id", "fullName");

    if (field.name === "departmentId")
      options = generateOptions(departments, "value", "label");

    if (field.name === "country")
      options = generateOptions(geographies, "name", "name");

    if (typeof item.label === "function") {
      field.label = field.label(formik.values);
    }

    return (
      <Grid item key={field.key} xs={field.xs} md={field.md}>
        {generateInputField(field, formik, options)}
      </Grid>
    );
  };

  return {
    formik,
    isLoading,
    renderFields,
  };
};

export default useUpdateEmployee;
