import { useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import {
  useDeleteDocketMutation,
  useUpdateLockUnlockDocketMutation,
} from "src/redux/documents/api";
import useToast from "../useToast";
import { useDispatch } from "src/store";
import useRouter from "../useRouter";

// redux
import { resetSearchKeyWord } from "src/features/organization/documents/redux/slice";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import { useState } from "react";

const useDocketLifeCycleActionsLogic = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { toastIdHandler, cleanup, toastId } = useToast();
  const [isLocked, setIsLocked] = useState();

  const [
    lockUnlockDocket,
    {
      isError: isLockUnlockError,
      error: lockUnlockError,
      isLoading: lockingOrUnlocking,
      isSuccess: operationSuccess,
      reset: resetLockUnlockState,
    },
  ] = useUpdateLockUnlockDocketMutation();

  const [
    deleteDocket,
    {
      isLoading: isDeleting,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
      reset: resetDeleteState,
    },
  ] = useDeleteDocketMutation();

  // handle docket lock/unlock feedback
  useEffect(() => {
    if (lockingOrUnlocking && !toastId) {
      const loadingMessage = !isLocked
        ? "Unlocking Docket..."
        : "Locking Docket...";
      const _toastId = toast.loading(loadingMessage);
      toastIdHandler(_toastId);
    }

    if (operationSuccess && toastId) {
      const successMessage = !isLocked
        ? "Docket Unlocked Successfully."
        : "Docket Locked Successfully.";
      toast.success(successMessage, {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });
      resetLockUnlockState();
    }

    if (isLockUnlockError && toastId) {
      toast.error(`Error: ${lockUnlockError?.data?.detail}`, {
        id: toastId,
        duration: ERROR_TOAST_DURATION,
      });
      resetLockUnlockState();
    }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      cleanup();
    };
  }, [
    cleanup,
    isLockUnlockError,
    lockUnlockError,
    lockingOrUnlocking,
    operationSuccess,
    resetLockUnlockState,
    toastId,
    toastIdHandler,
    isLocked,
  ]);

  // handle docket remove feedback
  useEffect(() => {
    if (isDeleting && !toastId) {
      const _toastId = toast.loading("Deleting...");
      toastIdHandler(_toastId);
    }

    if (isDeleteSuccess && toastId) {
      toast.success("Docket Deleted Successfully.", {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });
      resetDeleteState();
    }

    if (isDeleteError && toastId) {
      toast.error(`Error: ${deleteError?.data?.detail}`, {
        id: toastId,
        duration: ERROR_TOAST_DURATION,
      });
      resetDeleteState();
    }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      if (toastId) {
        cleanup();
      }
    };
  }, [
    cleanup,
    deleteError,
    isDeleteError,
    isDeleteSuccess,
    isDeleting,
    resetDeleteState,
    toastId,
    toastIdHandler,
  ]);

  const lockUnlockHandler = (docket) => {
    const isLocked = !docket.isLocked;
    lockUnlockDocket({ id: docket.id, isLocked: isLocked });
    setIsLocked(isLocked);
  };

  const deleteHandler = (docket) => deleteDocket(docket.id);

  const viewHandler = (path) => {
    dispatch(resetSearchKeyWord());
    push(path);
  };

  return {
    lockingOrUnlocking,
    viewHandler,
    lockUnlockHandler,
    deleteHandler,
  };
};

export default useDocketLifeCycleActionsLogic;
