const generateOptions = (data = [], value, label, customLabel) => {
  if (customLabel) {
    return data.map((d) => {
      return {
        value: d[value],
        label: customLabel(d),
      };
    });
  }

  return data.map((d) => {
    return {
      value: d[value],
      label: d[label],
    };
  });
};

export default generateOptions;
