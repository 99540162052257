import { useContext } from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from "react-idle-timer";

// context
import AuthContext from "src/contexts/auth/AuthContext";

// hoc
import withAuthGuard from "src/hocs/withAuthGuard";

// hooks
import useSettings from "src/hooks/useSettings";
import useSections from "src/hooks/useSections";
import useInitializer from "src/hooks/useInitializer";

// components
import HorizontalLayout from "./horizontal-layout";
import VerticalLayout from "./vertical-layout";

export const Layout = withAuthGuard((props) => {
  const { onSignOut } = useContext(AuthContext);

  useInitializer();
  const settings = useSettings();
  const sections = useSections();

  // sign out when user idle (30min)
  useIdleTimer({ onIdle: onSignOut, timeout: 1800000, throttle: 500 });

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
