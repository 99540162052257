class FileUtility {
  /**
   * Decode base64 to ArrayBuffer.
   * @param {String} base64 - The base64 string to decode.
   * @returns {Uint8Array} - Decoded ArrayBuffer.
   */
  static base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  /**
   * Handle file download from byte array.
   * @param {String} fileName - Name of the file to save.
   * @param {String} type - MIME type of the file.
   * @param {Byte} byte - Byte array of the file content.
   */
  static saveByteArray(fileName, type, byte) {
    const bytes = new Uint8Array(byte);
    const blob = new Blob([bytes], { type });
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}

export default FileUtility;
