import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../constants";
import {
  COMPANIES,
  AUDITORS,
  BOARD_OF_DIRECTORS,
  SECRETARIES,
  SHAREHOLDERS,
  DEPARTMENTS,
  DEPARTMENT,
  INSTITUTIONS,
} from "../../helpers/url";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "../apiService";

const TAGS = {
  COMPANIES: "COMPANIES",
  COMPANY: "COMPANY",
  DEPARTMENTS: "DEPARTMENTS",
  INSTITUTIONS: "INSTITUTIONS",
};

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery,
  tagTypes: [TAGS.COMPANIES, TAGS.COMPANY, TAGS.DEPARTMENTS, TAGS.INSTITUTIONS],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => COMPANIES,
      providesTags: [TAGS.COMPANIES],
    }),
    getCompany: builder.query({
      query: (id) => `${COMPANIES}/${id}`,
      providesTags: [TAGS.COMPANY],
    }),
    getCompanyAuditors: builder.query({
      query: (id) => `${COMPANIES}/${id}/${AUDITORS}`,
    }),
    getCompanyBoardOfDirectors: builder.query({
      query: (id) => `${COMPANIES}/${id}/${BOARD_OF_DIRECTORS}`,
    }),
    getCompanySecretaries: builder.query({
      query: (id) => `${COMPANIES}/${id}/${SECRETARIES}`,
    }),
    getCompanyShareholders: builder.query({
      query: (id) =>
        `/${COMPANIES}/${id ?? getCurrentTenant()}/${SHAREHOLDERS}`,
    }),

    getCompanyCorporateAuthorities: builder.query({
      query: (id) =>
        `${COMPANIES}/${id ?? getCurrentTenant()}/corporateAuthorities`,
    }),

    getCompanyInstituteCorporateAuthorities: builder.query({
      query: (id) =>
        `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}/${id}/CorporateAuthorities`,
    }),

    getInstitutions: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/${INSTITUTIONS}`,
    }),

    createCompanies: builder.mutation({
      query: (payload) => ({
        url: COMPANIES,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateCompany: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${COMPANIES}/${id}`,
        method: HTTP_METHODS.PUT,
        body: { id, ...payload },
      }),
      invalidatesTags: [TAGS.COMPANY],
    }),
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `${COMPANIES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.COMPANIES],
    }),
    getDepartments: builder.query({
      query: () => `${COMPANIES}/${DEPARTMENTS}`,
      providesTags: [TAGS.DEPARTMENTS],
    }),
    getDepartmentsById: builder.query({
      query: (id) => `${COMPANIES}/${DEPARTMENTS}/${id}`,
      providesTags: [TAGS.DEPARTMENTS],
    }),
    createDepartment: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${DEPARTMENT}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.DEPARTMENTS],
    }),
    updateDepartments: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${DEPARTMENT}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.DEPARTMENTS],
    }),
    deleteDepartments: builder.mutation({
      query: (id) => ({
        url: `${COMPANIES}/${DEPARTMENT}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.DEPARTMENTS],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetCompanyAuditorsQuery,
  useLazyGetCompanyAuditorsQuery,
  useGetCompanyBoardOfDirectorsQuery,
  useLazyGetCompanyBoardOfDirectorsQuery,
  useLazyGetCompanySecretariesQuery,
  useGetCompanySecretariesQuery,
  useGetCompanyShareholdersQuery,
  useGetInstitutionsQuery,
  useLazyGetCompanyShareholdersQuery,
  useGetCompanyCorporateAuthoritiesQuery,
  useGetCompanyInstituteCorporateAuthoritiesQuery,
  useCreateCompaniesMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetDepartmentsQuery,
  useGetDepartmentsByIdQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentsMutation,
  useDeleteDepartmentsMutation,
} = companyApi;
