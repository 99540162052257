// input types
export const INPUT_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  PASSWORD: "password",
  TEL: "tel",
  SELECT: "select",
  EMAIL: "email",
  DATE: "date",
  TEXT_AREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  AUTO_COMPLETE: "auto complete",
};

// media types
export const MIME_TYPES = {
  PDF: "application/pdf",
  XLS: "application/vnd.ms-excel",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOCX1: "application/vnd.openxmlformats-officedocument.word",
};

// alert types
export const ALERT_TYPES = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
};

// http methods
export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

// http status codes messages
export const HTTP_STATUS_CODES = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
};

// credit card types
export const CREDIT_CARD_TYPES = {
  VISA: "Visa",
  MASTER_CARD: "MasterCard",
  AMERICAN_EXPRESS: "American Express",
  DISCOVER: "Discover",
  DINERS_CLUB: "Diners Club",
  JCB: "JCB",
};

export const DOCUMENT_LIBRARY_TYPES = {
  Main: 1,
  Workflows: 2,
  Agreements: 3,
  BOIRecords: 4,
  License: 5,
  Trademark: 6,
  RecoveriesAndLitigation: 7,
  ImmovableProperties: 8,
  LegalOpinions: 9,
  MergersAndAcquisition: 10,
  Guarantees: 11,
  LegislativeEnactments: 12,
  Empty: 13,
  PersonDocuments: 14,
};

export const NEW_DOCUMENT = "NEW_DOCUMENT";
export const UPLOAD = "UPLOAD";
export const FINAL_ARTIFACT_FORM = "FINAL_ARTIFACT_FORM";
export const LINK_DOCUMENT_FORM = "LINK_DOCUMENT_FORM";

export const TOOLTIP_TEXT = {
  MORE_OPTIONS: "More Options",
};

export const MAX_CHARACTER_FOR_LARGE_SCREEN = 35;

export const DEFAULT_TOAST_DURATION = 2000; // 2 seconds
export const ERROR_TOAST_DURATION = 3000; // 3 seconds
