import PropTypes from "prop-types";

// mui
import { Box, Table, TableBody } from "@mui/material";

// hooks
import useDocumentLifecycleActionsLogic from "../../../hooks/documents/useDocumentLifecycleActionsLogic";
import useUploadLogic from "src/hooks/documents/useUploadLogic";
import useDownloadLogic from "src/hooks/documents/useDownloadLogic";

// components
import Scrollbar from "src/components/Scrollbar";
import Document from "./document/Document";
import Docket from "./docket/Docket";

const LibraryItems = ({
  mainDocument,
  docketId,
  items,
  isVersion,
  isMore,
  isViewAllowed,
  isUploadAllowed,
  isMoveAllowed,
  downloadOnly,
  onUnlink,
}) => {
  const { uploadClickHandler } = useUploadLogic({ docketId });
  const { downloadHandler } = useDownloadLogic({
    parent: mainDocument,
    docketId,
    isVersion,
  });

  const { viewHandler: view, clickMoveHandler } =
    useDocumentLifecycleActionsLogic(docketId);

  const viewHandler = (document) => view(document, isViewAllowed);

  return (
    <Box>
      <Scrollbar>
        <Box>
          <Table
            sx={{
              minWidth: 400,
              borderCollapse: "separate",
              borderSpacing: "0 8px",
            }}
          >
            <TableBody>
              {items.map((item) => {
                if (!item.isDocket) {
                  return (
                    <Document
                      key={item.guid}
                      isMore={isMore}
                      docketId={docketId}
                      document={item}
                      isVersion={isVersion}
                      isMoveAllowed={isMoveAllowed}
                      isUploadAllowed={isUploadAllowed}
                      downloadOnly={downloadOnly}
                      onDownload={downloadHandler}
                      onUpload={uploadClickHandler}
                      onView={viewHandler}
                      onMove={clickMoveHandler}
                      onUnlink={onUnlink}
                    />
                  );
                }
                return <Docket key={item.guid} docket={item} />;
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Box>
  );
};

LibraryItems.propTypes = {
  mainDocument: PropTypes.any,
  docketId: PropTypes.any,
  items: PropTypes.array,
  isVersion: PropTypes.bool,
  isMore: PropTypes.bool,
  isViewAllowed: PropTypes.bool,
  downloadOnly: PropTypes.bool,
  isMoveAllowed: PropTypes.bool,
  isUploadAllowed: PropTypes.bool,
  onUnlink: PropTypes.func,
};

LibraryItems.defaultProps = {
  mainDocument: null,
  items: [],
  isVersion: false,
  isMore: true,
  isViewAllowed: true,
  downloadOnly: false,
  isMoveAllowed: true,
};

export default LibraryItems;
