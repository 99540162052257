import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { DIVIDEND_DECLARATION } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const dividendDeclarationApi = createApi({
  reducerPath: "dividendDeclarationApi",
  baseQuery,
  endpoints: (builder) => ({
    createRequest: builder.mutation({
      query: (payload) => ({
        url: DIVIDEND_DECLARATION,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
  }),
});

export const { useCreateRequestMutation } = dividendDeclarationApi;
