import { format } from "date-fns";

class Base {
  constructor({ id, createdAt, createdBy, updatedAt, updatedBy }) {
    Object.assign(this, {
      id,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
    });

    this.formattedCreatedAt = this.formatDate(createdAt);
    this.formattedUpdatedAt = this.formatDate(updatedAt);
  }

  formatDate(dateString) {
    if (!dateString) return null;
    return format(new Date(dateString), "dd/MM/yyyy");
  }

  convertToUTC(date) {
    const newDate = new Date(date);
    return newDate.toISOString();
  }
}

export default Base;
