// parent endpoints
export const SIGN_IN = "auth/signIn";
export const GEOGRAPHY = "geographies";
export const SECTOR = "sectors";
export const CURRENCY = "currencies";

export const WORKFLOW_REQUEST_TYPES = "workflowrequesttypes";
export const COMPANY_PROFILE = "companiesProfile";
export const COMPANIES = "companies";
export const SHAREHOLDERS = "shareholders";
export const DOCKETS = "dockets";
export const DOCUMENT_TYPE = "documentTypes";
export const DOCUMENT_CATEGORY = "DocumentCategories";
export const LAYOUTS = "layouts";
export const STAKEHOLDERS = "stakeholder";
export const WORKFLOW_REQUESTS = "workflows/requests";
export const WORKFLOW = "workflow";
export const DOCKET_TYPES = "docketType";
export const AUDITORS = "auditors";
export const WORK_FLOW_TASK = "Task";
export const DOCUMENT_LIBRARY_TEMPLATES = "documentLibraryTemplates";
export const EMPLOYEES = "employees";
export const CORPORATE_PROFILE = "corporateProfiles";
export const CORPORATE_AUTHORITIES = "corporateAuthorities";
export const CORPORATE_AUTHORITIES_WITH_TYPE = "CorporateAuthoritiesWithType";
export const INSTITUTIONS = "institutions";
export const DASHBOARD = "dashboard";
export const USER_REQUESTS = "userRequests";
export const USER_REQUESTS_LIST = "userRequestsList";
export const USERS = "users";
export const DEPARTMENTS = "Departments";
export const DEPARTMENT = "Department";
export const GPT = "GPT";
export const BANKS = "banks";
export const BANK_FACILITIES = "bankFacilities";
export const CREDIT_CARDS = "creditCard";
export const LEASE_FACILITIES = "leasing";
export const CREDIT_CARD = "CreditCard";
export const BRANCH = "branch";
export const ACCOUNT_CARDS = "creditcards";
export const LESSORS = "lessors";
export const LEASING = "leasing";
// child endpoints
export const SHARES = "shares";
export const REMARKS = "remarks";
export const DOCUMENTS = "documents";
export const DOCUMENT_LIBRARIES = "documentLibraries";
export const DOCUMENT_LIBRARY = "documentLibrary";
export const COMPANY_NAME_CHANGE = "companyNameChange";
export const COMPANY_SECRETARIES_CHANGE = "companySecretariesChange";
export const COMPANY_ADDRESS_CHANGE = "comapanyAddressChange";
export const COMPANY_ARTICLES_CHANGE = "companyArticlesChange";
export const COMPANY_DIVESTMENT = "companyDivestment";
export const COMPANY_ACQUISITION_REQUEST = "CompanyAcquisition";
export const COMPANY_AUDITORS_CHANGE = "companyAuditorsChange";
export const REMOVE_DIRECTOR = "removeDirector";
export const DIVIDEND_DECLARATION = "dividendDeclaration";
export const BOARD_OF_DIRECTORS = "boardOfDirectors";
export const SECRETARIES = "secretaries";
export const BOARD_RESOLUTIONS = "boardResolutions";
export const BOARD_RESOLUTION_TYPES = "boardResolutionTypes";

//Access Control
export const APPLICATION_ACCESS = "ApplicationAccess";
export const COMPANY_ACCESS = "CompanyAccess";

export const SIGNATORY_TAG = 1;
export const ACCOUNTS = "Accounts";
export const CORPORATE_AUTHORIZATIONS = "CorporateAuthorizations";
export const AUTHORITY_TYPE = "authorityType";
