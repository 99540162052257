import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Document name is required")
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      "Special characters, periods, and underscores are not allowed"
    ),
  documentTypeId: Yup.object().required("Document type is required"),
});

export default validationSchema;
