import React from "react";
import PropTypes from "prop-types";

// hoc
import withLoading from "src/hocs/withLoading";

// hooks
import useCorporateAuthorityDetailLogic from "../../hooks/useCorporateAuthorityDetailLogic";

// components
import DrawerHeader from "src/components/drawer/DrawerHeader";
import HeaderSkeleton from "src/components/skeletons/bank/detail-drawer/HeaderSkeleton";
import Container from "src/components/drawer/Container";
import AuthorityDetails from "./AuthorityDetails";
import AccountDetails from "./AccountDetails";
import AuthorityDetailsSkeleton from "src/components/skeletons/employees/corporate-authorization/AuthorityDetailsSkeleton";

const HeaderWithLoading = withLoading(DrawerHeader, HeaderSkeleton);

const AuthorityWithLoading = withLoading(
  AuthorityDetails,
  AuthorityDetailsSkeleton
);

const AccountWithLoading = withLoading(
  AccountDetails,
  AuthorityDetailsSkeleton
);

const CorporateAuthorityDetail = ({ id }) => {
  const { isLoading, authorization } = useCorporateAuthorityDetailLogic(id);

  return (
    <div>
      <HeaderWithLoading isLoading={isLoading} title={authorization.title} />
      <Container>
        <AuthorityWithLoading
          isLoading={isLoading}
          authorization={authorization}
        />
        <AccountWithLoading
          isLoading={isLoading}
          authorization={authorization}
        />
      </Container>
    </div>
  );
};

CorporateAuthorityDetail.propTypes = {
  id: PropTypes.any.isRequired,
};

export default CorporateAuthorityDetail;
