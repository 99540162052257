import { useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";

// hooks
import { useGetCorporateAuthorizationQuery } from "src/redux/corporateAuthorities/api";

// other
import { ERROR_TOAST_DURATION } from "src/constants";
import CorporateAuthorization from "src/models/organization/corporate-authorities/CorporateAuthorization";

const useCorporateAuthorityDetailLogic = (id) => {
  const params = useParams();

  const {
    isLoading,
    isError,
    error,
    data = {},
  } = useGetCorporateAuthorizationQuery(
    { employeeId: params.id, id },
    { skip: !id }
  );

  const authorization = new CorporateAuthorization({ ...data });

  // handle error feedback
  useEffect(() => {
    if (isError)
      toast.error(`Error on Fetching: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [isError, error]);

  return { isLoading, authorization };
};

export default useCorporateAuthorityDetailLogic;
