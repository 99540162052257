import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";

// hooks
import useSessionStorage from "src/hooks/useSessionStorage";

import Seo from "src/components/Seo";
import { paths } from "src/paths";

const Page = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isAdmin] = useSessionStorage("is_admin");
  const [isInstanceAdmin] = useSessionStorage("is_instance_admin");

  const href =
    !isAdmin && !isInstanceAdmin ? paths.home.index : paths.admin.index;

  return (
    <>
      <Seo title="Error: Server Error" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? "h1" : "h4"}>
            500: Internal Server Error
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button component={Link} href={href}>
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
