import { useEffect } from "react";
import toast from "react-hot-toast";

import { ERROR_TOAST_DURATION } from "src/constants";

import { useGetCorporateRolesQuery } from "../redux/api";

const useGetCorporateRoles = () => {
  const {
    data: corporateRoles = [],
    isError,
    error,
  } = useGetCorporateRolesQuery();

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Fetching: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  // const List = corporateRoles || [];
  // const Roles = List.map((corporateRoles) => ({
  //   value: employee.employeeNumber,
  //   label: `${employee.firstName} ${employee.lastName}  ( ${employee.employeeNumber} )`,
  // }));
  return {
    corporateRoles,
  };
};

export default useGetCorporateRoles;
