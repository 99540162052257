// hooks
import UpdateEmployeeFeature from "src/features/admin/employees/components/UpdateEmployee";
import usePageTitle from "src/hooks/usePageTitle";

// features

// other
import { pageTitles } from "src/pageTitles";

const UpdateEmployee = () => {
  usePageTitle({ title: pageTitles.admin.employees.UpdateEmployee });

  return <UpdateEmployeeFeature />;
};

export default UpdateEmployee;
