export const paths = {
  index: "/",
  home: {
    index: "/home",
  },
  dashboard: {
    index: "/dashboard",
  },
  people: {
    corporateManagement: {
      index: "/people/corporate-management",
    },
    boardOfDirectors: {
      index: "/people/board-of-directors",
    },
    companySecretaries: {
      index: "/people/company-secretaries",
    },
    auditors: {
      index: "/people/auditors",
    },
  },
  secretarial: {
    index: "/secretarial",
    companyFiles: {
      index: "/secretarial/company-files",
    },
    statutoryDocuments: {
      index: "/secretarial/statutory-documents",
      newShareholder: "/secretarial/statutory-documents/new-shareholder",
      issueShares: "/secretarial/statutory-documents/issue-shares",
      editShares: "/secretarial/statutory-documents/edit-shares",
    },
    boardResolutions: {
      index: "/secretarial/board-resolutions",
      create: "/secretarial/board-resolutions/create",
    },
  },
  banking: {
    accounts: {
      index: "/banking/accounts",
      create: "/banking/accounts/create",
    },
    facilities: {
      index: "/banking/facilities",
      create: "/banking/facilities/create",
    },
    creditCards: {
      index: "/banking/credit-cards",
      create: "/banking/credit-cards/create",
    },
  },
  leasing: {
    facilities: {
      index: "/leasing/facilities",
      create: "/leasing/facilities/create",
    },
  },
  workFlows: {
    requests: {
      index: "/work-flows/requests",
      default: "/work-flows/requests/default",
      miscellaneous: {
        divestmentOfCompany: {
          index: "/work-flows/requests/miscellaneous/divestment-of-company",
          create:
            "/work-flows/requests/miscellaneous/divestment-of-company/create",
        },
        companyAcquisition: {
          index: "/work-flows/requests/miscellaneous/company-acquisition",
          create:
            "/work-flows/requests/miscellaneous/company-acquisition/create",
        },
        dividendDeclaration: {
          index: "/work-flows/requests/miscellaneous/dividend-declaration",
          create:
            "/work-flows/requests/miscellaneous/dividend-declaration/create",
        },
        guarantees: {
          index: "/work-flows/requests/miscellaneous/guarantees",
          create: "/work-flows/requests/miscellaneous/guarantees/create",
        },
        badDeptWriteOff: {
          index: "/work-flows/requests/miscellaneous/bad-debt-write-off",
          create:
            "/work-flows/requests/miscellaneous/bad-debt-write-off/create",
        },
        bonds: {
          index: "/work-flows/requests/miscellaneous/bonds",
          create: "/work-flows/requests/miscellaneous/bonds/create",
        },
        irdLiaisonAuthorization: {
          index: "/work-flows/requests/miscellaneous/ird-liaison-authorization",
          create:
            "/work-flows/requests/miscellaneous/ird-liaison-authorization/create",
        },
        authorizedSignatories: {
          index: "/work-flows/requests/miscellaneous/authorized-signatories",
          create:
            "/work-flows/requests/miscellaneous/authorized-signatories/create",
        },
        annualReport: {
          index: "/work-flows/requests/miscellaneous/annual-report",
          create: "/work-flows/requests/miscellaneous/annual-report/create",
        },
        annualQuarterlyFinancialReport: {
          index:
            "/work-flows/requests/miscellaneous/annual-quarterly-financial-report",
          create:
            "/work-flows/requests/miscellaneous/annual-quarterly-financial-report/create",
        },
      },
      registrarOfCompanies: {
        companyNameChange: {
          index:
            "/work-flows/requests/registrar-of-companies/company-name-change",
          create:
            "/work-flows/requests/registrar-of-companies/company-name-change/create",
        },
        companySecretariesChange: {
          index:
            "/work-flows/requests/registrar-of-companies/company-secretaries-change",
          create:
            "/work-flows/requests/registrar-of-companies/company-secretaries-change/create",
        },
        companyAuditorsChange: {
          index:
            "/work-flows/requests/registrar-of-companies/company-auditors-change",
          create:
            "/work-flows/requests/registrar-of-companies/company-auditors-change/create",
        },
        appointmentOfDirector: {
          index:
            "/work-flows/requests/registrar-of-companies/appointment-of-director",
          create:
            "/work-flows/requests/registrar-of-companies/appointment-of-director/create",
        },
        removalOfDirector: {
          index:
            "/work-flows/requests/registrar-of-companies/removal-of-director",
          create:
            "/work-flows/requests/registrar-of-companies/removal-of-director/create",
        },
        changeOfRegisteredAddress: {
          index:
            "/work-flows/requests/registrar-of-companies/change-of-registered-address",
          create:
            "/work-flows/requests/registrar-of-companies/change-of-registered-address/create",
        },
        newCompanyIncorporation: {
          index:
            "/work-flows/requests/registrar-of-companies/new-company-incorporation",
          create:
            "/work-flows/requests/registrar-of-companies/new-company-incorporation/create",
        },
        changeOfArticles: {
          index:
            "/work-flows/requests/registrar-of-companies/change-of-articles",
          create:
            "/work-flows/requests/registrar-of-companies/change-of-articles/create",
        },
      },
      shares: {
        shareIssue: {
          index: "/work-flows/requests/shares/share-issue",
          create: "/work-flows/requests/shares/share-issue/create",
        },
        shareTransfer: {
          index: "/work-flows/requests/shares/share-transfer",
          create: "/work-flows/requests/shares/share-transfer/create",
        },
        shareAcquisition: {
          index: "/work-flows/requests/shares/share-acquisition",
          create: "/work-flows/requests/shares/share-acquisition/create",
        },
      },
      banking: {
        removeSignatory: {
          index: "/work-flows/requests/banking/remove-signatory",
          create: "/work-flows/requests/banking/remove-signatory/create",
        },
        addSignatory: {
          index: "/work-flows/requests/banking/add-signatory",
          create: "/work-flows/requests/banking/add-signatory/create",
        },
        activeDormantAccount: {
          index: "/work-flows/requests/banking/active-dormant-account",
          create: "/work-flows/requests/banking/active-dormant-account/create",
        },
        closeAccount: {
          index: "/work-flows/requests/banking/close-account",
          create: "/work-flows/requests/banking/close-account/create",
        },
        enableInternetBanking: {
          index: "/work-flows/requests/banking/enable-internet-banking",
          create: "/work-flows/requests/banking/enable-internet-banking/create",
        },
        enableMerchantAgreement: {
          index: "/work-flows/requests/banking/enable-merchant-agreement",
          create:
            "/work-flows/requests/banking/enable-merchant-agreement/create",
        },
        enableOnlineBanking: {
          index: "/work-flows/requests/banking/enable-online-banking",
          create: "/work-flows/requests/banking/enable-online-banking/create",
        },
        increseTransactionLimit: {
          index: "/work-flows/requests/banking/increse-transaction-limit",
          create:
            "/work-flows/requests/banking/increse-transaction-limit/create",
        },
        issueCreditCard: {
          index: "/work-flows/requests/banking/issue-credit-card",
          create: "/work-flows/requests/banking/issue-credit-card/create",
        },
        newAccountOpenning: {
          index: "/work-flows/requests/banking/new-account-openning",
          create: "/work-flows/requests/banking/new-account-openning/create",
        },
        newBankFacility: {
          index: "/work-flows/requests/banking/new-bank-facility",
          create: "/work-flows/requests/banking/new-bank-facility/create",
        },
        obtainEWindow: {
          index: "/work-flows/requests/banking/obtain-ewindow",
          create: "/work-flows/requests/banking/obtain-ewindow/create",
        },
        cancelCreditCard: {
          index: "/work-flows/requests/banking/cancel-credit-card",
          create: "/work-flows/requests/banking/cancel-credit-card/create",
        },
        increaseAuthLevel: {
          index: "/work-flows/requests/banking/increase-auth-level",
          create: "/work-flows/requests/banking/increase-auth-level/create",
        },
        increaseCreditCardLimit: {
          index: "/work-flows/requests/banking/increase-credit-card-limit",
          create:
            "/work-flows/requests/banking/increase-credit-card-limit/create",
        },
      },
      leasing: {
        newLease: {
          index: "/work-flows/requests/leasing/new-lease",
          create: "/work-flows/requests/leasing/new-lease/create",
        },
        renewalOfLease: {
          index: "/work-flows/requests/leasing/renewal-of-lease",
          create: "/work-flows/requests/leasing/renewal-of-lease/create",
        },
        renewalOfCancellation: {
          index: "/work-flows/requests/leasing/cancellation-of-lease",
          create: "/work-flows/requests/leasing/cancellation-of-lease/create",
        },
        assignmentOfLease: {
          index: "/work-flows/requests/leasing/assignment-of-lease",
          create: "/work-flows/requests/leasing/assignment-of-lease/create",
        },
        subLease: {
          index: "/work-flows/requests/leasing/sub-lease",
          create: "/work-flows/requests/leasing/sub-lease/create",
        },
      },
    },
    tasks: {
      index: "/work-flows/tasks",
    },
    taskdetail: {
      index: "/work-flows/tasks/id",
    },
  },
  organization: {
    documents: {
      index: "/organization/documents",
      docket: "/organization/documents/docket",
    },
  },
  admin: {
    index: "/admin",
    stakeholders: {
      index: "/admin/stakeholders",
      newStakeholder: "/admin/stakeholders/new",
    },
    documentCategory: {
      index: "/admin/document-category",
      newDocumentCategory: "admin/document-category/new",
    },
    documentTypes: {
      index: "/admin/document-type",
      newDocumentType: "admin/document-type/new",
    },
    companies: {
      index: "/admin/companies",
      newCompany: "/admin/companies/create",
    },
    users: {
      index: "/admin/user-management",
      newUser: "/admin/user-management/new",
    },
    sectors: {
      index: "/admin/sectors",
      newSector: "/admin/sectors/new",
    },
    geography: {
      index: "/admin/geography",
      newGeography: "/admin/geography/new",
    },
    currency: {
      index: "/admin/currency",
      newCurrency: "/admin/currency/new",
    },
    applicationAccessControl: {
      index: "/admin/access-control/application-access-control",
      newApplicationAccess:
        "/admin/access-control/application-access-control/create-access",
    },
    companyAccessControl: {
      index: "/admin/access-control/company-access-control",
      newCompanyAccess:
        "/admin/access-control/company-access-control/create-access",
    },
    workflowRequestTypes: {
      index: "/admin/workflowRequestTypes",
    },
    employee: {
      index: "/admin/employee",
      newEmployee: "/admin/employee/new",
      updateEmployee: "/admin/employee/id",
      viewEmployee: "/admin/employee/view/id",
    },
    authorities: {
      index: "/admin/authorities",
      newAuthority: "/admin/authorities/create",
    },
    departments: {
      index: "/admin/departments",
      newDepartment: "/admin/departments/create",
    },
    banks: {
      index: "/admin/banks",
      create: "/admin/banks/create",
    },
    leasing: {
      index: "/admin/lessors",
      create: "/admin/lessors/create",
    },
    corporateRoles: {
      index: "/admin/corporate-roles",
    },
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
