import { useFormik } from "formik";
import { corporateProfileValidationSchema } from "../create/validations";

const useCorporateProfileDetails = (employee, onSubmit) => {
  const formik = useFormik({
    initialValues: {
      name: employee?.corporateProfiles[0]?.name ?? "",
      corporateRoleId: employee?.corporateProfiles[0]?.corporateRole.id ?? null,
      userId: employee?.userId ?? 0
    },
    validationSchema: corporateProfileValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return {
    formik,
  };
};

export default useCorporateProfileDetails;
