import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";

// icons
import XCloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import Title from "./Title";

const Authority = ({
  id,
  title,
  authorityLevel,
  bankName,
  branchName,
  accountNumber,
  financeLimit,
  onClick,
  onDelete
}) => {
  const subheader = bankName + ", " + branchName;

  return (
    <Card
      sx={{ cursor: "pointer", minHeight: "188.84px" }}
    >
      <CardHeader
        title={<Title authorityLevel={authorityLevel} title={title} />}
        subheader={bankName && subheader}
        action={
          <Tooltip title="Remove" >
            <IconButton onClick={onDelete.bind(this, id)}>
              <SvgIcon fontSize="small">
                <XCloseIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        }
        sx={{ padding: "16px 24px", paddingBottom: "16px" }}
      />
      <Divider />
      <CardContent onClick={onClick.bind(this, id)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
          >
            <Typography variant="caption" color="text.secondary">
              {accountNumber && "Account"}
            </Typography>
            <Typography variant="subtitle2">{accountNumber}</Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
          >
            <Typography variant="caption" color="text.secondary">
              Finance Limit
            </Typography>
            <Typography variant="subtitle1">{financeLimit}</Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

Authority.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  authorityLevel: PropTypes.string.isRequired,
  bankName: PropTypes.string,
  branchName: PropTypes.string,
  accountNumber: PropTypes.string,
  financeLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onClick: PropTypes.func,
};

Authority.defaultProps = {
  onClick: () => { },
};

export default Authority;
