import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

// mui
import {
  Avatar,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";

// icons
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";

// hooks
import useRouter from "src/hooks/useRouter";
import { useGetEmployeeListQuery } from "../redux/api";
import { useDispatch } from "src/store";

// other
import getFullName from "src/helpers/getFullName";
import ColorUtility from "src/utils/ColorUtility";
import extractCapitals from "src/utils/extractCapitals";
import { openFormDialog } from "src/redux/slice";
import { paths } from "src/paths";
import { ERROR_TOAST_DURATION } from "src/constants";

const COLUMNS = [
  {
    field: "firstName",
    headerName: "Full Name",
    flex: 1,
    width: 200,
    valueGetter: getFullName,
    renderCell: (params) => (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Avatar sx={{ bgcolor: ColorUtility.getRandomColor(), color: "white" }}>
          {extractCapitals(params.row?.firstName)}
          {extractCapitals(params.row?.lastName)}
        </Avatar>
        <div>
          <Typography variant="subtitle2">{getFullName(params)}</Typography>
          <Typography color="text.secondary" variant="body2">
            {params.row.corporateEmail}
          </Typography>
        </div>
      </Stack>
    ),
  },
  {
    field: "employeeNumber",
    headerName: "Employee Number",
    width: 150,
  },
  {
    field: "nicNumber",
    headerName: "Nic Number",
    width: 100,
  },
  {
    field: "companyEmail",
    headerName: "Company Email",
    flex: 1,
    width: 110,
  },
  {
    field: "officeNumber",
    headerName: "Office Number",
    width: 120,
  },
  {
    field: "department",
    headerName: "Department",
    width: 110,
    valueGetter: (params) => params.value?.name,
    renderCell: (params) => params.row.department?.name,
  },
];

const useEmployees = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();

  const [selectedEmployee, setEmployee] = useState(null);

  const { isLoading, data = [], isError, error } = useGetEmployeeListQuery();

  useEffect(() => {
    if (isError) {
      toast.error(`Error on Employee Updating: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [dispatch, isError, error]);

  const EmployeesList = data;

  const deleteHandler = useCallback(
    (row, event) => {
      setEmployee(row);
      event.stopPropagation();
      dispatch(
        openFormDialog({
          type: "DELETE_EMPLOYEE",
          message: `Are you sure you want to remove  ${row.firstName} ${row.lastName} ?`,
        })
      );
    },
    [dispatch]
  );

  const viewHandler = (row) => {
    setEmployee(row);
    push(paths.admin.employee.viewEmployee.replace("id", row?.id));
  };

  const authorityClickHandler = useCallback(
    () =>
      push(
        `${paths.admin.employee.index}/${selectedEmployee.id}/manage-authorities`
      ),
    [push, selectedEmployee]
  );

  const columns = [
    ...COLUMNS,
    {
      field: "action",
      headerName: "actions",
      renderCell: (params) => (
        <Tooltip title="Remove">
          <IconButton
            color="error"
            onClick={deleteHandler.bind(this, params.row)}
          >
            <SvgIcon fontSize="small">
              <Trash02Icon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const title = `Total (${EmployeesList.length})`;

  return {
    columns,
    selectedEmployee,
    EmployeesList,
    title,
    isLoading,
    viewHandler,
    deleteHandler,
    authorityClickHandler,
  };
};

export default useEmployees;
