// mui
import { Button, Grid, Stack } from "@mui/material";

// helpers
import generateInputField from "src/helpers/generateInputField";

// utils
import generateUniqueKey from "src/utils/generateUniqueKeys";

// other
import { INPUT_TYPES } from "src/constants";
import useGetDocumentCategory from "../../document-category/hooks/useGetDocumentCategory";
import { PropTypes } from "prop-types";

const FORM_DETAILS = [
  {
    key: generateUniqueKey("name"),
    name: "name",
    label: "Name",
    required: true,
    type: INPUT_TYPES.TEXT,
  },
  {
    key: generateUniqueKey("documentCategory"),
    name: "documentCategoryId",
    label: "Document Category",
    required: true,
    type: INPUT_TYPES.SELECT,
  },
];

const DocumentTypeForm = ({ isLoading, type, formik }) => {
  const { selectOptions } = useGetDocumentCategory();
  const btnLabel = type ? 'Update' : 'Create';

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={0} mb={0} px={3}>
        {FORM_DETAILS.map((item) => (
          <Grid item key={item.key} xs={12}>
            {generateInputField(item, formik, selectOptions)}
          </Grid>
        ))}
      </Grid>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ py: 2, pr: 3 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />
        <div>
          <Button type="create" variant="contained" disabled={isLoading}>
            {!isLoading && btnLabel}
            {isLoading && "Please wait..."}
          </Button>
        </div>
      </Stack>
    </form>
  );
};

DocumentTypeForm.prototype = {
    formik: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    type: PropTypes.object,
}

export default DocumentTypeForm;
