import { useFormik } from "formik";
import { useEffect } from "react";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

// other
import { validationSchema } from "../validations";
import toast from "react-hot-toast";
import useGetEmployee from "./useGetEmployee";

const useUpdateEmployeeCorporateDetails = (employeeId) => {
  const { employee } = useGetEmployee(employeeId);
  const formik = useFormik({
    initialValues: {
      corporateRoleId: "",
      userId: "",
      name: "",
    },
    //alidationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      // update(values);
    },
  });

  //   useEffect(() => {
  //     if (isSuccess) {
  //       toast.success("Corporate Profile Update Successfully.", {
  //         duration: DEFAULT_TOAST_DURATION,
  //       });
  //       onSuccess();
  //     }
  //   }, [data, dispatch, isSuccess, onSuccess]);

  //   useEffect(() => {
  //     if (isError) {
  //       toast.error(`Error: ${error?.data?.detail}`, {
  //         duration: ERROR_TOAST_DURATION,
  //       });
  //     }
  //   }, [isError, error]);

  return {
    formik,
  };
};

export default useUpdateEmployeeCorporateDetails;
