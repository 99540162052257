import { cloneElement, useContext } from "react";
import PropTypes from "prop-types";

// context
import DropdownContext from "../../contexts/dropdown/DropdownContext";

const DropdownTrigger = (props) => {
  const { children } = props;
  const { onTriggerEnter, onTriggerLeave } = useContext(DropdownContext);

  return cloneElement(children, {
    onMouseEnter: (event) => {
      children.props.onMouseEnter?.(event);
      onTriggerEnter(event);
    },
    onMouseLeave: (event) => {
      children.props.onMouseLeave?.(event);
      onTriggerLeave(event);
    },
  });
};

DropdownTrigger.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DropdownTrigger;
