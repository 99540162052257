import { Skeleton } from "@mui/material";
import React from "react";

const DataTableSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={180}
      sx={{ borderRadius: 2, marginBottom: 2 }}
    />
  );
};

export default DataTableSkeleton;
