// mui
import { Button, Divider, Grid, Stack } from "@mui/material";

// helpers
import generateInputField from "src/helpers/generateInputField";

// utils
import generateUniqueKey from "src/utils/generateUniqueKeys";

// other
import { INPUT_TYPES } from "src/constants";
import useNewDepartment from "../hooks/useNewDepartment";

const FORM_DETAILS = [
  {
    key: generateUniqueKey("userName"),
    name: "name",
    label: "Department Name",
    required: true,
    type: INPUT_TYPES.TEXT,
  },
];

const NewDepartmentForm = () => {
  const { isLoading, formik } = useNewDepartment();

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={3} mb={2} pr={2} pl={2}>
        {FORM_DETAILS.map((item) => (
          <Grid item key={item.key} xs={12}>
            {generateInputField(item, formik)}
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />
        <div>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {!isLoading && "Submit"}
            {isLoading && "Please wait..."}
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default NewDepartmentForm;
