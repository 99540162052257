import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const useRouter = () => {
  const navigate = useNavigate();

  return useMemo(() => {
    return {
      back: () => navigate(-1),
      forward: () => navigate(1),
      refresh: () => navigate(0),
      push: (href, options) => navigate(href, { ...options }),
      replace: (href, options) => navigate(href, { replace: true }),
      prefetch: (href) => {},
    };
  }, [navigate]);
};

export default useRouter;
