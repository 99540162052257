import { lazy } from "react";
import { Outlet } from "react-router-dom";
import GuestGuard from "src/guards/GuestGuard";
import AuthLayout from "src/layouts/auth/ClassicLayout";

import Error404Page from "src/pages/404";
import Error405Page from "src/pages/405";
import { dashboardRoutes } from "./dashboard";
import { adminRoutes } from "./admin";

// Amplify
const LoginPage = lazy(() => import("src/pages/auth/Login"));

export const routes = [
  {
    element: (
      <AuthLayout>
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      </AuthLayout>
    ),
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  ...adminRoutes,
  ...dashboardRoutes,
  {
    path: "405",
    element: <Error405Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
