import Base from "../Base";

class ApplicationUser extends Base {
  constructor({
    id,
    email,
    emailConfirmed,
    firstName,
    lastName,
    phoneNumber,
    userName,
    accessibleCompanies,
  }) {
    super({ id });
    Object.assign(this, {
      email,
      emailConfirmed,
      firstName,
      lastName,
      phoneNumber,
      userName,
      accessibleCompanies,
    });
  }

  get fullName() {
    return this.firstName + " " + this.lastName;
  }
}

export default ApplicationUser;
