import { useEffect } from "react";
import { ERROR_TOAST_DURATION } from "src/constants";

import { useGetCompaniesQuery } from "src/redux/company/api";
import toast from "react-hot-toast";

const useGetCompanies = () => {
  const { data: companies = [], isError, error } = useGetCompaniesQuery();
  useEffect(() => {
    if (isError) {
      toast.error(`Error: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  const List = companies || [];
  const selectOptions = List.map((companies) => ({
    value: companies.id,
    label: `${companies.registeredName}`,
  }));

  return {
    selectOptions,
    List,
  };
};

export default useGetCompanies;
