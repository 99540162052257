import PropTypes from "prop-types";

// material
import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

// untitled
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";

// models
import DocketModel from "src/models/documents/Docket";

// hooks
import usePopover from "src/hooks/usePopover";
import useDocketLifeCycleActionsLogic from "src/hooks/dockets/useDocketLifeCycleActionsLogic";

// components
import { sx } from "../document/Document";
import DocketOptions from "../../DocketOptions";
import LastModified from "../LastModified";
import DocumentIcons from "../../DocumentIcons";
import ItemTitle from "../document/ItemTitle";

// other
import { paths } from "src/paths";
import { TOOLTIP_TEXT } from "src/constants";

const Docket = ({ docket }) => {
  const { anchorRef, open, handleClose, handleOpen } = usePopover();

  const { lockUnlockHandler, deleteHandler, viewHandler } =
    useDocketLifeCycleActionsLogic();

  const clickHandler = () =>
    viewHandler(`${paths.organization.documents.docket}/${docket.id}`);

  return (
    <>
      <TableRow key={docket.id} sx={sx}>
        <TableCell>
          <Stack
            sx={{
              cursor: "pointer",
            }}
            onClick={clickHandler}
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Box>
              <DocumentIcons
                isVersion={false}
                item={docket}
                extension="folder"
              />
            </Box>
            <ItemTitle item={docket} />
          </Stack>
        </TableCell>
        <TableCell align="right" />
        <TableCell align="right" />
        <TableCell align="right">
          <LastModified object={docket} />
        </TableCell>
        <TableCell align="right">
          <Tooltip title={TOOLTIP_TEXT.MORE_OPTIONS}>
            <IconButton onClick={handleOpen} ref={anchorRef}>
              <SvgIcon fontSize="small">
                <DotsVerticalIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <DocketOptions
        docket={docket}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        onLockUnlock={lockUnlockHandler}
        onRemove={deleteHandler}
      />
    </>
  );
};

Docket.propTypes = {
  docket: PropTypes.instanceOf(DocketModel),
};

export default Docket;
