import PropTypes from "prop-types";

// mui
import {
  Menu,
  MenuItem as MuiMenuItem,
  menuItemClasses,
  SvgIcon,
} from "@mui/material";

// untitled-ui
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";

// utils
import generateUniqueKey from "src/utils/generateUniqueKeys";

// constants
import { DOCUMENT_DOCKET_OPTIONS } from "./constants";

const DocketOptions = ({
  docket,
  anchorEl,
  open,
  onClose,
  onLockUnlock,
  onRemove,
}) => {
  const options = [
    {
      key: generateUniqueKey("lock_unlock"),
      icon: docket.lockUnlockIcon,
      label: docket.lockUnlockLabel,
      onClick: onLockUnlock.bind(null, docket),
    },
    {
      key: generateUniqueKey("remove"),
      icon: <Trash02Icon />,
      sx: { color: "error.main" },
      label: DOCUMENT_DOCKET_OPTIONS.REMOVE,
      onClick: onRemove.bind(null, docket),
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      sx={{
        [`& .${menuItemClasses.root}`]: {
          fontSize: 14,
          "& svg": {
            mr: 1,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {options.map((option) => (
        <MuiMenuItem
          key={option.key}
          sx={{ ...option.sx }}
          onClick={option.onClick}
        >
          <SvgIcon fontSize="small">{option.icon}</SvgIcon>
          {option.label}
        </MuiMenuItem>
      ))}
    </Menu>
  );
};

DocketOptions.propTypes = {
  docket: PropTypes.object,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DocketOptions.defaultProps = {
  open: false,
};

export default DocketOptions;
