import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { REMOVE_DIRECTOR } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const removeDirectorsRequestApi = createApi({
  reducerPath: "removeDirectorsRequestApi",
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query({
      query: (id) => `${REMOVE_DIRECTOR}/${id}`,
    }),
    createRequest: builder.mutation({
      query: (payload) => ({
        url: REMOVE_DIRECTOR,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
  }),
});

export const { useGetByIdQuery, useCreateRequestMutation } =
  removeDirectorsRequestApi;
