import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";

// hooks
import useFormDialog from "src/hooks/useFormDialog";
import {
  useDeleteAuthorizationMutation,
  useGetCorporateProfileQuery,
  useGetEmployeeCorporateAuthorizationsQuery,
  useGetEmployeeQuery,
  useUpdateCorporateProfileMutation,
} from "../../redux/api";
import { useLazyGetDocketQuery } from "src/redux/documents/api";
import { useDispatch } from "src/store";

//redux
import { closeDrawer, openDrawer } from "src/redux/slice";

// other
import { ERROR_TOAST_DURATION, DEFAULT_TOAST_DURATION } from "src/constants";
import Docket from "src/models/documents/Docket";
import {
  ASSIGN_NEW_AUTHORITY,
  UPLOAD_EMPLOYEE_PERSONAL_DOCUMENTS,
} from "../../common/constants";
import CorporateAuthorization from "src/models/organization/corporate-authorities/CorporateAuthorization";
import convertToModel from "src/helpers/convertToModel";

const tabs = [
  {
    label: "Employee Details",
    value: "emp_details",
  },
  {
    label: "Corporate Profile Details",
    value: "corp_roles",
  },
  {
    label: "Personal Documents",
    value: "personal_documents",
  },
  {
    label: "Authorities",
    value: "authorities",
  },
];

const useDetailsLogic = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    data: employee,
    isLoading,
    isError: isEmployeeFetchError,
    error: employeeFetchError,
  } = useGetEmployeeQuery(params.id);

  const {
    data: corporateProfiles = [],
    isLoading: isProfilesLoading,
    isError: isProfilesFetchError,
    error: profilesFetchError,
  } = useGetCorporateProfileQuery(params.id);

  const [
    deleteAuthorization,
    {
      isLoading: isRemoving,
      isSuccess: isRemoved,
      isError: isRemoveError,
      error: removeError,
      reset: resetRemoveState,
    },
  ] = useDeleteAuthorizationMutation();

  const [
    fetchDocket,
    {
      isLoading: isDocketLoading,
      data: _docket = {},
      isError: isDocketFetchError,
      error: docketFetchError,
    },
  ] = useLazyGetDocketQuery();

  const { isLoading: isAuthorizationLoading, data: _authorizations = [] } =
    useGetEmployeeCorporateAuthorizationsQuery(params.id);

  const [updateCorporateProfile, { isLoading: isUpdating, isError, isSuccess, error }] = useUpdateCorporateProfileMutation()

  const { data: formDetails, openFormDialogHandler } = useFormDialog(
    UPLOAD_EMPLOYEE_PERSONAL_DOCUMENTS
  );

  const { openFormDialogHandler: openNewAuthorityHandler } =
    useFormDialog(ASSIGN_NEW_AUTHORITY);

  const [selectedTab, setTab] = useState("emp_details");

  const docket = new Docket({ ..._docket });

  const authorizations = convertToModel(
    _authorizations,
    CorporateAuthorization
  );

  // fetch employee documents
  useEffect(() => {
    if (employee)
      fetchDocket({
        id: employee.personalDocumentsFolderId,
        fetchDocuments: true,
      });
  }, [employee, fetchDocket]);

  // handle employee fetch error
  useEffect(() => {
    if (isEmployeeFetchError)
      toast.error(`Error on Fetching: ${employeeFetchError?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [isEmployeeFetchError, employeeFetchError]);

  // handle employee personal documents fetch error
  useEffect(() => {
    if (isDocketFetchError)
      toast.error(`Error on Fetching: ${docketFetchError?.data?.detail}.`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [isDocketFetchError, docketFetchError]);

  // handle employee corporate profile fetch error
  useEffect(() => {
    if (isProfilesFetchError)
      toast.error(`Error on Fetching: ${profilesFetchError?.data?.detail}.`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [isProfilesFetchError, profilesFetchError]);

  //Handle Corporate Profile details Update Success 
  useEffect(() => {
    if (isSuccess)
      toast.success("Corporate Profile Details Updated Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
  }, [isSuccess]);

  //Handle Corporate Profile details Update Failure
  useEffect(() => {
    if (isError) {
      toast.error(`Error on Update: ${error?.data?.detail}.`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  // handle employee authorization delete

  const deleteHandler = (id) => {
    deleteAuthorization({ id, employeeId: params.id });
  }

  const corporateProfileSubmitHandler = (values) => updateCorporateProfile({ updateCorporateRoleDto: { employeeId: employee.id, ...values } })

  const tabChangeHandler = (event, newValue) => setTab(newValue);

  const viewAuthorityDetailHandler = (id) => dispatch(openDrawer({ id }));

  const closeDrawerHandler = () => dispatch(closeDrawer());

  return {
    isLoading,
    isDocketLoading,
    isAuthorizationLoading,
    isProfilesLoading,
    tabs,
    selectedTab,
    employee,
    docket,
    authorizations,
    corporateProfiles,
    formDetails,
    openFormDialogHandler,
    openNewAuthorityHandler,
    isUpdating,
    tabChangeHandler,
    viewAuthorityDetailHandler,
    closeDrawerHandler,
    corporateProfileSubmitHandler,
    deleteHandler
  };
};

export default useDetailsLogic;
