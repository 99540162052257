import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ACCOUNTS,
  ACCOUNT_CARDS,
  BANKS,
  BANK_FACILITIES,
  BRANCH,
  CREDIT_CARD,
  CREDIT_CARDS,
} from "src/helpers/url";
import { HTTP_METHODS } from "src/constants";
import { baseQuery } from "../apiService";

export const TAGS = {
  BANKS: "BANKS",
  FACILITIES: "FACILITIES",
  FACILITY: "FACILITY",
  CREDIT_CARDS: "CREDIT_CARDS",
  CREDIT_CARD: "CREDIT_CARD",
  ACCOUNTS: "ACCOUNTS",
  ACCOUNT: "ACCOUNT",
};

export const bankingApi = createApi({
  reducerPath: "bankingApi",
  baseQuery,
  tagTypes: [
    TAGS.BANKS,
    TAGS.FACILITY,
    TAGS.ACCOUNTS,
    TAGS.CREDIT_CARD,
    TAGS.CREDIT_CARDS,
    TAGS.FACILITIES,
    TAGS.ACCOUNT,
  ],
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: () => BANKS,
      providesTags: [TAGS.BANKS],
    }),
    getBank: builder.query({
      query: (id) => `${BANKS}/${id}`,
    }),
    getBankFacilities: builder.query({
      query: () => BANK_FACILITIES,
      providesTags: [TAGS.FACILITIES],
    }),
    getBankFacility: builder.query({
      query: (id) => `${BANK_FACILITIES}/${id}`,
      providesTags: [TAGS.FACILITY],
    }),
    getCreditCards: builder.query({
      query: () => CREDIT_CARDS,
      providesTags: [TAGS.CREDIT_CARDS],
    }),
    getCreditCard: builder.query({
      query: (id) => `${CREDIT_CARDS}/${id}`,
      providesTags: [TAGS.CREDIT_CARD],
    }),
    getCreditCardsByBankId: builder.query({
      query: ({ id, IsClosed = true }) => ({
        url: `${CREDIT_CARD}/${BRANCH}/${id}/${ACCOUNT_CARDS}`,
        params: {
          IsClosed: IsClosed,
        }
      }),
      providesTags: [TAGS.CREDIT_CARD],
    }),
    createBank: builder.mutation({
      query: (payload) => ({
        url: BANKS,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    createBankFacility: builder.mutation({
      query: (payload) => ({
        url: BANK_FACILITIES,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    createCreditCard: builder.mutation({
      query: (payload) => ({
        url: CREDIT_CARDS,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.CREDIT_CARDS],
    }),

    updateDeactivateCreditCard: builder.mutation({
      query: (payload) => (
        {
          url: `${CREDIT_CARDS}/deactivate/${payload.id}`,
          method: HTTP_METHODS.PUT,
          body: payload,
        }),
    }),

    updateBank: builder.mutation({
      query: (payload) => ({
        url: `${BANKS}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
    }),
    updateBankFacility: builder.mutation({
      query: (payload) => ({
        url: `${BANK_FACILITIES}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.FACILITY],
    }),
    updateCreditCard: builder.mutation({
      query: (payload) => ({
        url: `${CREDIT_CARDS}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.CREDIT_CARD],
    }),
    deleteBank: builder.mutation({
      query: (id) => ({
        url: `${BANKS}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.BANKS],
    }),
    deleteBankFacility: builder.mutation({
      query: (id) => ({
        url: `${BANK_FACILITIES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.FACILITIES],
    }),
    createAccount: builder.mutation({
      query: (payload) => ({
        url: ACCOUNTS,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.ACCOUNTS],
    }),
    getAllAccounts: builder.query({
      query: () => ACCOUNTS,
      providesTags: [TAGS.ACCOUNTS],
    }),
    getAccountById: builder.query({
      query: (id) => `${ACCOUNTS}/${id}`,
      providesTags: [TAGS.ACCOUNT],
    }),
    getCorporateAuthoritiesByAccountId: builder.query({
      query: (accountId) => `${ACCOUNTS}/${accountId}/CorporateAuthorizations`,
      providesTags: [TAGS.ACCOUNTS],
    }),
    getCorporateAuthoritiesByAuthorityType: builder.query({
      query: ({ accountId, authorityTypeId, fetchAuthorities }) => ({
        url: `${ACCOUNTS}/${accountId}/CorporateAuthorizations/authorityType/${authorityTypeId}`,
        params: { fetchAuthorities },
      }),
      providesTags: [TAGS.ACCOUNTS],
    }),
    updateAccount: builder.mutation({
      query: (payload) => ({
        url: `${ACCOUNTS}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.ACCOUNT],
    }),
  }),
});

export const {
  useGetBanksQuery,
  useGetBankQuery,
  useLazyGetBankQuery,
  useGetBankFacilitiesQuery,
  useGetBankFacilityQuery,
  useGetCreditCardsQuery,
  useLazyGetBankFacilityQuery,
  useLazyGetCreditCardQuery,
  useGetCreditCardQuery,
  useCreateBankMutation,
  useCreateBankFacilityMutation,
  useCreateCreditCardMutation,
  useUpdateBankMutation,
  useUpdateBankFacilityMutation,
  useUpdateCreditCardMutation,
  useDeleteBankMutation,
  useDeleteBankFacilityMutation,
  useCreateAccountMutation,
  useGetAllAccountsQuery,
  useLazyGetAllAccountsQuery,
  useGetAccountByIdQuery,
  useLazyGetAccountByIdQuery,
  useGetCorporateAuthoritiesByAccountIdQuery,
  useLazyGetCorporateAuthoritiesByAccountIdQuery,
  useGetCorporateAuthoritiesByAuthorityTypeQuery,
  useLazyGetCorporateAuthoritiesByAuthorityTypeQuery,
  useUpdateAccountMutation,
  useGetCreditCardsByBankIdQuery,
  useUpdateDeactivateCreditCardMutation,
} = bankingApi;
