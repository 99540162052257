import PropTypes from "prop-types";

// untitled-ui
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";

// mui
import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

// hooks
import { useSelector } from "src/store";

// components
import { AccountButton } from "../account-button";
// import NotificationsButton from "../notifications-button";
import SearchButton from "../search-button";

import { pageTitleSelector } from "src/redux/selectors";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

const TopNav = ({ onMobileNavOpen }) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const pageTitle = useSelector(pageTitleSelector);

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography variant="h5">{pageTitle}</Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <SearchButton />
          {/* <NotificationsButton /> */}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopNav;
