export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: [
    `api://${process.env.REACT_APP_API_CLIENT_ID}/XTrack.Common`,
    "User.Read",
  ],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
  scopes: ["user.read"],
};
