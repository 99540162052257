import { useEffect, useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";

import { useDispatch } from "src/store";
import {
  useGetDocumentTypesQuery,
  useUploadMultipleMutation,
} from "src/redux/documents/api";

import { closeFormDialog, openFormDialog } from "src/redux/slice";

import generateOptions from "src/utils/generateOptions";
import generateInputField from "src/helpers/generateInputField";
import { newDocumentSchema } from "src/validations/document";
import { DIALOG_TYPE } from "src/components/docket/constants";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useNewDocUploadLogic = ({ isDocketOption, docketId, files } = {}) => {
  const dispatch = useDispatch();
  const { data: documentTypes } = useGetDocumentTypesQuery();

  const [uploadDocuments, { isError, error, isSuccess, isLoading }] =
    useUploadMultipleMutation();

  const [renameDocumentName, setRenameDocumentName] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      documentTypeId: null,
    },
    validationSchema: newDocumentSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("docketId", docketId);
      formData.append("fileList[0].document", files[0]);
      formData.append(
        "fileList[0].documentTypeId",
        values.documentTypeId.value
      );

      // rename file name
      if (renameDocumentName) {
        formData.append("fileList[0].name", formik.values.name);
      } else {
        const { name } = files[0];
        formData.append("fileList[0].name", name);
      }

      uploadDocuments({ docketId, formData });
    },
  });

  useEffect(() => {
    if (isError)
      toast.error(`Error on Uploading: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
  }, [error, isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Document Uploaded successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch(closeFormDialog());
    }
  }, [dispatch, isSuccess]);

  const newClickHandler = () => {
    dispatch(openFormDialog({ type: DIALOG_TYPE.NEW_DOCUMENT }));
  };

  const handleRenameDocumentNameChange = (event) => {
    setRenameDocumentName(event.target.checked);
  };

  const renderInputFields = (item) => {
    let options = [];

    if (item.show && !item.show(isDocketOption)) {
      return null;
    }

    if (item.name === "documentTypeId") {
      options = generateOptions(documentTypes, "id", "name");
    }

    return generateInputField(item, formik, options);
  };

  return {
    isLoading,
    formik,
    renameDocumentName,
    newClickHandler,
    renderInputFields,
    handleRenameDocumentNameChange,
  };
};

export default useNewDocUploadLogic;
