import { createApi } from "@reduxjs/toolkit/dist/query/react";
import getCurrentUserId from "src/helpers/getCurrentUserId";
import {USER_REQUESTS, USER_REQUESTS_LIST, DASHBOARD, WORKFLOW_REQUESTS} from "src/helpers/url";
import { baseQuery } from "../apiService";

export const TAGS = {
  USER_REQUESTS: "USER_REQUESTS",
  USER_REQUESTS_LIST: "USER_REQUESTS_LIST",
  WORKFLOW_REQUESTS: "WORKFLOW_REQUESTS",
};

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery,
  tagTypes: [TAGS.USER_REQUESTS, TAGS.USER_REQUESTS_LIST],
  endpoints: (builder) => ({
    getUserRequestsById: builder.query({
      query: (id) => `${DASHBOARD}/${USER_REQUESTS}/${getCurrentUserId()}`,
      providesTags: [TAGS.USER_REQUESTS],
    }),
    getUserRequestsListById: builder.query({
      query: (id) => `${DASHBOARD}/${USER_REQUESTS_LIST}/${getCurrentUserId()}`,
      providesTags: [TAGS.USER_REQUESTS_LIST],
    }),
    getGlobalRequestSummary: builder.query({
      query: () => `${DASHBOARD}/${WORKFLOW_REQUESTS}/global-summary`,
      providesTags: [TAGS.WORKFLOW_REQUESTS],
    }),
    getLast14DaysRequestSummary: builder.query({
      query: () => `${DASHBOARD}/${WORKFLOW_REQUESTS}/last-14-days-summary`,
      providesTags: [TAGS.WORKFLOW_REQUESTS],
    }),
  }),
});

export const {
  useGetUserRequestsByIdQuery,
  useGetUserRequestsListByIdQuery,
  useGetGlobalRequestSummaryQuery,
  useGetLast14DaysRequestSummaryQuery,
} =
    dashboardApi;