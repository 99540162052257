// mui
import {Drawer, Stack, backdropClasses, Tooltip, IconButton, Box} from "@mui/material";

// hoc
import withLoading from "src/hocs/withLoading";
import withNoData from "src/hocs/withNoData";

// hooks
import useDetailsLogic from "./hooks/useDetailsLogic";
import { useSelector } from "src/store";

// components
import PageContainer from "src/layouts/page";
import Tabs from "src/components/Tabs";
import CorporateRoles from "../../user-management/detail/components/corporate-roles";
import UpdateEmployee from "../components/UpdateEmployee";
import Header from "./components/Header";
import DocumentList from "src/components/docket/table";
import DocumentListSkeleton from "src/components/skeletons/docket/DocumentListSkeleton";
import FormModal from "src/components/modals/FormModal";
import UploadForm from "src/components/docket/UploadForm";
import DocUploadForm from "src/components/docket/DocUploadForm";
import FloatingButton from "src/components/FloatingButton";
import AuthoritiesSkeleton from "src/components/skeletons/employees/authorities";
import Authorities from "../components/authorities";
import NoAuthorities from "./components/NoAuthorities";
import EmployeeDetailSkeleton from "src/components/skeletons/employees/detail/EmployeeDetailSkeleton";
import HeaderSkeleton from "src/components/skeletons/employees/detail/HeaderSkeleton";
import AssignNewAuthorityForm from "./components/AssignNewAuthorityForm";
import CorporateAuthorityDetail from "./components/corporate-authority-detail";
import CorporateProfileDetails from "../components/CorporateProfileDetails";

// redux
import { drawerSelector } from "src/redux/selectors";

// other
import { DIALOG_TYPE } from "src/components/docket/constants";
import TabsSkeleton from "src/components/skeletons/employees/detail/TabsSkeleton";
import CorporateRolesSkeleton from "src/components/skeletons/employees/detail/CorporateRolesSkeleton";
import {
  ASSIGN_NEW_AUTHORITY,
  UPLOAD_EMPLOYEE_PERSONAL_DOCUMENTS,
} from "../common/constants";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const emptyAuthorizationMessage = "No Corporate Authorities Assigned.";

const AuthoritiesWithNoData = withNoData(Authorities, NoAuthorities);

const AuthoritiesWithLoading = withLoading(
  AuthoritiesWithNoData,
  AuthoritiesSkeleton
);

const DocumentsWithNoData = withNoData(DocumentList);

const DocumentListWithLoading = withLoading(
  DocumentsWithNoData,
  DocumentListSkeleton
);

const UpdateEmployeeWithLoading = withLoading(
  UpdateEmployee,
  EmployeeDetailSkeleton
);

const HeaderWithLoading = withLoading(Header, HeaderSkeleton);

const TabsWithLoading = withLoading(Tabs, TabsSkeleton);

const CorporateRolesWithLoading = withLoading(
  CorporateRoles,
  CorporateRolesSkeleton
);

const EmployeeDetailFeature = () => {
  const { open, data: drawerData } = useSelector(drawerSelector);

  const {
    formDetails,
    employee,
    tabs,
    selectedTab,
    docket,
    authorizations,
    corporateProfiles,
    isAuthorizationLoading,
    isLoading,
    isProfilesLoading,
    isDocketLoading,
    openFormDialogHandler,
    openNewAuthorityHandler,
    isUpdating,
    tabChangeHandler,
    viewAuthorityDetailHandler,
    closeDrawerHandler,
    corporateProfileSubmitHandler,
    deleteHandler
  } = useDetailsLogic();

  return (
    <>
      <PageContainer spacing={4}>
        <Stack spacing={4}>
          <HeaderWithLoading
            isLoading={isLoading}
            firstName={employee?.firstName}
            lastName={employee?.lastName}
          />
          <div>
            <TabsWithLoading
              isLoading={isLoading}
              tabs={tabs}
              current={selectedTab}
              onTabChange={tabChangeHandler}
            />
          </div>
        </Stack>

        {selectedTab === "emp_details" && (
          <UpdateEmployeeWithLoading isLoading={isLoading} />
        )}

        {selectedTab === "corp_roles" && (
          <CorporateProfileDetails onSubmit={corporateProfileSubmitHandler} isUpdating={isUpdating} employee={employee} />
        )}

        {selectedTab === "personal_documents" && (
          <Stack>
            <DocumentListWithLoading
              isLoading={isDocketLoading}
              isMore={false}
              docketId={employee?.personalDocumentsFolderId}
              items={docket?.documents}
              isEmpty={docket?.documents.length === 0}
              message="No Personal Documents Available."
            />
            <Box
                sx={{
                  position: "fixed",
                  bottom: 32,
                  right: 32,
                  zIndex: 1100,
                }}
            >
              <Tooltip title="Upload New Documents" placement="left">
                  <span>
                    <IconButton
                        sx={{
                          backgroundColor: "primary.main",
                          borderRadius: "50%",
                          color: "primary.contrastText",
                          p: "10px",
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                        onClick={openFormDialogHandler}
                        color="primary"
                    >
                      <UploadFileIcon />
                    </IconButton>
                  </span>
              </Tooltip>
            </Box>
            {/*<FloatingButton*/}
            {/*  title="Upload New Document"*/}
            {/*  onClick={openFormDialogHandler}*/}
            {/*/>*/}
          </Stack>
        )}
        {selectedTab === "authorities" && (
          <Stack>
            <AuthoritiesWithLoading
              isEmpty={authorizations.length === 0}
              message={emptyAuthorizationMessage}
              isLoading={isAuthorizationLoading}
              authorizations={authorizations}
              onAdd={openNewAuthorityHandler}
              onClick={viewAuthorityDetailHandler}
              onDelete={deleteHandler}
            />
          </Stack>
        )}

        {formDetails?.type === DIALOG_TYPE.UPLOAD && (
          <FormModal title="Upload Document">
            <UploadForm />
          </FormModal>
        )}

        {formDetails?.type === ASSIGN_NEW_AUTHORITY && (
          <FormModal title="New Authority">
            <AssignNewAuthorityForm />
          </FormModal>
        )}
      </PageContainer>

      {formDetails?.type === UPLOAD_EMPLOYEE_PERSONAL_DOCUMENTS && (
        <FormModal title="Upload New Personal Document">
          <DocUploadForm docketId={employee?.personalDocumentsFolderId} />
        </FormModal>
      )}

      <Drawer
        open={open}
        anchor="right"
        ModalProps={{
          sx: {
            [`& .${backdropClasses.root}`]: {
              background: "transparent !important",
            },
          },
        }}
        PaperProps={{
          sx: {
            maxWidth: "100%",
            width: "42%",
          },
        }}
        onClose={closeDrawerHandler}
      >
        <CorporateAuthorityDetail {...drawerData} />
      </Drawer>
    </>
  );
};

export default EmployeeDetailFeature;
