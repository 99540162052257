import { createApi } from "@reduxjs/toolkit/query/react";
import { APPLICATION_ACCESS, COMPANY_ACCESS } from "src/helpers/url";
import { HTTP_METHODS } from "src/constants";
import { baseQuery } from "../apiService";

export const TAGS = {
  APPLICATION_ACCESS: "APPLICATION_ACCESS",
  COMPANY_ACCESS: "COMPANY_ACCESS",
};

export const accessControlsApi = createApi({
  reducerPath: "accessControlsApi",
  baseQuery,
  tagTypes: [TAGS.APPLICATION_ACCESS, TAGS.COMPANY_ACCESS],
  endpoints: (builder) => ({
    getAllApplicationAccess: builder.query({
      query: () => APPLICATION_ACCESS,
      providesTags: [TAGS.APPLICATION_ACCESS],
    }),
    getApplicationAccess: builder.query({
      query: () => APPLICATION_ACCESS,
      providesTags: [TAGS.APPLICATION_ACCESS],
    }),
    createApplicationAccess: builder.mutation({
      query: (payload) => ({
        url: APPLICATION_ACCESS,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.APPLICATION_ACCESS],
    }),
    updateApplicationAccess: builder.mutation({
      query: (payload) => ({
        url: `${APPLICATION_ACCESS}/${payload.id}`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
      invalidatesTags: [TAGS.APPLICATION_ACCESS],
    }),
    deleteApplicationAccess: builder.mutation({
      query: (id) => ({
        url: `${APPLICATION_ACCESS}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.APPLICATION_ACCESS],
    }),
    getAllCompanyAccess: builder.query({
      query: () => COMPANY_ACCESS,
      providesTags: [TAGS.COMPANY_ACCESS],
    }),
    getCompanyAccess: builder.query({
      query: () => COMPANY_ACCESS,
      providesTags: [TAGS.COMPANY_ACCESS],
    }),
    createCompanyAccess: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_ACCESS}/${payload.companyId}/${payload.userName}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.COMPANY_ACCESS],
    }),
    updateCompanyAccess: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_ACCESS}/${payload.companyId}/${payload.userName}`,
        method: HTTP_METHODS.PATCH,
        body: payload,
      }),
      invalidatesTags: [TAGS.COMPANY_ACCESS],
    }),
    deleteCompanyAccess: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_ACCESS}/${payload.companyId}/${payload.userName}`,
        method: HTTP_METHODS.DELETE,
        body: payload,
      }),
      invalidatesTags: [TAGS.COMPANY_ACCESS],
    }),
  }),
});

export const {
  useGetAllApplicationAccessQuery,
  useGetApplicationAccessQuery,
  useCreateApplicationAccessMutation,
  useUpdateApplicationAccessMutation,
  useDeleteApplicationAccessMutation,

  useGetAllCompanyAccessQuery,
  useGetCompanyAccessQuery,
  useCreateCompanyAccessMutation,
  useUpdateCompanyAccessMutation,
  useDeleteCompanyAccessMutation,
} = accessControlsApi;
