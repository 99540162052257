import PropTypes from "prop-types";

// mui
import Typography from "@mui/material/Typography";

// model
import Docket from "src/models/documents/Docket";

const LastModified = ({ object, isVersion }) => {
  const LastModified =
    object instanceof Docket
      ? object.formattedUpdatedAt
      : object.getLastModified(isVersion);

  return (
    <>
      <Typography noWrap variant="subtitle2">
        Last Modified
      </Typography>
      <Typography color="text.secondary" noWrap variant="body2">
        {LastModified}
      </Typography>
    </>
  );
};

LastModified.propTypes = {
  object: PropTypes.object,
  isVersion: PropTypes.bool,
};

export default LastModified;
