import PropTypes from "prop-types";

// mui
import Typography from "@mui/material/Typography";

// model
import Document from "src/models/documents/Document";

const ModifiedBy = ({ document }) => {
  return (
    <>
      <Typography noWrap variant="subtitle2">
        Modified By
      </Typography>
      <Typography color="text.secondary" noWrap variant="body2">
        {document.updatedBy}
      </Typography>
    </>
  );
};

ModifiedBy.propTypes = {
  document: PropTypes.instanceOf(Document),
};

export default ModifiedBy;
