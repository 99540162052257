import { useDispatch, useSelector } from "src/store";

import { formDialogSelector } from "src/redux/selectors";
import { openFormDialog, closeFormDialog } from "src/redux/slice";
import { useCallback } from "react";

/**
 * custom hook to manage the state and actions for opening a form dialog.
 *
 * @param {string} type - Type of the form dialog to determine its behavior or template (example: UPLOAD).
 * @returns {Object} - Returns an object containing the open status, data, and a handler to open the dialog.
 */
const useFormDialog = (type) => {
  const dispatch = useDispatch();

  const { data, open } = useSelector(formDialogSelector);

  /**
   * handler to open the form dialog and populate it with data.
   *
   * @param {Object} [data={}] - Optional data object to populate the form dialog.
   */
  const openFormDialogHandler = useCallback(
    (event, data = {}) => dispatch(openFormDialog({ type, ...data })),
    [dispatch, type]
  );

  const closeFormDialogHandler = () => dispatch(closeFormDialog());

  return { open, data, openFormDialogHandler, closeFormDialogHandler };
};

export default useFormDialog;
