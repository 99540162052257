import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, Container, Stack } from "@mui/material";
import Seo from "src/components/Seo";
import { useSelector } from "src/store";
import { pageTitleSelector } from "src/redux/selectors";

const PageContainer = ({ title, options, main, spacing = 4, children }) => {
  const pageTitles = useSelector(pageTitleSelector);

  return (
    <>
      <Seo title={title || pageTitles} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 1,
          pb: 12,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={spacing}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Stack alignItems="center" direction="row" spacing={1}>
                  {options}
                </Stack>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={3}>
                {main}
              </Stack>
            </Stack>
            {children}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

PageContainer.propTypes = {
  title: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  main: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  spacing: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageContainer;
