import React from "react";
import PropTypes from "prop-types";

// mui
import { IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";

// icons
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import XIcon from "@untitled-ui/icons-react/build/esm/X";

const DrawerHeader = ({ title, onEdit, onClose }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    spacing={2}
    sx={{ p: 2 }}
  >
    <Typography variant="h6">{title}</Typography>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
      spacing={1}
    >
      {onEdit && (
        <Tooltip title="Edit">
          <IconButton onClick={onEdit}>
            <SvgIcon fontSize="small">
              <Edit02Icon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Close">
        <IconButton onClick={onClose}>
          <SvgIcon fontSize="small">
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Stack>
  </Stack>
);

DrawerHeader.propTypes = {
  title: PropTypes.string,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DrawerHeader;
