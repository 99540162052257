import { useContext } from "react";

// mui
import { Avatar, Box, ButtonBase, SvgIcon } from "@mui/material";
import User01 from "@untitled-ui/icons-react/build/esm/User01";

// context
import AuthContext from "src/contexts/auth/AuthContext";

// hooks
import usePopover from "src/hooks/usePopover";

// components
import AccountPopover from "./AccountPopover";

export const AccountButton = () => {
  const popover = usePopover();
  const { userProfilePicURL } = useContext(AuthContext);

  const profileUrl =
    userProfilePicURL && window.URL.createObjectURL(userProfilePicURL);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        {profileUrl ? (
          <Avatar
            sx={{
              height: 32,
              width: 32,
            }}
            src={profileUrl}
          />
        ) : (
          <Avatar
            sx={{
              height: 32,
              width: 32,
            }}
          >
            <SvgIcon>
              <User01 />
            </SvgIcon>
          </Avatar>
        )}
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
