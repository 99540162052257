import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES } from "src/helpers/url";

import getCurrentTenant from "src/helpers/getCurrentTenant";
import { HTTP_METHODS } from "src/constants";
import { baseQuery } from "src/redux/apiService";

const TAGS = {
  SECRETARIES: "SECRETARIES",
};

export const companySecretariesApi = createApi({
  reducerPath: "companySecretariesApi",
  baseQuery,
  tagTypes: [TAGS.SECRETARIES],
  endpoints: (builder) => ({
    getCompanySecretaries: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/Secretaries`,
      providesTags: [TAGS.SECRETARIES],
    }),
    getCompanyEmployees: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/Employees`,
    }),

    getCompanyEmployee: builder.query({
      query: (employeeId) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/Employees/${employeeId}`,
        method: HTTP_METHODS.GET,
      }),
    }),
    createSecretary: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/Secretaries`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [TAGS.SECRETARIES],
    }),
    updateSecretary: builder.mutation({
        query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/Secretaries/${payload.id}`,
        method: "PUT",
        body: payload,
    }),
    invalidatesTags: [TAGS.SECRETARIES],
    }),
  }),
});

export const {
  useGetCompanySecretariesQuery,
  useCreateSecretaryMutation,
  useGetCompanyEmployeesQuery,
  useGetCompanyEmployeeQuery,
  useUpdateSecretaryMutation,
} = companySecretariesApi;
