import { createSlice } from "@reduxjs/toolkit";

import { ALERT_TYPES } from "src/constants";

const initialState = {
  formDialog: {
    open: false,
    data: null,
  },
  alertDialog: {
    open: false,
    title: "",
    message: "",
    type: ALERT_TYPES.SUCCESS,
  },
  drawer: {
    open: false,
    data: null,
  },
  loading: false, // global loading
  pageTitle: "",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openFormDialog: (state, action) => {
      state.formDialog.open = true;
      state.formDialog.data = action.payload;
    },
    closeFormDialog: (state) => {
      state.formDialog = initialState.formDialog;
    },
    showAlert: (state, action) => {
      state.alertDialog.open = true;
      state.alertDialog.title = action.payload?.title || "Job Done";
      state.alertDialog.message = action.payload?.message || "";
      state.alertDialog.type = action.payload?.type || ALERT_TYPES.SUCCESS;
    },
    closeAlert: (state) => {
      state.alertDialog = initialState.alertDialog;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    openDrawer: (state, action) => {
      state.drawer.open = true;
      state.drawer.data = action.payload;
    },
    updateDrawerData: (state, action) => {
      state.drawer.data = action.payload;
    },
    closeDrawer: (state) => {
      state.drawer = initialState.drawer;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

const { actions, reducer } = uiSlice;

export const {
  closeFormDialog,
  openFormDialog,
  showAlert,
  closeAlert,
  openDrawer,
  closeDrawer,
  setPageTitle,
  updateDrawerData,
  setLoading,
} = actions;

export default reducer;
