import React from "react";

// components
import Error500 from "src/pages/500";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return <Error500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
