import { createApi } from "@reduxjs/toolkit/query/react";
import { LAYOUTS } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery,
  endpoints: (builder) => ({
    getLayout: builder.query({
      query: () => LAYOUTS,
    }),
  }),
});

export const { useGetLayoutQuery } = commonApi;
