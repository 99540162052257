import { useMemo } from "react";

// mui
import { SvgIcon } from "@mui/material";

// hooks
import useSessionStorage from "./useSessionStorage";

// icons
import CoinsHandIcon from "@untitled-ui/icons-react/build/esm/CoinsHand";
import BankIcon from "@untitled-ui/icons-react/build/esm/Bank";
import CreditCard02Icon from "@untitled-ui/icons-react/build/esm/CreditCard02";
import PieChartIcon from "@untitled-ui/icons-react/build/esm/PieChart02";
import Users02Icon from "@untitled-ui/icons-react/build/esm/Users02";
import Send01Icon from "@untitled-ui/icons-react/build/esm/Send01";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import FileIcon from "src/icons/untitled-ui/duocolor/file-04";
import BuildingIcon from "src/icons/untitled-ui/duocolor/building-04";
import ReceiptCheck from "src/icons/untitled-ui/duocolor/receipt-check";
import AccessControl from "src/icons/untitled-ui/duocolor/lock-01";
import { RiBuildingFill } from "react-icons/ri";
import { IoDocumentsOutline } from "react-icons/io5";
import { FaRegHandshake } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";

import GeographyIcon from "@untitled-ui/icons-react/build/esm/Globe05";
import SectorIcon from "@untitled-ui/icons-react/build/esm/Columns01";
import CurrencyIcon from "@untitled-ui/icons-react/build/esm/CurrencyDollarCircle";
import RequestTypeIcon from "@untitled-ui/icons-react/build/esm/SwitchHorizontal01";
import DocumentCategoryIcon from "@untitled-ui/icons-react/build/esm/Grid01";
import PencilIcon from "@untitled-ui/icons-react/build/esm/Pencil02";

import {
  LibraryBooksOutlined,
  GridViewOutlined,
  GroupsOutlined,
  PeopleOutlined, PersonSearchOutlined, PersonPin, PersonPinCircleOutlined, HowToRegOutlined,
} from "@mui/icons-material";
import Secretaries from "src/components/icons/Secretaries";
import Auditors from "src/components/icons/Auditors";

// paths
import { paths } from "src/paths";
import {color} from "@mui/system";

const TENANT_ADMIN = [
  {
    items: [
      {
        title: "Employees",
        path: paths.admin.employee.index,
        icon: (
          <SvgIcon fontSize="small">
            <Users02Icon />
          </SvgIcon>
        ),
      },
      {
        title: "Corporate Authorities",
        path: paths.admin.authorities.index,
        icon: (
          <SvgIcon fontSize="small">
            <PencilIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Departments",
        path: paths.admin.departments.index,
        icon: (
          <SvgIcon fontSize="small">
            <RiBuildingFill />
          </SvgIcon>
        ),
      },
      {
        title: "Corporate Roles",
        path: paths.admin.corporateRoles.index,
        icon: (
          <SvgIcon fontSize="small">
            <RiBuildingFill />
          </SvgIcon>
        ),
      },
    ],
  },
];

const INSTANCE_ADMIN = [
  {
    items: [
      {
        title: "Companies",
        path: paths.admin.companies.index,
        icon: (
          <SvgIcon fontSize="small">
            <BuildingIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Access Control",
        path: paths.admin.applicationAccessControl.index,
        icon: (
          <SvgIcon fontSize="small">
            <AccessControl />
          </SvgIcon>
        ),
        items: [
          {
            title: "Application Access",
            path: paths.admin.applicationAccessControl.index,
            icon: (
              <SvgIcon fontSize="small">
                <BuildingIcon />
              </SvgIcon>
            ),
          },
          {
            title: "Company Access",
            path: paths.admin.companyAccessControl.index,
            icon: (
              <SvgIcon fontSize="small">
                <BuildingIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        title: "Document Categories",
        path: paths.admin.documentCategory.index,
        icon: (
          <SvgIcon fontSize="small">
            <DocumentCategoryIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Document Types",
        path: paths.admin.documentTypes.index,
        icon: (
          <SvgIcon fontSize="small">
            <FileIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Sectors",
        path: paths.admin.sectors.index,
        icon: (
          <SvgIcon fontSize="small">
            <SectorIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Geography",
        path: paths.admin.geography.index,
        icon: (
          <SvgIcon fontSize="small">
            <GeographyIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Currency",
        path: paths.admin.currency.index,
        icon: (
          <SvgIcon fontSize="small">
            <CurrencyIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Banks",
        path: paths.admin.banks.index,
        icon: (
          <SvgIcon fontSize="small">
            <BankIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Lessors",
        path: paths.admin.leasing.index,
        icon: (
          <SvgIcon fontSize="small">
            <HiBuildingOffice2 />
          </SvgIcon>
        ),
      },
      {
        title: "Workflow Request Types",
        path: paths.admin.workflowRequestTypes.index,
        icon: (
          <SvgIcon fontSize="small">
            <RequestTypeIcon />
          </SvgIcon>
        ),
      },
    ],
  },
];

const USER = [
  {
    items: [
      {
        title: "Home",
        path: paths.home.index,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Global View",
        path: paths.dashboard.index,
        icon: (
          <SvgIcon fontSize="small">
            <GridViewOutlined />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "People",
    items: [
      {
        title: "Corporate Management",
        path: paths.people.corporateManagement.index,
        icon: (
          <SvgIcon fontSize="small">
            <GroupsOutlined />
          </SvgIcon>
        ),
      },
      {
        title: "Board of Directors",
        path: paths.people.boardOfDirectors.index,
        icon: (
          <SvgIcon fontSize="small">
            <PeopleOutlined />
          </SvgIcon>
        ),
      },
      {
        title: "Company Secretaries",
        path: paths.people.companySecretaries.index,
        icon: (
          <SvgIcon fontSize="small">
            {/*<Secretaries />*/}
            <HowToRegOutlined />
          </SvgIcon>
        ),
      },
      {
        title: "Auditors",
        path: paths.people.auditors.index,
        icon: (
          <SvgIcon fontSize="small">
            {/*<Auditors />*/}
            <PersonSearchOutlined />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "Secretarial",
    items: [
      {
        title: "Company Files",
        path: paths.secretarial.companyFiles.index,
        icon: (
          <SvgIcon fontSize="small">
            <FileIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Share Structure",
        path: paths.secretarial.statutoryDocuments.index,
        icon: (
          <SvgIcon fontSize="small">
            <PieChartIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Minute Book",
        path: paths.secretarial.boardResolutions.index,
        icon: (
          <SvgIcon fontSize="small">
            <LibraryBooksOutlined />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "Banking",
    items: [
      {
        title: "Bank Facilities",
        path: paths.banking.facilities.index,
        icon: (
          <SvgIcon fontSize="small">
            <BankIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Accounts",
        path: paths.banking.accounts.index,
        icon: (
          <SvgIcon fontSize="small">
            <CoinsHandIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Credit Cards",
        path: paths.banking.creditCards.index,
        icon: (
          <SvgIcon fontSize="small">
            <CreditCard02Icon />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "Lease",
    items: [
      {
        title: "Lease Facility",
        path: paths.leasing.facilities.index,
        icon: (
          <SvgIcon fontSize="small">
            <FaRegHandshake />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "Workflows",
    items: [
      {
        title: "Requests",
        path: paths.workFlows.requests.index,
        icon: (
          <SvgIcon fontSize="small">
            <Send01Icon />
          </SvgIcon>
        ),
      },
      {
        title: "Tasks",
        path: paths.workFlows.tasks.index,
        icon: (
          <SvgIcon fontSize="small">
            <ReceiptCheck />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: "Organization",
    items: [
      {
        title: "Repository",
        path: paths.organization.documents.index,
        icon: (
          <SvgIcon fontSize="small">
            <IoDocumentsOutline />
          </SvgIcon>
        ),
      },
    ],
  },
];

const useSections = () => {
  const [isAdmin] = useSessionStorage("is_admin");
  const [isInstanceAdmin] = useSessionStorage("is_instance_admin");

  const adminNav = !isInstanceAdmin ? TENANT_ADMIN : INSTANCE_ADMIN;

  return useMemo(() => {
    return (!isAdmin && [...USER]) || [...adminNav];
  }, [adminNav, isAdmin]);
};

export default useSections;
