import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string().required("Document Type name is required"),
  documentCategoryId: Yup.number()
    .required("Document Category is required")
    .positive("Document Category is required")
    .integer("Document Category is required"),
});

export default validationSchema;
