import DocumentTypeFeature from "src/features/admin/document-types";
import { useEffect } from "react";
import Seo from "src/components/Seo";
import { useDispatch } from "src/store";
import { pageTitles } from "src/pageTitles";
import { setPageTitle } from "src/redux/slice";
const DocumentType = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitles.admin.documenttype.index));
  }, [dispatch]);
  return (
    <>
      <Seo title={pageTitles.admin.documenttype.index} />
      <DocumentTypeFeature />;
    </>
  );
};

export default DocumentType;
