import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../constants";
import { GEOGRAPHY } from "../../helpers/url";
import { baseQuery } from "../apiService";

export const TAGS = {
  GEOGRAPHY: "GEOGRAPHY",
};
export const geographiesApi = createApi({
  reducerPath: "geographiesApi",
  baseQuery,
  //tagtypes
  endpoints: (builder) => ({
    getGeographies: builder.query({
      query: () => `${GEOGRAPHY}`,
      providesTags: [TAGS.GEOGRAPHY],
    }),
    getGeography: builder.query({
      query: (id) => `${GEOGRAPHY}/${id}`,
      providesTags: [TAGS.GEOGRAPHY],
    }),
    createGeography: builder.mutation({
      query: (payload) => ({
        url: `${GEOGRAPHY}/`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.GEOGRAPHY],
    }),
    updateGeography: builder.mutation({
      query: (payload) => ({
        url: `${GEOGRAPHY}/${payload.id}`,

        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.GEOGRAPHY],
    }),
    deleteGeography: builder.mutation({
      query: (id) => ({
        url: `${GEOGRAPHY}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.GEOGRAPHY],
    }),
  }),
});

export const {
  useGetGeographiesQuery,
  useGetGeographyQuery,
  useCreateGeographyMutation,
  useUpdateGeographyMutation,
  useDeleteGeographyMutation,
} = geographiesApi;
