// icons
import CheckIcon from "@untitled-ui/icons-react/build/esm/Check";
import XCloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import AlertTriangleIcon from "@untitled-ui/icons-react/build/esm/AlertTriangle";

// mui
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Paper,
  SvgIcon,
  Typography,
} from "@mui/material";

// actions
import { closeAlert } from "src/redux/slice";

// selectors
import { alertDialogSelector } from "src/redux/selectors";

// other
import { useSelector, useDispatch } from "src/store";
import { ALERT_TYPES } from "src/constants";

const AlertModal = () => {
  const dispatch = useDispatch();

  const { open, title, message, type } = useSelector(alertDialogSelector);

  const closeHandler = () => dispatch(closeAlert());

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={closeHandler}>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "neutral.800" : "neutral.100",
        }}
      >
        <Paper
          elevation={12}
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              backgroundColor:
                type === ALERT_TYPES.SUCCESS
                  ? "success.lightest"
                  : "error.lightest",
              color:
                type === ALERT_TYPES.SUCCESS
                  ? "success.main"
                  : type === ALERT_TYPES.ERROR
                  ? "warning.main"
                  : "",
              mb: 2,
            }}
          >
            {type === ALERT_TYPES.SUCCESS && (
              <SvgIcon>
                <CheckIcon />
              </SvgIcon>
            )}
            {type === ALERT_TYPES.ERROR && (
              <SvgIcon>
                {" "}
                <XCloseIcon />
              </SvgIcon>
            )}
            {type === ALERT_TYPES.WARNING && (
              <SvgIcon>
                {" "}
                <AlertTriangleIcon />
              </SvgIcon>
            )}
          </Avatar>
          <Typography variant="h5">{title}</Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            {message}
          </Typography>
          <Button
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            variant="contained"
            onClick={closeHandler}
          >
            Go back
          </Button>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default AlertModal;
