import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { COMPANY_AUDITORS_CHANGE } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  AUDITOR_CHANGE: "AUDITOR_CHANGE",
};

export const companyAuditorsChangeApi = createApi({
  reducerPath: "companyAuditorsChangeApi",
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query({
      query: (id) => `${COMPANY_AUDITORS_CHANGE}/${id}`,
      providesTags: [TAGS.AUDITOR_CHANGE],
    }),
    createAuditorChange: builder.mutation({
      query: (payload) => ({
        url: COMPANY_AUDITORS_CHANGE,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateRequest: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_AUDITORS_CHANGE}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.AUDITOR_CHANGE],
    }),
  }),
});

export const {
  useGetByIdQuery,
  useCreateAuditorChangeMutation,
  useUpdateRequestMutation,
} = companyAuditorsChangeApi;
