import { Container, Typography, Box } from "@mui/material";

const NoData = ({ boxSx, title }) => (
  <Box
    sx={{
      alignItems: "center",
      display: "flex",
      flexGrow: 1,
      py: 2,
      ...boxSx,
    }}
  >
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Box
          alt="Not authorized"
          component="img"
          src="/assets/file_searching.png"
          sx={{ width: 250, height: 153.28 }}
        />
      </Box>
      <Typography color="text.secondary" align="center">
        {title}
      </Typography>
    </Container>
  </Box>
);

export default NoData;
