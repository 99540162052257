import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ImageListItem,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";

const DataTableWithoutData = ({
  title,
  subheader,
  headerSx,
  imageSx,
  message,
  action,
}) => (
  <Card>
    <CardHeader
      title={title}
      subheader={subheader}
      sx={{ ...headerSx }}
      action={action}
    />
    <Divider />
    <CardContent>
      <Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={2}
        >
          <ImageListItem sx={{ width: 150, height: 100, ...imageSx }}>
            <img src="/assets/file_searching.png" alt="no-data" />
          </ImageListItem>
          <div>
            <Typography color="text.secondary">{`${
              message || "No Data Available."
            }`}</Typography>
          </div>
        </Stack>
      </Box>
    </CardContent>
    <TablePagination
      component="div"
      count={0}
      page={0}
      rowsPerPage={5}
      rowsPerPageOptions={[5, 10, 25]}
    />
  </Card>
);

DataTableWithoutData.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  message: PropTypes.string,
  imageSx: PropTypes.object,
  headerSx: PropTypes.object,
  action: PropTypes.node,
};

DataTableWithoutData.defaultProps = {
  imageSx: {},
  headerSx: {},
};

export default DataTableWithoutData;
