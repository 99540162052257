import { useMemo } from "react";

// mui
import { Box, Card, CardHeader, Divider, Grid, Stack } from "@mui/material";

// hooks
// layout
import PageContainer from "src/layouts/page";
import generateInputField from "src/helpers/generateInputField";

import { corporateProfileDetails } from "../common/formDetails";
import useGetCorporateRoles from "src/features/people/corporate-management/hooks/useGetCorporateRoles";
import useCorporateProfileDetails from "../hooks/useCorporateProfileDetails";
import useGetCompanies from "src/hooks/company/useGetCompanies";
import FormFooter from "src/components/FormFooter";
import { useGetApplicationUsersQuery } from "../redux/api";
import convertToModel from "src/helpers/convertToModel";
import ApplicationUser from "src/models/organization/ApplicationUser";
import generateOptions from "src/utils/generateOptions";
import { useParams } from "react-router";
const CorporateProfileDetails = ({ isUpdating, employee, onSubmit, onBack }) => {
  const params = useParams()

  const { formik, isLoading } = useCorporateProfileDetails(
    employee,
    onSubmit
  );
  const { corporateRoles } = useGetCorporateRoles();
  const { selectOptions } = useGetCompanies();
  const { data: applicationUsers = [] } = useGetApplicationUsersQuery();

  const corporateRoleOptions = corporateRoles?.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const users = useMemo(
    () => convertToModel(applicationUsers, ApplicationUser),
    [applicationUsers]
  );
  const memoizedUsers = useMemo(
    () => generateOptions(users, "id", "fullName"),
    [users]
  );

  const buttonLabel = params.id ? "Update" : "Create";

  return (
    <PageContainer>
      <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          <Card>
            <CardHeader title="Corporate Profile Details" />
            <Divider />
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {corporateProfileDetails.map((item) => {
                  let label = item.label;
                  if (typeof item.label === "function") {
                    label = item.label(formik.values);
                  }
                  return (
                    <Grid key={item.key} item xs={item.xs} md={item.md}>
                      {generateInputField(
                        { ...item, label },
                        formik,
                        item.name === "corporateRoleId"
                          ? corporateRoleOptions
                          : memoizedUsers
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Card>

          <FormFooter btnLabel={buttonLabel} isLoading={isUpdating} onBack={onBack} />
        </Stack>
      </form>
    </PageContainer>
  );
};

export default CorporateProfileDetails;
