import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "src/constants";
import { COMPANY_ACQUISITION_REQUEST } from "src/helpers/url";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  COMPANY_ACQUISITION_DETAIL: "COMPANY_ACQUISITION_DETAIL",
};

export const companyAcquisitionApi = createApi({
  reducerPath: "companyAcquisitionApi",
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query({
      query: (id) => `${COMPANY_ACQUISITION_REQUEST}/${id}`,
      providesTags: [TAGS.COMPANY_ACQUISITION_DETAIL],
    }),
    createCompanyAcquisition: builder.mutation({
      query: (payload) => ({
        url: COMPANY_ACQUISITION_REQUEST,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateRequest: builder.mutation({
      query: (payload) => ({
        url: `${COMPANY_ACQUISITION_REQUEST}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.COMPANY_ACQUISITION_DETAIL],
    }),
  }),
});

export const {
  useGetByIdQuery,
  useCreateCompanyAcquisitionMutation,
  useUpdateRequestMutation,
} = companyAcquisitionApi;
