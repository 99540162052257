import React from "react";
import PropTypes from "prop-types";


// mui
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
} from "@mui/material";

// hooks
import useNewEmployeeFormik from "../create/hooks/useNewEmployeeFormik";

// components
import FormFooter from "src/components/FormFooter";

import {
  corporateProfileDetails,
  employeeBasicDetails,
  employeeResidentialDetails,
  employeeoOfficialDetails,
} from "../common/formDetails";

// other
import generateUniqueKey from "src/utils/generateUniqueKeys";
import { Formik } from "formik";

const sections = [
  {
    key: generateUniqueKey("basic_details"),
    title: "Basic Details",
    items: employeeBasicDetails,
  },
  {
    key: generateUniqueKey("official_details"),
    title: "Official Details",
    items: employeeoOfficialDetails,
  },
  {
    key: generateUniqueKey("residential_details"),
    title: "Residential Details",
    items: employeeResidentialDetails,
  },
];

const Form = ({
  isLoading,
  employee,
  selectedTab,
  onSubmit,
  onBack,
  onTabChange,
}) => {
  const { handleSubmit, renderFields, tabChangeHandler, resetCorporateRoleHandler } = useNewEmployeeFormik(
    employee,
    selectedTab,
    onTabChange,
    onSubmit
  );

  return (
    <form autoComplete="off" noValidate>
      <Stack direction="column" spacing={2}>
        {selectedTab === 1 &&
          sections.map(({ key, title, items }) => (
            <Card key={key} item xs={12}>
              <CardHeader title={title} />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  {items.map(({ xs, md, ...item }) => (
                    <Grid key={item.key} item xs={xs} md={md}>
                      {renderFields(item)}
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          ))}
        {selectedTab === 2 && (
          <Card item xs={12}>
            <CardHeader
              action={
                <Button sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }} type="button" onClick={resetCorporateRoleHandler}>
                  Reset
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {corporateProfileDetails.map(({ xs, md, ...item }) => (
                  <Grid key={item.key} item xs={xs} md={md}>
                    {renderFields(item)}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
        <FormFooter
          type="button"
          isLoading={isLoading}
          btnLabel={selectedTab === 1 ? "Next" : "Submit"}
          onClick={selectedTab === 1 ? tabChangeHandler : handleSubmit}
          onBack={selectedTab === 2 && onBack}
        />
      </Stack>
    </form>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  employee: PropTypes.object,
  selectedTab: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
};

export default Form;
