// mui
import {Box, Divider, Stack, Button, LinearProgress} from "@mui/material";

// hooks
import useUploadLogic from "src/hooks/documents/useUploadLogic";

// components
import FileDropzone from "../fileDropzone";

const UploadForm = () => {
  const {
    files,
    isUploadDisabled,
    isLoading,
    uploadHandler,
    dropHandler,
    removeHandler,
  } = useUploadLogic({});

  return (
    <>
      <Box sx={{ mt: 3, mb: 2, mr: 2, ml: 2 }}>
        <FileDropzone
          multiple={false}
          files={files}
          onDrop={dropHandler}
          onRemove={removeHandler}
        />
      </Box>
        {isLoading ? <LinearProgress /> : <Divider />}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Stack alignItems="center" direction="row" spacing={1} />
        <div>
          <Button
            variant="contained"
            onClick={uploadHandler}
            disabled={isLoading || isUploadDisabled}
          >
            {isLoading && "Please wait..."}
            {!isLoading && "Upload"}
          </Button>
        </div>
      </Stack>
    </>
  );
};

export default UploadForm;
