import { useParams } from "react-router";
import PropTypes from "prop-types";

// mui
import {
  Menu,
  MenuItem as MuiMenuItem,
  menuItemClasses,
  SvgIcon,
} from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";

// untitled-ui
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import CheckDone01 from "@untitled-ui/icons-react/build/esm/CheckDone01";
import UploadIcon from "@untitled-ui/icons-react/build/esm/Share01";
import EyeIcon from "@untitled-ui/icons-react/build/esm/Eye";

import DownloadIcon from "@untitled-ui/icons-react/build/esm/Download01";

// hooks
import useDocumentLifecycleActionsLogic from "src/hooks/documents/useDocumentLifecycleActionsLogic";

// models
import DocumentModel from "src/models/documents/Document";

// constants
import { DOCUMENT_DOCKET_OPTIONS } from "./constants";
import generateUniqueKey from "src/utils/generateUniqueKeys";

const DocumentOptions = ({
  document,
  docketId,
  isVersion,
  anchorEl,
  open,
  showUpload,
  isMoveAllowed,
  onClose,
  onUpload,
  onDownload,
  onMove,
}) => {
  const params = useParams();
  const { versionHandler, lockUnlockHandler, removeHandler, previewHandler } =
    useDocumentLifecycleActionsLogic(docketId, isVersion);

  const showUploadOption = (options = []) =>
    (!showUpload &&
      options.filter(
        (option) => option.label !== "Upload" && option.label !== "Download"
      )) ||
    options;

  const getOptions = () => {
    const options = [
      {
        key: generateUniqueKey("lock_unlock"),
        icon: document.lockUnlockIcon,
        label: document.lockUnlockLabel,
        onClick: lockUnlockHandler.bind(null, document),
      },
      {
        key: generateUniqueKey("current"),
        icon: <CheckDone01 />,
        sx: { color: "primary.main" },
        label: DOCUMENT_DOCKET_OPTIONS.SET_CURRENT,
        onClick: versionHandler.bind(null, params.id, document),
      },
      {
        key: generateUniqueKey("upload"),
        icon: <UploadIcon />,
        sx: { color: "primary.main" },
        label: DOCUMENT_DOCKET_OPTIONS.UPLOAD,
        onClick: onUpload.bind(null, document),
      },
      {
        key: generateUniqueKey("download"),
        icon: <DownloadIcon />,
        sx: { color: "primary.main.dark" },
        label: DOCUMENT_DOCKET_OPTIONS.DOWNLOAD,
        onClick: onDownload.bind(null, document),
      },
      {
        key: generateUniqueKey("move"),
        icon: <DriveFileMoveOutlinedIcon />,
        sx: { color: "primary.main.dark" },
        label: DOCUMENT_DOCKET_OPTIONS.MOVE,
        onClick: onMove.bind(null, document),
      },
      {
        key: generateUniqueKey("remove"),
        icon: <Trash02Icon />,
        sx: { color: "error.main" },
        label: DOCUMENT_DOCKET_OPTIONS.REMOVE,
        onClick: removeHandler.bind(null, document),
      },
      {
        key: generateUniqueKey("preview"),
        icon: <EyeIcon />,
        sx: { color: "primary.main.dark" },
        label: DOCUMENT_DOCKET_OPTIONS.PREVIEW,
        onClick: previewHandler.bind(null, document),
      },
    ];

    let _options = [...options];

    if (!isMoveAllowed) {
      _options = [
        ..._options.filter(
          (option) => option.label !== DOCUMENT_DOCKET_OPTIONS.MOVE
        ),
      ];
    }

    if (!document.azureBlobStorageUrl && !isVersion) {
      return showUploadOption([
        ..._options.filter(
          (option) =>
            option.label !== DOCUMENT_DOCKET_OPTIONS.SET_CURRENT &&
            option.label !== DOCUMENT_DOCKET_OPTIONS.DOWNLOAD &&
            option.label !== DOCUMENT_DOCKET_OPTIONS.PREVIEW
        ),
      ]);
    }

    if (document.azureBlobStorageUrl && !isVersion) {
      return showUploadOption([
        ..._options.filter(
          (option) => option.label !== DOCUMENT_DOCKET_OPTIONS.SET_CURRENT
        ),
      ]);
    }

    if (isVersion) {
      return showUploadOption([
        ..._options.filter(
          (option) =>
            option.label !== DOCUMENT_DOCKET_OPTIONS.REMOVE &&
            option.label !== DOCUMENT_DOCKET_OPTIONS.MOVE &&
            !option.key.includes("lock_unlock")
        ),
      ]);
    }

    return showUploadOption(options);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      sx={{
        [`& .${menuItemClasses.root}`]: {
          fontSize: 14,
          "& svg": {
            mr: 1,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {getOptions().map((option) => (
        <MuiMenuItem
          key={option.key}
          sx={{ ...option.sx }}
          onClick={option.onClick}
        >
          <SvgIcon fontSize="small">{option.icon}</SvgIcon>
          {option.label}
        </MuiMenuItem>
      ))}
    </Menu>
  );
};

DocumentOptions.propTypes = {
  document: PropTypes.instanceOf(DocumentModel),
  docketId: PropTypes.any,
  isVersion: PropTypes.bool,
  showUpload: PropTypes.bool,
  isMoveAllowed: PropTypes.bool,
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onMove: PropTypes.func,
};

DocumentOptions.defaultProps = {
  open: false,
  isVersion: false,
  showUpload: false,
  onMove: () => {},
  onUpload: () => {},
  onDownload: () => {},
};

export default DocumentOptions;
