import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";

const EmployeeDetailSkeleton = () => (
  <Stack spacing={4}>
    <Card>
      <CardHeader title="Basic Details" />
      <Divider />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[1, 2, 3, 4, 5].map((item) => (
            <Grid item key={item} xs={12} md={6}>
              <Skeleton variant="rectangular" width="40" />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
    <Card>
      <CardHeader title="Official Details" />
      <Divider />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[5, 6, 7, 8].map((item) => (
            <Grid item key={item} xs={12} md={6}>
              <Skeleton variant="rectangular" width="40" />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
    <Card>
      <CardHeader title="Residential Details" />
      <Divider />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {[9, 10, 11, 12].map((item) => (
            <Grid item key={item} xs={12} md={6}>
              <Skeleton variant="rectangular" width="40" />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Card>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-end"
      spacing={1}
    >
      <Skeleton variant="rectangular" width="80" height="30" />
    </Stack>
  </Stack>
);

export default EmployeeDetailSkeleton;
