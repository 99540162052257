import { useEffect } from "react";
import toast from "react-hot-toast";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

// hooks
import { useDeleteDepartmentsMutation } from "src/redux/company/api";

const useDeleteDepartment = (id) => {
  const [deleteDepartmentmethod, { isError, error, isSuccess }] =
    useDeleteDepartmentsMutation();

  const deleteDepartment = (id) => {
    deleteDepartmentmethod(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Department Deleted Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(`${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  return {
    deleteDepartment,
  };
};

export default useDeleteDepartment;
