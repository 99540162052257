// hooks
import useRouter from "src/hooks/useRouter";
import useDeleteEmployee from "./hooks/useDeleteEmployee";
import useEmployees from "./hooks/useEmployees";
import { useDispatch, useSelector } from "src/store";

// hocs
import withLoading from "src/hocs/withLoading";

// layouts
import PageContainer from "src/layouts/page";

// components
import DataTable from "src/components/tables/DataTable";
import FloatingButton from "src/components/FloatingButton";
import DataTableSkeleton from "src/components/skeletons/table/DataTableSkeleton";
import FormModal from "src/components/modals/FormModal";
import Confirmation from "src/components/modals/Confirmation";

// redux
import { closeFormDialog } from "src/redux/slice";
import { formDialogSelector } from "src/redux/selectors";

// other
import { paths } from "src/paths";

const TableWithLoading = withLoading(DataTable, DataTableSkeleton);

const EmployeesFeature = () => {
  const { push } = useRouter();
  const {
    EmployeesList,
    columns,
    selectedEmployee,
    title,
    isLoading,
    viewHandler,
  } = useEmployees();
  const { deleteEmployee } = useDeleteEmployee();

  const newEmployee = () => {
    push(paths.admin.employee.newEmployee);
  };

  const { data: dialogData } = useSelector(formDialogSelector);
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    dispatch(closeFormDialog());
  };
  const handleDelete = () => {
    deleteEmployee(selectedEmployee?.id);
    onCloseHandler();
  };

  return (
    <>
      <PageContainer>
        <TableWithLoading
          isLoading={isLoading}
          columns={columns}
          title={title}
          rows={EmployeesList}
          headerSx={{ color: "#696868" }}
          onRowClick={viewHandler}
        />
      </PageContainer>

      {dialogData?.type === "DELETE_EMPLOYEE" && (
        <FormModal title="Remove Employee">
          <Confirmation onConfirm={handleDelete} onClose={onCloseHandler} />
        </FormModal>
      )}

      <FloatingButton title="New Employee" onClick={newEmployee} />
    </>
  );
};

export default EmployeesFeature;
