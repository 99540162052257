import { createComponents } from "./createComponents";
import { createPalette } from "./createPalette";
import { createShadows } from "./createShadows";

export const createOptions = (config) => {
  const { colorPreset, contrast } = config;
  const palette = createPalette({ colorPreset, contrast });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows,
  };
};
