import { useState, useCallback } from "react";
import { toast } from "react-hot-toast";

const useToast = () => {
  const [toastId, setToastId] = useState();

  const cleanup = useCallback(() => {
    if (toastId) {
      setTimeout(() => {
        toast.dismiss(toastId);
        setToastId();
      }, 2000);
    }
  }, [toastId]);

  const toastIdHandler = useCallback((id) => setToastId(id), []);

  return {
    toastId,
    cleanup,
    toastIdHandler,
  };
};

export default useToast;
