// mui
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Stack,
} from "@mui/material";

// layout
import PageContainer from "src/layouts/page";

import generateInputField from "src/helpers/generateInputField";

import {
  employeeBasicDetails,
  employeeoOfficialDetails,
  employeeResidentialDetails,
} from "../common/formDetails";
import useUpdateEmployee from "../hooks/useUpdateEmployee";
import { useParams } from "react-router";
import useGetDepartments from "../../departments/hooks/useGetDepartments";
import { useGetGeographiesQuery } from "src/redux/geography/api";

const UpdateEmployee = () => {
  const { id } = useParams();
  const { formik, isLoading, renderFields } = useUpdateEmployee(id);
  const { options } = useGetDepartments();
  const { data } = useGetGeographiesQuery();
  return (
    <>
      <PageContainer>
        <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={4}>
            <Card>
              <CardHeader title="Basic Details" />
              <Divider />
              <Box sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  {employeeBasicDetails.map((item) => renderFields(item))}
                </Grid>
              </Box>
            </Card>
            <Card>
              <CardHeader title="Offecial Details" />
              <Divider />
              <Box sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  {employeeoOfficialDetails.map((item) =>
                    renderFields(item, options)
                  )}
                </Grid>
              </Box>
            </Card>
            <Card>
              <CardHeader title="Residential Details" />
              <Divider />
              <Box sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  {employeeResidentialDetails.map((item) =>
                    renderFields(item, options, data)
                  )}
                </Grid>
              </Box>
            </Card>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              <Button type="submit" variant="contained" disabled={isLoading}>
                {!isLoading && "Update"}
                {isLoading && "Please wait..."}
              </Button>
            </Stack>
          </Stack>
        </form>
      </PageContainer>
    </>
  );
};

export default UpdateEmployee;
