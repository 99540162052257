import React from "react";
import PropTypes from "prop-types";

// mui
import { Chip, Typography } from "@mui/material";

const Title = ({ title, authorityLevel }) => (
  <div style={{ display: "flex", direction: "row", gap: 16 }}>
    <Typography
      variant="subtitle1"
      sx={{ fontSize: "0.875rem", fontWeight: 400 }}
    >
      {title}
    </Typography>
    <Chip size="small" variant="filled" color="info" label={authorityLevel} />
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  authorityLevel: PropTypes.string.isRequired,
};

export default Title;
