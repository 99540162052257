import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";

// hooks
import { useDispatch, useSelector } from "src/store";
import useRouter from "src/hooks/useRouter";
import {
  documentsApi,
  useDeleteDocumentMutation,
  useUpdateLockUnlockDocumentMutation,
  useUpdateVersionMutation,
  TAGS,
  useUpdateDocumentLocationMutation,
} from "src/redux/documents/api";
import useToast from "../useToast";

// redux
import { formDialogSelector } from "src/redux/selectors";

// other
import { paths } from "src/paths";
import { openFormDialog } from "src/redux/slice";
import { DIALOG_TYPE } from "src/components/docket/constants";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import { useState } from "react";

const useDocumentLifecycleActionsLogic = (docketId, isVersion) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { push } = useRouter();
  const { toastIdHandler, cleanup, toastId } = useToast();
  const [isLocked, setIsLocked] = useState();
  const { data } = useSelector(formDialogSelector);
  const [
    deleteDocument,
    {
      isLoading: deleting,
      isSuccess: isDeleted,
      isError: isDeleteError,
      error: deleteError,
      reset,
    },
  ] = useDeleteDocumentMutation();

  const [
    revertVersion,
    {
      isLoading: reverting,
      isSuccess: isRevertSuccess,
      isError: isRevertError,
      error: revertError,
      reset: resetRevertVersionState,
    },
  ] = useUpdateVersionMutation();

  const [
    lockUnlockDocument,
    {
      isLoading: lockingOrUnlocking,
      isSuccess: isLockUnlockSuccess,
      isError: isLockUnlockError,
      error: lockUnlockError,
      reset: resetLockUnlockDocumentState,
    },
  ] = useUpdateLockUnlockDocumentMutation();

  const [
    changeLocation,
    {
      isLoading: moving,
      isSuccess: isMoveSuccess,
      isError: isMoveError,
      error: moveError,
      reset: resetChangeLocationState,
    },
  ] = useUpdateDocumentLocationMutation();

  // handle document remove feedback
  useEffect(() => {
    if (deleting && !toastId) {
      const _toastId = toast.loading("Loading...");
      toastIdHandler(_toastId);
    }

    if (isDeleted && toastId) {
      toast.success("Document Deleted Successfully.", {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch({
        type: `${documentsApi.reducerPath}/invalidateTags`,
        payload: [TAGS.DOCKET],
      }); // invalidate caches manually

      reset();
    }

    if (isDeleteError && toastId) {
      toast.error(`Error when Removing: ${deleteError?.data?.detail}`, {
        id: toastId,
        duration: ERROR_TOAST_DURATION,
      });
      reset();
    }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      cleanup();
    };
  }, [
    cleanup,
    deleteError,
    deleting,
    dispatch,
    isDeleteError,
    isDeleted,
    reset,
    toastIdHandler,
    toastId,
  ]);

  // handle document version revert feedback
  useEffect(() => {
    if (reverting && !toastId) {
      const _toastId = toast.loading("Loading...");
      toastIdHandler(_toastId);
    }

    if (isRevertSuccess && toastId) {
      toast.success("Version Changed Successfully.", {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });
      dispatch({
        type: `${documentsApi.reducerPath}/invalidateTags`,
        payload: [TAGS.DOCUMENT],
      });
      resetRevertVersionState();
    }

    if (isRevertError && toastId) {
      toast.error(
        `Error when Changing the Version: ${revertError?.data?.detail}`,
        {
          id: toastId,
          duration: ERROR_TOAST_DURATION,
        }
      );
      resetRevertVersionState();
    }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      cleanup();
    };
  }, [
    cleanup,
    dispatch,
    isRevertError,
    isRevertSuccess,
    resetRevertVersionState,
    revertError,
    reverting,
    toastId,
    toastIdHandler,
  ]);

  // handle document lock unlock feedback
  useEffect(() => {
    if (lockingOrUnlocking && !toastId) {
      const loadingMessage = !isLocked
        ? "Unlocking file..."
        : "Locking file...";
      const _toastId = toast.loading(loadingMessage);
      toastIdHandler(_toastId);
    }

    if (isLockUnlockSuccess && toastId) {
      const successMessage = !isLocked
        ? "File Unlocked Successfully."
        : "File Locked Successfully.";

      toast.success(successMessage, {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });

      dispatch({
        type: `${documentsApi.reducerPath}/invalidateTags`,
        payload: [TAGS.DOCKET],
      });
      dispatch({
        type: `${documentsApi.reducerPath}/invalidateTags`,
        payload: [TAGS.LIBRARIES],
      });
      resetLockUnlockDocumentState();
    }

    if (isLockUnlockError && toastId) {
      toast.error(`Error: ${lockUnlockError?.data?.detail}`, {
        id: toastId,
        duration: ERROR_TOAST_DURATION,
      });
      resetLockUnlockDocumentState();
    }
    // if (lockingOrUnlocking && !toastId) {
    //   const _toastId = toast.loading("Loading...");
    //   toastIdHandler(_toastId);
    // }

    // if (isLockUnlockSuccess && toastId) {
    //   toast.success("File Status Updated Successfully.", {
    //     id: toastId,
    //     duration: DEFAULT_TOAST_DURATION,
    //   });
    //   dispatch({
    //     type: `${documentsApi.reducerPath}/invalidateTags`,
    //     payload: [TAGS.DOCKET],
    //   });
    //   dispatch({
    //     type: `${documentsApi.reducerPath}/invalidateTags`,
    //     payload: [TAGS.LIBRARIES],
    //   });
    //   resetLockUnlockDocumentState();
    // }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      cleanup();
    };
  }, [
    lockUnlockError,
    dispatch,
    isLockUnlockError,
    isLockUnlockSuccess,
    resetLockUnlockDocumentState,
    lockingOrUnlocking,
    toastId,
    cleanup,
    toastIdHandler,
    isLocked,
  ]);

  // handle document moving feedback
  useEffect(() => {
    if (moving && !toastId) {
      const _toastId = toast.loading("Moving...");
      toastIdHandler(_toastId);
    }

    if (isMoveSuccess && toastId) {
      toast.success("Document Moved Successfully.", {
        id: toastId,
        duration: DEFAULT_TOAST_DURATION,
      });
      resetChangeLocationState();
    }

    if (isMoveError && toastId) {
      toast.error(
        `Error when Moving the Document: ${moveError?.data?.detail}`,
        {
          id: toastId,
          duration: ERROR_TOAST_DURATION,
        }
      );
      resetChangeLocationState();
    }

    // cleanup function to dismiss the toast when the component unmounts
    return () => {
      cleanup();
    };
  }, [
    moveError,
    dispatch,
    isMoveError,
    isMoveSuccess,
    resetChangeLocationState,
    moving,
    toastId,
    cleanup,
    toastIdHandler,
  ]);

  const removeHandler = (document) =>
    deleteDocument({ docket: docketId, id: document.id });

  const lockUnlockHandler = (document) => {
    const isLocked = !document.isLocked;
    lockUnlockDocument({
      docket: docketId,
      document: document.id,
      isLocked: isLocked,
    });
    setIsLocked(isLocked);
  };

  const versionHandler = (id, document) =>
    revertVersion({
      docket: docketId,
      document: id,
      versionId: document.id,
    });

  /**
   * view document
   * do not allow to open document if;
   * there is no upload and it is a version
   * @param {Object} document
   */
  const viewHandler = (document, allowed = true) => {
    if (!allowed) {
      return;
    }

    // const notAllowed = !document.azureBlobStorageUrl && !isVersion;
    push(
      `${paths.secretarial.companyFiles.index}/${document.id}?docket=${docketId}`
    );
  };

  const moveHandler = (docket, event) => {
    event.stopPropagation();
    const payload = {
      docketId: data?.parentDocketId ?? params.id,
      documentId: data.id,
      moveToDocketId: docket.id,
    };
    changeLocation(payload);
  };

  const clickMoveHandler = (document) =>
    dispatch(
      openFormDialog({
        type: DIALOG_TYPE.MOVE_DOCUMENT,
        id: document.id,
        name: document.name,
        parentDocketId: document.parentDocketId,
      })
    );

  const previewHandler = (document) => {
    const currentVersion = document.versions.find((v) => v.isCurrentVersion);
    dispatch(
      openFormDialog({
        type: DIALOG_TYPE.PREVIEW,
        documentId: document.id,
        docketId: docketId,
        versionId: isVersion ? document.id : currentVersion.id,
      })
    );
  };

  return {
    removeHandler,
    lockUnlockHandler,
    versionHandler,
    viewHandler,
    moveHandler,
    clickMoveHandler,
    previewHandler,
  };
};

export default useDocumentLifecycleActionsLogic;
