import convertToModel from "src/helpers/convertToModel";
import Base from "../../Base";
import Branch from "./Branch";

class Bank extends Base {
  constructor({
    id,
    name,
    code,
    branches,
    geographyId,
    geography,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      name,
      code,
      branches: convertToModel(branches, Branch),
      geographyId,
      geography,
    });
  }
}

export default Bank;
