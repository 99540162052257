import * as Yup from "yup";

const isBank = (institutionId, institutions) => {
  return (
    institutions.find((institution) => institution.id === institutionId)
      ?.institutionType === "Bank"
  );
};

const employeeValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  employeeNumber: Yup.string().required("Employee number is required"),
  nicNumber: Yup.string().required("NIC number is required"),
  passportNumber: Yup.string().required("Passport number is required"),
  userId: Yup.number().required("User is required"),
  companyEmail: Yup.string()
    .email("Invalid email")
    .required("Company email is required"),
  personalEmail: Yup.string()
    .email("Invalid email")
    .required("Personal email is required"),
  mobileNumber: Yup.string().required("Mobile number is required"),
  officeNumber: Yup.string().required("Office number is required"),
  addressLine1: Yup.string().required("Address Line 1 is required"),
  country: Yup.string().required("Country is required"),
  departmentId: Yup.string().required("Department is required"),
});

export const assignAuthorityValidationSchema = (institutions) =>
  Yup.object({
    institutionId: Yup.number()
      .min(1, "Institution is required")
      .required("Institution is required"),
    accountId: Yup.number().when("institutionId", {
      is: (institutionId) => isBank(institutionId, institutions),
      then: (schema) => schema.min(1, "Account is required"),
    }),
    corporateAuthorityId: Yup.number()
      .min(1, "Corporate authority is required")
      .required("Corporate authority is required"),
    corporateAuthorityLevelId: Yup.number()
      .min(1, "Corporate authority level is required")
      .required("Corporate authority level is required"),
  });

export const assignAuthorityValidationWithAccountSchema = (institutionType) =>
  Yup.object({
    ...assignAuthorityValidationSchema,
    accountId:
      institutionType === "Bank"
        ? Yup.number().min(1, "Account is required")
        : Yup.number(),
  });

export const assignAuthorityLevelValidationSchema = Yup.object({
  corporateAuthorityLevelId: Yup.number().required(
    "Corporate authority level is required"
  ),
});

export default employeeValidationSchema;
