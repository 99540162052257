import { createApi } from "@reduxjs/toolkit/query/react";

import { HTTP_METHODS } from "src/constants";
import { WORKFLOW_REQUESTS, WORKFLOW_REQUEST_TYPES } from "src/helpers/url";
import { baseQuery } from "../apiService";

export const TAGS = {
  WORKFLOW_REQUEST_TYPE: "WORKFLOW_REQUEST_TYPE",
  WORKFLOW: "WORKFLOW",
  REQUEST: "REQUEST",
};

export const workflowApi = createApi({
  reducerPath: "workflowApi",
  baseQuery,
  tagTypes: [TAGS.WORKFLOW_REQUEST_TYPE, TAGS.WORKFLOW, TAGS.REQUEST],
  endpoints: (builder) => ({
    getWorkflowByRequestId: builder.query({
      query: (requestTypeId) => `getWorkflow/${requestTypeId}`,
    }),
    getWorkflowById: builder.query({
      query: (id) => `workflow/${id}`,
      providesTags: [TAGS.WORKFLOW],
    }),
    getRequests: builder.query({
      query: (filters) => ({
        url: WORKFLOW_REQUESTS,
        params: { ...filters },
      }),
    }),
    getWorkflowRequestTypes: builder.query({
      query: () => `${WORKFLOW_REQUEST_TYPES}`,
      providesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    getWorkflowRequestTypeById: builder.query({
      query: (id) => `${WORKFLOW_REQUEST_TYPES}/${id}`,
      providesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    getWorkflowRequestTypeByCode: builder.query({
      query: (code) => `${WORKFLOW_REQUEST_TYPES}/${code}`,
      providesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    getRequestsSummary: builder.query({
      query: () => "workflowsRequests/summary",
    }),
    createRequest: builder.mutation({
      query: (payload) => ({
        url: `${WORKFLOW_REQUESTS}`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    createWorkflowRequestType: builder.mutation({
      query: (payload) => ({
        url: `${WORKFLOW_REQUEST_TYPES}/`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
      invalidatesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    createRequestRequiredDocuments: builder.mutation({
      query: (payload) => ({
        url: `${WORKFLOW_REQUESTS}/required-documents`,
        method: HTTP_METHODS.POST,
        body: payload,
      }),
    }),
    updateWorkflowRequestType: builder.mutation({
      query: (payload) => ({
        url: `${WORKFLOW_REQUEST_TYPES}/${payload.id}`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    updateWorkflow: builder.mutation({
      query: (payload) => ({
        url: `processWorkflow`,
        method: HTTP_METHODS.PUT,
        body: payload,
      }),
      invalidatesTags: [TAGS.WORKFLOW],
    }),
    deleteWorkflowRequestType: builder.mutation({
      query: (id) => ({
        url: `${WORKFLOW_REQUEST_TYPES}/${id}`,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: [TAGS.WORKFLOW_REQUEST_TYPE],
    }),
    getRequest: builder.query({
      query: (id) => `${WORKFLOW_REQUESTS}/${id}`,
      providesTags: [TAGS.REQUEST],
    }),
    updateRequest: builder.mutation({
      query: ({ data, id }) => ({
        url: `${WORKFLOW_REQUESTS}/${id}`,
        method: HTTP_METHODS.PUT,
        body: data,
      }),
      invalidatesTags: [TAGS.REQUEST],
    }),
  }),
});

export const {
  useLazyGetWorkflowByRequestIdQuery,
  useLazyGetWorkflowByIdQuery,
  useLazyGetRequestsQuery,
  useGetWorkflowRequestTypesQuery,
  useGetWorkflowRequestTypeByIdQuery,
  useGetWorkflowRequestTypeByCodeQuery,
  useGetRequestsSummaryQuery,
  useCreateRequestMutation,
  useCreateWorkflowRequestTypeMutation,
  useCreateRequestRequiredDocumentsMutation,
  useUpdateWorkflowRequestTypeMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowRequestTypeMutation,
  useGetRequestQuery,
  useUpdateRequestMutation,
} = workflowApi;
