import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import Fallback from "src/components/Fallback";

// layout
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));

const Dashboard = lazy(() => import("src/pages/dashboard/Dashboard"));

// people
const CorporateManagement = lazy(() =>
  import("src/pages/people/corporate-management")
);
const BoardOfDirectors = lazy(() =>
  import("src/pages/people/board-of-directors")
);
const CompanySecretaries = lazy(() =>
  import("src/pages/people/company-secretaries")
);
const Auditors = lazy(() => import("src/pages/people/auditors"));

// secretarial
const CompanyDocuments = lazy(() =>
  import("src/pages/secretarial/company-files")
);
const CompanyDocumentDetail = lazy(() =>
  import("src/pages/secretarial/company-files/Detail")
);
const StatutoryDocuments = lazy(() =>
  import("src/pages/secretarial/statutory-documents")
);
const NewShareholder = lazy(() =>
  import("src/pages/secretarial/statutory-documents/NewShareholder")
);
const IssueShares = lazy(() =>
  import("src/pages/secretarial/statutory-documents/IssueShares")
);
const EditShare = lazy(() =>
  import("src/pages/secretarial/statutory-documents/EditShare")
);
const BoardResolutions = lazy(() =>
  import("src/pages/secretarial/board-resolutions")
);
const NewBoardResolution = lazy(() =>
  import("src/pages/secretarial/board-resolutions/create")
);
const UpdateBoardResolution = lazy(() =>
  import("src/pages/secretarial/board-resolutions/update")
);
const BankFacilities = lazy(() => import("src/pages/banking/bank-facility"));
const SecretarialNewBankFacility = lazy(() =>
  import("src/pages/banking/bank-facility/new-facility")
);
const EditBankFacility = lazy(() =>
  import("src/pages/banking/bank-facility/edit-facility")
);
const CreditCards = lazy(() => import("src/pages/banking/credit-cards"));
const NewCreditCard = lazy(() =>
  import("src/pages/banking/credit-cards/create")
);
const BankAccounts = lazy(() => import("src/pages/banking/accounts"));
const SecretarialNewBankAccountFacility = lazy(() =>
  import("src/pages/banking/accounts/new-account")
);

const EditAccount = lazy(() =>
  import("src/pages/banking/accounts/edit-facility")
);
const EditCreditCard = lazy(() =>
  import("src/pages/banking/credit-cards/edit")
);

//leasing
const LeasingFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility")
);
const NewLeaseFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility/new-facility")
);

const EditLeaseFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility/edit-facility")
);

const RenewalLeaseFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility/renewal-facility")
);

const SubLeaseFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility/sub-lease-facility")
);

const AssignLeaseFacility = lazy(() =>
  import("src/pages/leasing/leasing-facility/assign-lease-facility")
);

// work-flows requests
const Requests = lazy(() => import("src/pages/work-flows/requests"));

// workflow-misc-requests
const Default = lazy(() => import("src/pages/work-flows/requests/Default"));
const NewDivestmentOfCompany = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/divestment-of-company/create"
  )
);
const DivestmentOfCompany = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/divestment-of-company/detail"
  )
);
const NewCompanyAcquisition = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/company-acquisition/create"
  )
);
const NewDividendDeclaration = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/dividend-declaration/create"
  )
);
const NewGuarantees = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/guarantees/create")
);
const NewBadDebtWriteOff = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/bad-debt-write-off/create"
  )
);
const NewBonds = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/bonds/create")
);
const NewIrdLiaisonAuthorization = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/ird-liaison-authorization/create"
  )
);
const NewAuthorizedSignatories = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/authorized-signatories/create"
  )
);
const NewAnnualReport = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/annual-report/create")
);
const NewAnnualQuarterlyFinancialReport = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/annual-quarterly-financial-report/create"
  )
);

// workflow-roc-requests
const NewCompanyNameChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-name-change/create"
  )
);
const CompanyNameChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-name-change/detail"
  )
);
const NewCompanySecretariesChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-secretaries-change/create"
  )
);
const CompanySecretariesChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-secretaries-change/detail"
  )
);
const NewCompanyAuditorsChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-auditors-change/create"
  )
);
const CompanyAuditorsChange = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-auditors-change/detail"
  )
);
const NewAppointmentOfDirector = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/appointment-of-director/create"
  )
);
const AppointmentOfDirector = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/appointment-of-director/detail"
  )
);
const NewRemovalOfDirector = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/remove-director/create"
  )
);
const RemovalOfDirector = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/remove-director/detail"
  )
);
const NewChangeOfRegisteredAddress = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/change-registered-address/create"
  )
);
const ChangeOfRegisteredAddress = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/change-registered-address/detail"
  )
);
const NewCompanyIncorporation = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/new-company-incorporation/create"
  )
);
const CompanyIncorporation = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/new-company-incorporation/detail"
  )
);
const NewChangeOfArticles = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-articles-change/create"
  )
);
const ChangeOfArticles = lazy(() =>
  import(
    "src/pages/work-flows/requests/registrar-of-companies/company-articles-change/detail"
  )
);

const CompanyAcquisition = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/company-acquisition/detail"
  )
);

const DividendDeclaration = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/dividend-declaration/detail"
  )
);

const Guarantees = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/guarantees/detail")
);

const BadDebtWriteOff = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/bad-debt-write-off/detail"
  )
);

const Bonds = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/bonds/detail")
);

const IrdLiaisonAuthorization = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/ird-liaison-authorization/detail"
  )
);

const AuthorizedSignatories = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/authorized-signatories/detail"
  )
);

const AnnualReport = lazy(() =>
  import("src/pages/work-flows/requests/miscellaneous/annual-report/detail")
);

const AnnualQuarterlyFinancialReport = lazy(() =>
  import(
    "src/pages/work-flows/requests/miscellaneous/annual-quarterly-financial-report/detail"
  )
);

// workflow-share-requests
const NewShareIssue = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-issue/create")
);

const ShareIssue = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-issue/detail")
);

const NewShareTransfer = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-transfer/create")
);

const ShareTransfer = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-transfer/detail")
);

const NewShareAcquisition = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-acquisition/create")
);

const ShareAcquisition = lazy(() =>
  import("src/pages/work-flows/requests/shares/share-acquisition/detail")
);

// workflow-banking-requests
const RemoveSignatory = lazy(() =>
  import("src/pages/work-flows/requests/bank/remove-signatory/create")
);
const AddSignatory = lazy(() =>
  import("src/pages/work-flows/requests/bank/add-signatory/create")
);
const CloseAccount = lazy(() =>
  import("src/pages/work-flows/requests/bank/close-account/create")
);
const NewBankFacility = lazy(() =>
  import("src/pages/work-flows/requests/bank/new-bank-facility/create")
);
const NewAccountOpening = lazy(() =>
  import("src/pages/work-flows/requests/banking/new-account-opening/create")
);
const EnableOnlineBanking = lazy(() =>
  import("src/pages/work-flows/requests/banking/enable-online-banking/create")
);
const IncreaseTransactionLimit = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-transaction-limit/create"
  )
);
const IssueCreditCard = lazy(() =>
  import("src/pages/work-flows/requests/banking/issue-credit-card/create")
);
const EnableMerchantAgreement = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/enable-merchant-agreement/create"
  )
);
const ObtainEWindowFacility = lazy(() =>
  import("src/pages/work-flows/requests/banking/obtain-ewindow-facility/create")
);
const ActivateDormantAccount = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/activate-dormant-account/create"
  )
);
const ActivateDormantAccountDetail = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/activate-dormant-account/detail"
  )
);
const IncreaseAuthLevel = lazy(() =>
  import("src/pages/work-flows/requests/banking/increase-auth-level/create")
);
const IncreaseAuthLevelDetaill = lazy(() =>
  import("src/pages/work-flows/requests/banking/increase-auth-level/detail")
);

//workflow-banking-requests
const NewCancelCreditCard = lazy(() =>
  import("src/pages/work-flows/requests/banking/cancel-credit-card/create")
);
const CancelCreditCard = lazy(() =>
  import("src/pages/work-flows/requests/banking/cancel-credit-card/detail")
);

const NewIncreseCreditCardLimit = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-credit-card-limit/create"
  )
);
const IncreseCreditCardLimit = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-credit-card-limit/detail"
  )
);

const NewBankFacilityDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/new-bank-facility/detail")
);
const NewAccountOpenningDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/new-account-opening/detail")
);
const RemoveSignatoryDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/remove-signatory/detail")
);
const AddSignatoryDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/add-signatory/detail")
);
const CloseAccountDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/close-account/detail")
);
const IncreaseTransastionLimitDetail = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-transaction-limit/detail"
  )
);
const EnableOnlineBankingDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/enable-online-banking/detail")
);
const IssueCreditCardDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/issue-credit-card/detail")
);
const NewEnableInternetbanking = lazy(() =>
  import("src/pages/work-flows/requests/banking/enable-internet-banking/create")
);
const EnableInternetbanking = lazy(() =>
  import("src/pages/work-flows/requests/banking/enable-internet-banking/detail")
);
const NewIncreaseTransastionLimit = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-transaction-limit/create"
  )
);
const IncreaseTransastionLimit = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/increase-transaction-limit/detail"
  )
);
const EnableMerchantAgreementDetail = lazy(() =>
  import(
    "src/pages/work-flows/requests/banking/enable-merchant-agreement/detail"
  )
);
const ObtainEWindowFacilityDetail = lazy(() =>
  import("src/pages/work-flows/requests/banking/obtain-ewindow-facility/detail")
);

const AssignmentOfLease = lazy(() =>
  import("src/pages/work-flows/requests/leasing/assignment-of-lease/create")
);
const AssignmentOfLeaseDetail = lazy(() =>
  import("src/pages/work-flows/requests/leasing/assignment-of-lease/detail")
);
const NewLease = lazy(() =>
  import("src/pages/work-flows/requests/leasing/new-lease/create")
);
const NewLeaseDetail = lazy(() =>
  import("src/pages/work-flows/requests/leasing/new-lease/detail")
);
const RenewalOfLease = lazy(() =>
  import("src/pages/work-flows/requests/leasing/renewal-of-lease/create")
);
const RenewalOfLeaseDetail = lazy(() =>
  import("src/pages/work-flows/requests/leasing/renewal-of-lease/detail")
);
const RenewalOfCancellation = lazy(() =>
  import(
    "src/pages/work-flows/requests/leasing/renew-lease-cancellation/create"
  )
);
const RenewalOfCancellationDetail = lazy(() =>
  import(
    "src/pages/work-flows/requests/leasing/renew-lease-cancellation/detail"
  )
);
const SubLease = lazy(() =>
  import("src/pages/work-flows/requests/leasing/sub-lease/create")
);
const SubLeaseDetail = lazy(() =>
  import("src/pages/work-flows/requests/leasing/sub-lease/detail")
);
// work-flows tasks
const Tasks = lazy(() => import("src/pages/work-flows/tasks"));

// Organization
const Documents = lazy(() => import("src/pages/organization/documents"));
const Docket = lazy(() => import("src/pages/organization/documents/Docket"));

// Other
const AccountPage = lazy(() => import("src/pages/dashboard/Account"));
const BlankPage = lazy(() => import("src/pages/dashboard/Blank"));
const SearchPage = lazy(() => import("src/pages/dashboard/SearchPage"));

export const dashboardRoutes = [
  {
    path: "home",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "blank",
        element: <BlankPage />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "search",
        element: <SearchPage />,
      },
    ],
  },
  {
    path: "secretarial",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "company-files",
        children: [
          { index: true, element: <CompanyDocuments /> },
          { path: ":id", element: <CompanyDocumentDetail /> },
        ],
      },
      {
        path: "statutory-documents",
        children: [
          { index: true, element: <StatutoryDocuments /> },
          { path: "new-shareholder", element: <NewShareholder /> },
          { path: "issue-shares", element: <IssueShares /> },
          { path: "edit-shares/:id", element: <EditShare /> },
        ],
      },
      {
        path: "board-resolutions",
        children: [
          { index: true, element: <BoardResolutions /> },
          { path: "create", element: <NewBoardResolution /> },
          { path: "update/:id", element: <UpdateBoardResolution /> },
        ],
      },
    ],
  },
  {
    path: "banking",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "facilities",
        children: [
          {
            index: true,
            element: <BankFacilities />,
          },
          {
            path: "create",
            element: <SecretarialNewBankFacility />,
          },
          {
            path: ":id",
            element: <EditBankFacility />,
          },
        ],
      },
      {
        path: "credit-cards",
        children: [
          {
            index: true,
            element: <CreditCards />,
          },
          {
            path: "create",
            element: <NewCreditCard />,
          },
          {
            path: ":id",
            element: <EditCreditCard />,
          },
        ],
      },
      {
        path: "accounts",
        children: [
          {
            index: true,
            element: <BankAccounts />,
          },
          {
            path: "create",
            element: <SecretarialNewBankAccountFacility />,
          },
          {
            path: ":id",
            element: <EditAccount />,
          },
        ],
      },
    ],
  },
  {
    path: "leasing",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "facilities",
        children: [
          {
            index: true,
            element: <LeasingFacility />,
          },
          {
            path: "create",
            element: <NewLeaseFacility />,
          },
          {
            path: ":id",
            element: <EditLeaseFacility />,
          },
          {
            path: ":id/renewal",
            element: <RenewalLeaseFacility />,
          },
          {
            path: ":id/sublease",
            element: <SubLeaseFacility />,
          },
          {
            path: ":id/assign",
            element: <AssignLeaseFacility />,
          },
        ],
      },
    ],
  },

  {
    path: "people",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "corporate-management",
        children: [{ index: true, element: <CorporateManagement /> }],
      },
      {
        path: "board-of-directors",
        children: [{ index: true, element: <BoardOfDirectors /> }],
      },
      {
        path: "company-secretaries",
        children: [{ index: true, element: <CompanySecretaries /> }],
      },
      {
        path: "auditors",
        children: [{ index: true, element: <Auditors /> }],
      },
    ],
  },
  {
    path: "work-flows",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "requests",
        children: [
          { index: true, element: <Requests /> },
          { path: "default", element: <Default /> },
          {
            path: "miscellaneous",
            children: [
              {
                path: "divestment-of-company",
                children: [
                  { path: "create", element: <NewDivestmentOfCompany /> },
                  { path: ":id", element: <DivestmentOfCompany /> },
                ],
              },
              {
                path: "company-acquisition",
                children: [
                  { path: "create", element: <NewCompanyAcquisition /> },
                  { path: ":id", element: <CompanyAcquisition /> },
                ],
              },
              {
                path: "dividend-declaration",
                children: [
                  { path: "create", element: <NewDividendDeclaration /> },
                  { path: ":id", element: <DividendDeclaration /> },
                ],
              },
              {
                path: "guarantees",
                children: [
                  { path: "create", element: <NewGuarantees /> },
                  { path: ":id", element: <Guarantees /> },
                ],
              },
              {
                path: "bad-debt-write-off",
                children: [
                  { path: "create", element: <NewBadDebtWriteOff /> },
                  { path: ":id", element: <BadDebtWriteOff /> },
                ],
              },
              {
                path: "bonds",
                children: [
                  { path: "create", element: <NewBonds /> },
                  { path: ":id", element: <Bonds /> },
                ],
              },
              {
                path: "ird-liaison-authorization",
                children: [
                  { path: "create", element: <NewIrdLiaisonAuthorization /> },
                  { path: ":id", element: <IrdLiaisonAuthorization /> },
                ],
              },
              {
                path: "authorized-signatories",
                children: [
                  { path: "create", element: <NewAuthorizedSignatories /> },
                  { path: ":id", element: <AuthorizedSignatories /> },
                ],
              },
              {
                path: "annual-report",
                children: [
                  { path: "create", element: <NewAnnualReport /> },
                  { path: ":id", element: <AnnualReport /> },
                ],
              },
              {
                path: "annual-quarterly-financial-report",
                children: [
                  {
                    path: "create",
                    element: <NewAnnualQuarterlyFinancialReport />,
                  },
                  {
                    path: ":id",
                    element: <AnnualQuarterlyFinancialReport />,
                  },
                ],
              },
            ],
          },
          {
            path: "registrar-of-companies",
            children: [
              {
                path: "company-name-change",
                children: [
                  { path: "create", element: <NewCompanyNameChange /> },
                  { path: ":id", element: <CompanyNameChange /> },
                ],
              },
              {
                path: "company-secretaries-change",
                children: [
                  { path: "create", element: <NewCompanySecretariesChange /> },
                  { path: ":id", element: <CompanySecretariesChange /> },
                ],
              },
              {
                path: "company-auditors-change",
                children: [
                  { path: "create", element: <NewCompanyAuditorsChange /> },
                  { path: ":id", element: <CompanyAuditorsChange /> },
                ],
              },
              {
                path: "appointment-of-director",
                children: [
                  { path: "create", element: <NewAppointmentOfDirector /> },
                  { path: ":id", element: <AppointmentOfDirector /> },
                ],
              },
              {
                path: "removal-of-director",
                children: [
                  { path: "create", element: <NewRemovalOfDirector /> },
                  { path: ":id", element: <RemovalOfDirector /> },
                ],
              },
              {
                path: "change-of-registered-address",
                children: [
                  { path: "create", element: <NewChangeOfRegisteredAddress /> },
                  { path: ":id", element: <ChangeOfRegisteredAddress /> },
                ],
              },
              {
                path: "new-company-incorporation",
                children: [
                  { path: "create", element: <NewCompanyIncorporation /> },
                  { path: ":id", element: <CompanyIncorporation /> },
                ],
              },
              {
                path: "change-of-articles",
                children: [
                  { path: "create", element: <NewChangeOfArticles /> },
                  { path: ":id", element: <ChangeOfArticles /> },
                ],
              },
            ],
          },
          {
            path: "shares",
            children: [
              {
                path: "share-issue",
                children: [
                  { path: "create", element: <NewShareIssue /> },
                  { path: ":id", element: <ShareIssue /> },
                ],
              },
              {
                path: "share-transfer",
                children: [
                  { path: "create", element: <NewShareTransfer /> },
                  { path: ":id", element: <ShareTransfer /> },
                ],
              },
              {
                path: "share-acquisition",
                children: [
                  { path: "create", element: <NewShareAcquisition /> },
                  { path: ":id", element: <ShareAcquisition /> },
                ],
              },
            ],
          },
          {
            path: "banking",
            children: [
              {
                path: "remove-signatory",
                children: [
                  { path: "create", element: <RemoveSignatory /> },
                  { path: ":id", element: <RemoveSignatoryDetail /> },
                ],
              },
              {
                path: "add-signatory",
                children: [
                  { path: "create", element: <AddSignatory /> },
                  { path: ":id", element: <AddSignatoryDetail /> },
                ],
              },
              {
                path: "close-account",
                children: [
                  { path: "create", element: <CloseAccount /> },
                  { path: ":id", element: <CloseAccountDetail /> },
                ],
              },
              {
                path: "new-bank-facility",
                children: [
                  { path: "create", element: <NewBankFacility /> },
                  { path: ":id", element: <NewBankFacilityDetail /> },
                ],
              },
              {
                path: "enable-online-banking",
                children: [
                  { path: "create", element: <EnableOnlineBanking /> },
                  { path: ":id", element: <EnableOnlineBankingDetail /> },
                ],
              },
              {
                path: "issue-credit-card",
                children: [
                  { path: "create", element: <IssueCreditCard /> },
                  { path: ":id", element: <IssueCreditCardDetail /> },
                ],
              },
              {
                path: "enable-merchant-agreement",
                children: [
                  { path: "create", element: <EnableMerchantAgreement /> },
                  { path: ":id", element: <EnableMerchantAgreementDetail /> },
                ],
              },
              {
                path: "obtain-ewindow",
                children: [
                  { path: "create", element: <ObtainEWindowFacility /> },
                  { path: ":id", element: <ObtainEWindowFacilityDetail /> },
                ],
              },
              {
                path: "active-dormant-account",
                children: [
                  { path: "create", element: <ActivateDormantAccount /> },
                  { path: ":id", element: <ActivateDormantAccountDetail /> },
                ],
              },
              {
                path: "new-account-openning",
                children: [
                  { path: "create", element: <NewAccountOpening /> },
                  { path: ":id", element: <NewAccountOpenningDetail /> },
                ],
              },
              {
                path: "increase-transaction-limit",
                children: [
                  { path: "create", element: <IncreaseTransactionLimit /> },
                  { path: ":id", element: <IncreaseTransastionLimitDetail /> },
                ],
              },
              {
                path: "increase-auth-level",
                children: [
                  { path: "create", element: <IncreaseAuthLevel /> },
                  { path: ":id", element: <IncreaseAuthLevelDetaill /> },
                ],
              },
              {
                path: "cancel-credit-card",
                children: [
                  { path: "create", element: <NewCancelCreditCard /> },
                  { path: ":id", element: <CancelCreditCard /> },
                ],
              },
              {
                path: "increase-credit-card-limit",
                children: [
                  { path: "create", element: <NewIncreseCreditCardLimit /> },
                  { path: ":id", element: <IncreseCreditCardLimit /> },
                ],
              },
              {
                path: "enable-internet-banking",
                children: [
                  { path: "create", element: <NewEnableInternetbanking /> },
                  { path: ":id", element: <EnableInternetbanking /> },
                ],
              },
              {
                path: "increse-transaction-limit",
                children: [
                  { path: "create", element: <NewIncreaseTransastionLimit /> },
                  { path: ":id", element: <IncreaseTransastionLimit /> },
                ],
              },
            ],
          },
          {
            path: "leasing",
            children: [
              {
                path: "new-lease",
                children: [
                  { path: "create", element: <NewLease /> },
                  { path: ":id", element: <NewLeaseDetail /> },
                ],
              },
              {
                path: "renewal-of-lease",
                children: [
                  { path: "create", element: <RenewalOfLease /> },
                  { path: ":id", element: <RenewalOfLeaseDetail /> },
                ],
              },
              {
                path: "cancellation-of-lease",
                children: [
                  { path: "create", element: <RenewalOfCancellation /> },
                  { path: ":id", element: <RenewalOfCancellationDetail /> },
                ],
              },
              {
                path: "assignment-of-lease",
                children: [
                  { path: "create", element: <AssignmentOfLease /> },
                  { path: ":id", element: <AssignmentOfLeaseDetail /> },
                ],
              },
              {
                path: "sub-lease",
                children: [
                  { path: "create", element: <SubLease /> },
                  { path: ":id", element: <SubLeaseDetail /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "tasks",
        children: [{ index: true, element: <Tasks /> }],
      },
    ],
  },
  {
    path: "organization",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "documents",
        children: [
          {
            index: true,
            element: <Documents />,
          },
          {
            path: "docket/:id",
            element: <Docket />,
          },
        ],
      },
    ],
  },
];
