import convertToModel from "src/helpers/convertToModel";
import Base from "../../Base";
import CorporateAuthorityLevel from "./CorporateAuthorityLevel";

class CorporateAuthority extends Base {
  constructor({
    id,
    title,
    description,
    corporateAuthorityLevels,
    companyId,
    createdAt,
    updatedAt,
    createdBy,
    updatedBy,
  }) {
    super({ id, createdAt, createdBy, updatedAt, updatedBy });
    Object.assign(this, {
      title,
      description,
      corporateAuthorityLevels: convertToModel(
        corporateAuthorityLevels,
        CorporateAuthorityLevel
      ),
      companyId,
    });
  }
}

export default CorporateAuthority;
