import { useMemo } from "react";
import { useFormik } from "formik";

// hooks
import { useGetApplicationUsersQuery } from "../../redux/api";
import { useGetCorporateRolesQuery } from "src/features/people/corporate-management/redux/api";
import { useGetDepartmentsQuery } from "src/redux/company/api";
import { useGetGeographiesQuery } from "src/redux/geography/api";

// models
import ApplicationUser from "src/models/organization/ApplicationUser";

// other
import { employeeValidationSchema } from "../validations";
import generateInputField from "src/helpers/generateInputField";
import generateOptions from "src/utils/generateOptions";
import convertToModel from "src/helpers/convertToModel";

// return object with passed value (true or false)
const createInitialTouched = (value) => ({
  firstName: value,
  lastName: value,
  dateOfBirth: value,
  gender: value,
  employeeNumber: value,
  userId: value,
  nicNumber: value,
  passportNumber: value,
  companyEmail: value,
  personalEmail: value,
  mobileNumber: value,
  officeNumber: value,
  addressNumber: value,
  addressLine1: value,
  addressLine2: value,
  street: value,
  city: value,
  province: value,
  country: value,
  zipCode: value,
  departmentId: value,
  dateOfAppoinment: value,
  name: value,
  corporateRoleId: value,
});

const useNewEmployeeFormik = (
  employee = {},
  currentTab,
  onTabChange,
  onSubmit
) => {
  const { data: applicationUsers = [] } = useGetApplicationUsersQuery();

  const { data: corporateRoles = [] } = useGetCorporateRolesQuery();

  const { data: geographies = [] } = useGetGeographiesQuery();

  const { data: departments = [] } = useGetDepartmentsQuery();

  const {
    firstName = "",
    lastName = "",
    dateOfBirth = null,
    employeeNumber = null,
    userId = 0,
    nicNumber = "",
    passportNumber = null,
    companyEmail = "",
    personalEmail = "",
    mobileNumber = "",
    officeNumber = "",
    addressNumber = "",
    addressLine1 = "",
    addressLine2 = "",
    street = "",
    city = "",
    province = "",
    country = "",
    zipCode = "",
    departmentId = null,
    dateOfAppoinment = null,
    corporateProfile = {},
  } = employee;

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      employeeNumber: employeeNumber,
      userId: userId,
      nicNumber: nicNumber,
      passportNumber: passportNumber,
      companyEmail: companyEmail,
      personalEmail: personalEmail,
      mobileNumber: mobileNumber,
      officeNumber: officeNumber,
      addressNumber: addressNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      street: street,
      city: city,
      province: province,
      country: country,
      zipCode: zipCode,
      departmentId: departmentId,
      dateOfAppoinment: dateOfAppoinment,
      name: corporateProfile?.name ?? "",
      corporateRoleId: corporateProfile?.corporateRoleId ?? null,
    },
    validationSchema: employeeValidationSchema(currentTab),
    onSubmit,
  });

  const { handleChange, handleSubmit, setTouched, setErrors, setFieldValue } = formik;

  const users = useMemo(
    () => convertToModel(applicationUsers, ApplicationUser),
    [applicationUsers]
  );

  const memoizedUsers = useMemo(
    () => generateOptions(users, "id", "fullName"),
    [users]
  );

  const memoizedCorporateRoles = useMemo(
    () => generateOptions(corporateRoles, "id", "name"),
    [corporateRoles]
  );

  const memoizedDepartments = useMemo(
    () => generateOptions(departments, "id", "name"),
    [departments]
  );

  const memoizedGeographies = useMemo(
    () => generateOptions(geographies, "name", "name"),
    [geographies]
  );

  const renderFields = (item) => {
    let field = { ...item };
    let options = [...(item.options || [])];

    if (field.name === "userId") options = memoizedUsers;

    if (field.name === "corporateRoleId") options = memoizedCorporateRoles;

    if (field.name === "departmentId") options = memoizedDepartments;

    if (field.name === "country") options = memoizedGeographies;

    if (typeof item.label === "function") {
      field.label = field.label(formik.values);
    }

    if (typeof item.required === "function")
      field.required = field.required(formik.values);

    return generateInputField(field, formik, options);
  };

  // validate form before navigating to second tab
  const tabChangeHandler = async () => {
    try {
      const errors = await setTouched(createInitialTouched(true));
      if (Object.keys(errors).length === 0) {
        setErrors({});
        await setTouched(createInitialTouched(false));
        onTabChange(null, 2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetCorporateRoleHandler = () => {
    setFieldValue("corporateRoleId", null)
    setFieldValue("name", "")
    setFieldValue("userId", null)
  }

  return {
    handleChange,
    renderFields,
    tabChangeHandler,
    handleSubmit,
    resetCorporateRoleHandler,
  };
};

export default useNewEmployeeFormik;
