import { Stack } from "@mui/material";

// hooks
import useNewEmployeeLogic from "./hooks/useNewEmployeeLogic";
import { useSelector } from "src/store";

// components
import PageContainer from "src/layouts/page";
import Tabs from "src/components/Tabs";
import FormModal from "src/components/modals/FormModal";
import NewConfirmation from "src/components/modals/NewConfirmation";
import Form from "../components/Form";

// redux
import { formDialogSelector } from "src/redux/selectors";

const NewEmployeeFeature = () => {
  const { data: dialogData } = useSelector(formDialogSelector);

  const {
    tabs,
    selectedTab,
    isLoading,
    submitHandler,
    backHandler,
    onTabChange,
    closeHandler,
    confirmHandler,
  } = useNewEmployeeLogic();

  return (
    <PageContainer spacing={4}>
      <Stack spacing={4}>
        <div>
          <Tabs tabs={tabs} current={selectedTab} />
        </div>
      </Stack>
      <Form
        isLoading={isLoading}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        onBack={backHandler}
        onSubmit={submitHandler}
      />

      {dialogData?.type === "NEW_CONFIRMATION" && (
        <FormModal>
          <NewConfirmation onConfirm={confirmHandler} onClose={closeHandler} />
        </FormModal>
      )}
    </PageContainer>
  );
};

export default NewEmployeeFeature;
