import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES, EMPLOYEES } from "src/helpers/url";

// helpers
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  DIRECTORS: "DIRECTORS",
};

export const boardOfDirectorsApi = createApi({
  reducerPath: "boardOfDirectorsApi",
  baseQuery,
  tagTypes: [TAGS.DIRECTORS],
  endpoints: (builder) => ({
    getBoardOfDirectors: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/BoardOfDirectors`,
      providesTags: [TAGS.DIRECTORS],
    }),
    createBoardOfDirector: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${
          payload.employeeId
        }/CorporateRoles`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [TAGS.DIRECTORS],
    }),
    updateBoardDirectorProfile: builder.mutation({
      query: (payload) => ({
        url: `corporateProfile/${payload.corporateProfileId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [TAGS.DIRECTORS],
    }),
  }),
});

export const {
  useGetBoardOfDirectorsQuery,
  useCreateBoardOfDirectorMutation,
  useUpdateBoardDirectorProfileMutation,
} = boardOfDirectorsApi;
