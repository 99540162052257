import {
  orange,
  red,
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  pink,
  purple,
  teal,
  yellow,
} from "@mui/material/colors";

class ColorUtility {
  static colors = [
    orange[500],
    orange[600],
    orange[700],
    orange[800],
    orange[900],
    red[500],
    red[600],
    red[700],
    red[800],
    red[900],
    amber[500],
    amber[600],
    amber[700],
    amber[800],
    amber[900],
    blue[500],
    blue[600],
    blue[700],
    blue[800],
    blue[900],
    blueGrey[500],
    blueGrey[600],
    blueGrey[700],
    blueGrey[800],
    blueGrey[900],
    brown[500],
    brown[600],
    brown[700],
    brown[800],
    brown[900],
    cyan[500],
    cyan[600],
    cyan[700],
    cyan[800],
    cyan[900],
    deepOrange[500],
    deepOrange[600],
    deepOrange[800],
    deepOrange[800],
    deepOrange[900],
    deepPurple[500],
    deepPurple[600],
    deepPurple[700],
    deepPurple[800],
    deepPurple[900],
    green[500],
    green[600],
    green[700],
    green[800],
    green[900],
    grey[500],
    grey[600],
    grey[700],
    grey[800],
    grey[900],
    indigo[500],
    indigo[600],
    indigo[700],
    indigo[800],
    indigo[900],
    lightBlue[500],
    lightBlue[600],
    lightBlue[700],
    lightBlue[800],
    lightBlue[900],
    lightGreen[500],
    lightGreen[600],
    lightGreen[700],
    lightGreen[800],
    lightGreen[900],
    lime[500],
    lime[600],
    lime[700],
    lime[800],
    lime[900],
    pink[500],
    pink[600],
    pink[700],
    pink[800],
    pink[900],
    purple[500],
    purple[600],
    purple[700],
    purple[800],
    purple[900],
    teal[500],
    teal[600],
    teal[700],
    teal[800],
    teal[900],
    yellow[500],
    yellow[600],
    yellow[700],
    yellow[800],
    yellow[900],
  ];

  static random = ColorUtility.colors.length - 1;

  static getRandomColor() {
    const interval = Math.floor(Math.random() * ColorUtility.random);
    return ColorUtility.colors[interval];
  }
}

export default ColorUtility;
