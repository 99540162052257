import { useFormik } from "formik";
//actions
import { useEffect } from "react";

// hooks
import { useDispatch } from "src/store";

// other
import { validationSchema } from "../validation";
import { useCreateDocumentTypeMutation } from "src/redux/documents/api";
import useToast from "../../../../hooks/useToast";
import toast from "react-hot-toast";
import { closeFormDialog } from "src/redux/slice";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";

const useNewDocumentType = () => {
  const dispatch = useDispatch();
  const { toastIdHandler, cleanup, toastId } = useToast();
  const [create, { isLoading, isError, isSuccess, error, data }] =
    useCreateDocumentTypeMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      documentCategoryId: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      create({ ...values, isSystem: false });
    },
  });

  useEffect(() => {
    if (isLoading && !toastId) {
      const _toastId = toast.loading("Creating", {
        duration: DEFAULT_TOAST_DURATION,
      });
      formik.resetForm();
      toastIdHandler(_toastId);
    }
    if (isSuccess && toastId) {
      toast.success("New Document Type Created Successfully.", {
        duration: DEFAULT_TOAST_DURATION,
        id: toastId,
      });
      dispatch(closeFormDialog());
    }
    if (isError && toastId) {
      toast.error(`${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
        id: toastId,
      });
    }
    return () => {
      cleanup();
    };
  }, [
    data,
    isSuccess,
    isLoading,
    toastId,
    isError,
    toastIdHandler,
    cleanup,
    error,
    dispatch,
  ]);

  return {
    formik,
    isLoading,
    create,
  };
};

export default useNewDocumentType;
