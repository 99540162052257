import { createApi } from "@reduxjs/toolkit/query/react";

import { COMPANIES, EMPLOYEES } from "src/helpers/url";
import getCurrentTenant from "src/helpers/getCurrentTenant";
import { baseQuery } from "src/redux/apiService";

export const TAGS = {
  CORPORATE_MANAGEMENT: "CORPORATE_MANAGEMENT",
};
export const corporateManagementApi = createApi({
  reducerPath: "corporateManagementApi",
  baseQuery,
  tagTypes: [TAGS.CORPORATE_MANAGEMENT],
  endpoints: (builder) => ({
    getCorporateManagement: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/CorporateManagement`,
      providesTags: [TAGS.CORPORATE_MANAGEMENT],
    }),
    getCorporateRoles: builder.query({
      query: () => `${COMPANIES}/${getCurrentTenant()}/CorporateRoles`,
    }),
    updateCorporateRoles: builder.mutation({
      query: (payload) => ({
        url: `${COMPANIES}/${getCurrentTenant()}/${EMPLOYEES}/${
          payload.employeeId
        }/CorporateRoles`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_MANAGEMENT],
    }),
    updateCorporateManagementProfile: builder.mutation({
      query: (payload) => ({
        url: `corporateProfile/${payload.corporateProfileId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [TAGS.CORPORATE_MANAGEMENT],
    }),
  }),
});

export const {
  useGetCorporateManagementQuery,
  useGetCorporateRolesQuery,
  useUpdateCorporateRolesMutation,
  useUpdateCorporateManagementProfileMutation,
} = corporateManagementApi;
