import { useCallback } from "react";
import { useState } from "react";

// handle initial value
const getStoredValue = (key, val) => {
  try {
    if (typeof window === "undefined" || val) return val;

    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return val;
  }
};

const useLocalStorage = (key, initialVal) => {
  const [storedVal, setStoredVal] = useState(getStoredValue(key, initialVal));

  const storeValueHandler = useCallback(
    (value) => {
      setStoredVal(value);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    },
    [key]
  );

  const removeStoredValueHandler = useCallback(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
    }
  }, [key]);

  return [storedVal, storeValueHandler, removeStoredValueHandler];
};

export default useLocalStorage;
