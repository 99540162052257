import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SIGN_IN } from "src/helpers/url";
import { HTTP_METHODS } from "src/constants";
// import { graphConfig } from "src/authConfig";

export const authenticationApi = createApi({
  reducerPath: "authenticationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/`,
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (token) => ({
        url: SIGN_IN,
        method: HTTP_METHODS.POST,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useSignInMutation } = authenticationApi;
