import { useEffect } from "react";
import toast from "react-hot-toast";

// hooks
import { useDispatch } from "src/store";

// redux
import { openFormDialog } from "src/redux/slice";
import { DEFAULT_TOAST_DURATION, ERROR_TOAST_DURATION } from "src/constants";
import { useGetDepartmentsQuery } from "src/redux/company/api";

const useGetDepartments = () => {
  const dispatch = useDispatch();
  const { data = [], isLoading, isError, error } = useGetDepartmentsQuery();

  const options = data?.map((department) => ({
    value: department?.id?.toString(),
    label: department?.name,
  }));

  useEffect(() => {
    if (isError) {
      toast.error(`Error: ${error?.data?.detail}`, {
        duration: ERROR_TOAST_DURATION,
      });
    }
  }, [isError, error]);

  const newTypeHandler = () => {
    dispatch(openFormDialog({ type: "NEW_DEPARTMENT" }));
  };

  const title = `Total (${data?.length ?? 0})`;
  return {
    data,
    isLoading,
    newTypeHandler,
    title,
    options,
  };
};

export default useGetDepartments;
