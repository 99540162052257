import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  history: {
    title: "Board Resolution",
  },
  decision: {
    approver: null,
    actionTaken: null,
    processing: {
      isLoading: false,
      approverId: null,
    },
  },
};

const requestsSlice = createSlice({
  name: "workFlowRequests",
  initialState,
  reducers: {
    pushToHistory: (state, action) => {
      state.history[action.payload.key] = action.payload.value;
    },
    resetHistory: (state) => {
      state.history = initialState.history;
    },
    takeDecision: (state, action) => {
      state.decision.approver = action.payload.approver;
      state.decision.actionTaken = action.payload.actionTaken;
    },
    resetDecisionTaken: (state) => {
      state.decision = initialState.decision;
    },
    setProcessLoading: (state, action) => {
      state.decision.processing = action.payload;
    },
  },
});

const { actions, reducer } = requestsSlice;

export const {
  pushToHistory,
  resetHistory,
  takeDecision,
  resetDecisionTaken,
  setProcessLoading,
} = actions;

export default reducer;
