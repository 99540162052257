import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import Fallback from "src/components/Fallback";

// layout
import DashboardLayout from "src/layouts/admin";
import DocumentType from "src/pages/admin/document-type";

// employee
import Employees from "src/pages/admin/employee";
import ViewEmployee from "src/pages/admin/employee/EmployeeDetails";
import NewEmployee from "src/pages/admin/employee/NewEmployee";
import UpdateEmployee from "src/pages/admin/employee/UpdateEmployee";
import DepartmentsPage from "src/pages/admin/departments";

// stakeholders
const Stakeholders = lazy(() => import("src/pages/admin/stakeholders"));
const NewStakeholder = lazy(() =>
  import("src/pages/admin/stakeholders/NewStakeholder")
);
const StakeholderDetail = lazy(() =>
  import("src/pages/admin/stakeholders/StakeholderDetail")
);

// tenants management
const Companies = lazy(() => import("src/pages/admin/company"));
const NewCompany = lazy(() => import("src/pages/admin/company/new-company"));
const CompanyDetail = lazy(() =>
  import("src/pages/admin/company/company-detail")
);

//Access Control
const ApplicationAccessControlPage = lazy(() =>
  import("src/pages/admin/access-control/application-access-control")
);

const CompanyAccessControlPage = lazy(() =>
  import("src/pages/admin/access-control/company-access-control")
);

// user management
const Users = lazy(() => import("src/pages/admin/user-management"));
const UserDetail = lazy(() => import("src/pages/admin/user-management/Detail"));
const ManageAccess = lazy(() =>
  import("src/pages/admin/user-management/manage-access")
);

//Document Category
const DocumentCategory = lazy(() =>
  import("src/pages/admin/document-category")
);

//Sectors
const Sectors = lazy(() => import("src/pages/admin/sectors"));

//Geographies
const Geographies = lazy(() => import("src/pages/admin/geographies"));

//currencies
const Currency = lazy(() => import("src/pages/admin/currency"));

// banks
const Banks = lazy(() => import("src/pages/admin/banks"));
const Lessors = lazy(() => import("src/pages/admin/lessors"));
const NewBank = lazy(() => import("src/pages/admin/banks/create"));
const BankDetails = lazy(() => import("src/pages/admin/banks/details"));

//workflow request Types
const WorkflowRequestTypes = lazy(() =>
  import("src/pages/admin/workflow-request-types")
);

//authorities
const Authorities = lazy(() => import("src/pages/admin/authorities"));
const NewAuthority = lazy(() => import("src/pages/admin/authorities/create"));
const AuthorityDetail = lazy(() =>
  import("src/pages/admin/authorities/detail")
);

const CorporateRoles = lazy(() => import("src/pages/admin/corporate-roles"));

export const adminRoutes = [
  {
    path: "admin",
    element: (
      <DashboardLayout>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "stakeholders",
        children: [
          {
            index: true,
            element: <Stakeholders />,
          },
          {
            path: "new",
            element: <NewStakeholder />,
          },
          {
            path: ":id",
            element: <StakeholderDetail />,
          },
        ],
      },
      {
        path: "document-category",
        children: [
          {
            index: true,
            element: <DocumentCategory />,
          },
          {
            path: "new",
            element: <NewStakeholder />,
          },
          {
            path: ":id",
            element: <StakeholderDetail />,
          },
        ],
      },
      {
        path: "document-type",
        children: [
          {
            index: true,
            element: <DocumentType />,
          },
          {
            path: "new",
            element: <NewStakeholder />,
          },
          {
            path: ":id",
            element: <StakeholderDetail />,
          },
        ],
      },
      {
        path: "companies",
        children: [
          {
            index: true,
            element: <Companies />,
          },
          {
            path: "create",
            element: <NewCompany />,
          },
          {
            path: ":id",
            element: <CompanyDetail />,
          },
        ],
      },
      {
        path: "access-control/application-access-control",
        children: [
          {
            index: true,
            element: <ApplicationAccessControlPage />,
          },
        ],
      },
      {
        path: "access-control/company-access-control",
        children: [
          {
            index: true,
            element: <CompanyAccessControlPage />,
          },
        ],
      },
      {
        path: "user-management",
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: ":id",
            element: <UserDetail />,
          },
          {
            path: ":id/manage-access",
            element: <ManageAccess />,
          },
        ],
      },
      {
        path: "employee",
        children: [
          {
            index: true,
            element: <Employees />,
          },
          {
            path: "new",
            element: <NewEmployee />,
          },
          {
            path: ":id",
            element: <UpdateEmployee />,
          },
          {
            path: "view/:id",
            element: <ViewEmployee />,
          },
        ],
      },
      {
        path: "sectors",
        children: [
          {
            index: true,
            element: <Sectors />,
          },
        ],
      },
      {
        path: "geography",
        children: [
          {
            index: true,
            element: <Geographies />,
          },
        ],
      },
      {
        path: "currency",
        children: [
          {
            index: true,
            element: <Currency />,
          },
        ],
      },
      {
        path: "banks",
        children: [
          {
            index: true,
            element: <Banks />,
          },
          {
            path: "create",
            element: <NewBank />,
          },
          {
            path: ":id",
            element: <BankDetails />,
          },
        ],
      },
      {
        path: "lessors",
        children: [
          {
            index: true,
            element: <Lessors />,
          },
          {
            path: ":id",
            element: <Lessors />,
          },
        ],
      },
      {
        path: "workflowRequestTypes",
        children: [
          {
            index: true,
            element: <WorkflowRequestTypes />,
          },
        ],
      },
      {
        path: "authorities",
        children: [
          {
            index: true,
            element: <Authorities />,
          },
          {
            path: "create",
            element: <NewAuthority />,
          },
          {
            path: ":id",
            element: <AuthorityDetail />,
          },
        ],
      },
      {
        path: "departments",
        children: [
          {
            index: true,
            element: <DepartmentsPage />,
          },
        ],
      },
      {
        path: "corporate-roles",
        children: [
          {
            index: true,
            element: <CorporateRoles />,
          },
        ],
      },
    ],
  },
];
